import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const AdminOnDuty: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M40.201 23.123a6.561 6.561 0 116.561-6.561 6.569 6.569 0 01-6.561 6.561zm0-9.373a2.812 2.812 0 102.812 2.812 2.815 2.815 0 00-2.812-2.813z" data-name="Path 21"/>
        <path d="M47.652 70a4.68 4.68 0 01-4.6-3.885l-2.46-14.043.285-.05-.291.023a9.811 9.811 0 00-.375-1.518 10.006 10.006 0 00-.386 1.532h-.019.015l-2.465 14.074a4.656 4.656 0 01-5.351 3.791l-.075-.013a4.687 4.687 0 01-3.782-5.424l3.56-20.167.572-3.469a17.3 17.3 0 00.044-6.011c-.149-.388-.61-.468-.971-.468H20.518a4.687 4.687 0 010-9.373h39.368a4.687 4.687 0 010 9.373H49.06c-.3 0-.814.06-.969.462a17.29 17.29 0 00.043 6.025l.571 3.461 3.567 20.206a4.683 4.683 0 01-3.815 5.4 4.744 4.744 0 01-.805.074zm-3.366-18.575l2.46 14.048a.928.928 0 101.831-.307l-.005-.028-3.565-20.2-.574-3.48c-.24-1.5-.675-5.827.161-7.979a4.6 4.6 0 014.466-2.86h10.826a.937.937 0 100-1.875H20.518a.937.937 0 100 1.875h10.838a4.607 4.607 0 014.468 2.865c.834 2.146.4 6.477.16 7.964l-.579 3.509-3.562 20.176a.938.938 0 00.727 1.078l.053.01a.91.91 0 001.041-.738v-.027l2.461-14.048c.27-1.646 1.178-5.336 3.893-5.336h.363c2.745 0 3.636 3.7 3.9 5.348z" data-name="Path 22"/>
    </Icon>
)
