/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Button, FormAdapters } from 'app/Components'
import { usePostApplyCoupon, usePostPaymentIntent } from '../actions'
import { CreatePaymentIntentResponse, PostApplyCouponResponse, ProductInfo } from '../types'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CONFIG } from 'lib/config'
import {
    addMonths,
    format,
    fromUnixTime,
    getUnixTime,
    isWithinInterval,
    startOfDay
} from 'date-fns'
import { CheckoutForm } from './CheckoutForm'
import styled from 'styled-components'
import { useTranslations } from 'lib/hooks'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { ApplyCouponFormShape, useApplyCouponForm } from '../form'
import { colors } from 'lib/styles'

export interface ProductCardProps {
    product: ProductInfo,
    annualPriceProp: boolean,
    stripePublicKey: string,
    registrationReferral?: string,
    setProductPurchase: ((product: ProductInfo | undefined)=>void)
}

export const ProductPurchase: React.FunctionComponent<ProductCardProps> = ({
    product,
    stripePublicKey,
    annualPriceProp,
    registrationReferral,
    setProductPurchase
}) => {
    // const stripePromise = loadStripe(CONFIG.STRIPE_PUBLIC_KEY)
    const [paymentIntent, setPaymentIntent] = useState<CreatePaymentIntentResponse>()
    const [discountInfo, setDiscountInfo] = useState<PostApplyCouponResponse>()
    const [couponCode, setCouponCode] = useState<string>()
    const [annualPrice, setAnnualPrice] = useState<boolean>(annualPriceProp)
    const [showCouponForm, setShowCouponForm] = useState<boolean>(false)
    const [isRegistrationReferralProcessed, setIsRegistrationReferralProcessed] = useState<boolean>(false)
    const stripePromise = loadStripe(stripePublicKey)
    const {fetch: createPaymentIntent} = usePostPaymentIntent(setPaymentIntent)
    const {fetch: applyCoupon, fetchState: {isLoading: applyingCoupon}} = usePostApplyCoupon((response, request) => {
        if (response.stripeCouponId) {
            setCouponCode(request.code)
            setDiscountInfo(response)
            setPaymentIntent(undefined)
        } else {
            if (showCouponForm) {
                form.code.setError('Unable to apply the promo code')
            }
        }
        if (!isRegistrationReferralProcessed) {
            setIsRegistrationReferralProcessed(true)
        }
    })
    const T = useTranslations()

    const {submit, form} = useForm(useApplyCouponForm(), {
        onSuccess(form: ApplyCouponFormShape) {
            applyCoupon({
                productId: annualPrice ? product.productYearId : product.product_id,
                code: form.code
            })
        }
    })

    useEffect(() => {
        if (isRegistrationReferralProcessed) {
            if (!paymentIntent) {
                createPaymentIntent({
                    productId: annualPrice ? product.productYearId : product.product_id,
                    couponCode
                })
            }
        } else {
            if (registrationReferral) {
                applyCoupon({
                    productId: annualPrice ? product.productYearId : product.product_id,
                    code: registrationReferral
                })
            } else {
                setIsRegistrationReferralProcessed(true)
            }
        }
    }, [couponCode, paymentIntent, annualPrice, isRegistrationReferralProcessed])

    return (
        <div>
            <a onClick={() => setProductPurchase(undefined)}>&lt; Back</a>
            <br/>
            <div>
                <ProductName>{product.name}{` (${annualPrice ? 'annual' : 'monthly'})`}</ProductName>
                <ProductDescription>{T.pages.purchase.chargeNote((discountInfo ? discountInfo.newPrice : (annualPrice ? product.product.priceYear : product.product.price)) || 0, annualPrice ? 'annual' : 'monthly')}
                    {!(annualPrice && product.product.recurring_payments_disabled) &&
                        (<span>{T.pages.purchase.renewNote(format(addMonths(new Date(), annualPrice ? 12 : 1), 'dd LLL yyyy'))}</span>)}
                </ProductDescription>
            </div>
            <CouponFormContainer>
                {!showCouponForm && !couponCode && (<InlineLink onClick={()=>setShowCouponForm(true)}>{T.pages.purchase.havePromoCode}</InlineLink>)}
                {showCouponForm && !couponCode && (<InlineSection>
                    <InputContainer>
                        <FormAdapters.Input
                            {...form.code}
                        />
                    </InputContainer>
                    <ButtonContainer>
                        <Button.Button
                            height={56}
                            fontSize={14}
                            backgroundColor={colors.orange}
                            textColor={colors.white}
                            onClick={submit}
                            content={T.common.save}
                            isLoading={applyingCoupon}
                            disabled={applyingCoupon}
                        />
                    </ButtonContainer>
                    <ButtonContainer>
                        <Button.Button
                            height={56}
                            fontSize={14}
                            backgroundColor={colors.layout.buttonShadow}
                            textColor={colors.black}
                            onClick={() => setShowCouponForm(false)}
                            content={T.common.close}
                            isLoading={false}
                            disabled={false}
                        />
                    </ButtonContainer>
                </InlineSection>)}
                {couponCode && <div>Promo code <b>{couponCode}</b> is applied</div>}
            </CouponFormContainer>
            <div>
                {paymentIntent && (<Elements stripe={stripePromise} options={{clientSecret: paymentIntent.clientSecret}} >
                    <CheckoutForm
                        price={annualPrice ? product.product.priceYear : product.product.price}
                        discount={discountInfo}
                        hasRecurringPayments={!(annualPrice && product.product.recurring_payments_disabled)}
                    />
                </Elements>)}
                <div>
                    {!annualPrice && product.product.priceYear && product.product.price && (
                        <span>
                            <br/>
                            <InlineLink onClick={() => {
                                setAnnualPrice(true)
                                setPaymentIntent(undefined)
                                setIsRegistrationReferralProcessed(false)
                            }}>{T.pages.purchase.preferAnnual(100 - Math.ceil(product.product.priceYear / (product.product.price * 12)*100))}</InlineLink>
                        </span>
                    )}
                    {annualPrice && product.product.priceYear && product.product.price && (
                        <span>
                            <br/>
                            <InlineLink onClick={() => {
                                setAnnualPrice(false)
                                setPaymentIntent(undefined)
                                setIsRegistrationReferralProcessed(false)
                            }}>{T.pages.purchase.preferMonthly}</InlineLink>
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}

const ProductName = styled.div`
  font-size: 20px;
  padding: 15px 0;
  font-weight: bold;
`

const ProductDescription = styled.div`
  font-size: 16px;
  padding: 0 0 15px 0;
  line-height: 16px;
`

const InlineSection = styled.div`
  display: flex;
  align-items: left;
  max-width: 450px;
  justify-content: space-between;
`

const CouponFormContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 15px;
`

const ButtonContainer = styled.div`
  margin-left: 12px;
`

const InputContainer = styled.div`
  width: 250px;
`

const InlineLink = styled.a`
  cursor: pointer;
`