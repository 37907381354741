import React from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { SingleSelect, SingleSelectProps } from '../FormComponents'

// eslint-disable-next-line
type GateProps = Field<any> & SingleSelectProps

interface SelectProps extends GateProps {
    disabled?: boolean
}

export const Select: React.FunctionComponent<SelectProps> = ({
    onChangeValue,
    disabled,
    value,
    onBlur,
    ...props
}) => (
    <SingleSelect
        {...props}
        onChange={value => {
            if (!value) {
                return onChangeValue({
                    label: '',
                    value: ''
                })
            }

            onChangeValue(value)
        }}
        disabled={disabled}
        selected={{
            value: value.value,
            label: value.label
        }}
        onBlur={onBlur}
    />
)
