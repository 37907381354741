import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { TryFreeEnum } from 'lib/types'
import { useTranslations, useTryFree } from 'lib/hooks'
import { Button } from 'app/Components'
import { Typography } from 'app/UI'

export const Confirmation: React.FunctionComponent = () => {
    const T = useTranslations()
    const { tryFreeState: { userEmail, page }, tryFreeActions: { setPage } } = useTryFree()
    const isConsultationBooked = page.consultationBooked

    return (
        <Container>
            {isConsultationBooked && (
                <ConsultationBooked>
                    <Title>
                        {T.pages.confirm.bookedConsultation}
                    </Title>
                    {T.pages.confirm.bookedNote}
                </ConsultationBooked>
            )}
            {!isConsultationBooked && (
                <Wrapper>
                    <Box>
                        <StepHeading>
                            <StepTitle>
                                {T.pages.confirm.confirmEmail}
                            </StepTitle>
                        </StepHeading>
                        <Text>
                            <EmailWrapper>
                                {T.pages.confirm.sendEmail}&nbsp;
                                <Email>
                                    {userEmail}
                                </Email>
                            </EmailWrapper>
                        </Text>
                    </Box>
                </Wrapper>
            )}
        </Container>
    )
}

const Container = styled.div`
    font-size: 20px;
    white-space: break-spaces;
`

const ConsultationBooked = styled.div`
    text-align: center;
`

const Title = styled(Typography.Heading)`
    text-align: center;
    margin-bottom: 32px;
`

const Wrapper = styled.div`
    width: 680px;
    margin: auto;
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid ${colors.gray.light};
    padding-bottom: 24px;
    margin-bottom: 30px;
`

const Bold = styled.span`
    font-weight: bold;
`

const StepHeading = styled.div`
    display: flex;
    align-items: center;
    gap: 38px;
    margin-bottom: 30px;
`

const StepTitle = styled.div`
    font-size: 28px;
    font-weight: 500;
    font-family: 'Roboto Slab' !important;
`

const Content = styled.div`
    padding-left: 104px;
`

const EmailWrapper = styled.div`
    display: flex;
    margin-bottom: 40px;
`

const Email = styled.div`
    color: ${colors.orange};
    text-decoration: underline;
`

const OrangeText = styled.span`
    color: ${colors.orange};
`

const ButtonWrapper = styled.div`
    width: 320px;
    margin: 56px auto 0 auto;
`

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: ${colors.orange};
    color: ${colors.white};
    border-radius: 50%;
    font-size: 40px;
    font-weight: 500;
    font-family: 'Roboto Slab' !important;
`

const Text = styled.div`
    color: ${colors.typography.blue};
    line-height: 34px;
`

