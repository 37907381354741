import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Calendar: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <g data-name="Group 621" transform="translate(23.678 31.606)">
            <circle cx="2.854" cy="2.854" r="2.854" data-name="Ellipse 14" transform="translate(17.711)"/>
            <circle cx="2.854" cy="2.854" r="2.854" data-name="Ellipse 15" transform="translate(26.273)"/>
            <circle cx="2.854" cy="2.854" r="2.854" data-name="Ellipse 16" transform="translate(17.711 8.562)"/>
            <circle cx="2.854" cy="2.854" r="2.854" data-name="Ellipse 17" transform="translate(26.273 8.562)"/>
            <circle cx="2.854" cy="2.854" r="2.854" data-name="Ellipse 18" transform="translate(0 8.562)"/>
            <circle cx="2.854" cy="2.854" r="2.854" data-name="Ellipse 19" transform="translate(8.562 8.562)"/>
            <circle cx="2.854" cy="2.854" r="2.854" data-name="Ellipse 20" transform="translate(17.711 17.124)"/>
            <circle cx="2.854" cy="2.854" r="2.854" data-name="Ellipse 21" transform="translate(26.273 17.124)"/>
            <circle cx="2.854" cy="2.854" r="2.854" data-name="Ellipse 22" transform="translate(0 17.124)"/>
            <circle cx="2.854" cy="2.854" r="2.854" data-name="Ellipse 23" transform="translate(8.562 17.124)"/>
        </g>
        <g data-name="Group 620">
            <path d="M16.706 15.724h45.6a6.714 6.714 0 0 1 6.706 6.706v40.236a6.714 6.714 0 0 1-6.706 6.706h-45.6A6.714 6.714 0 0 1 10 62.666V22.43a6.714 6.714 0 0 1 6.706-6.706Zm45.6 48.283a1.343 1.343 0 0 0 1.341-1.341V22.43a1.343 1.343 0 0 0-1.341-1.341h-45.6a1.343 1.343 0 0 0-1.341 1.341v40.236a1.343 1.343 0 0 0 1.341 1.341Z" data-name="Rectangle 1924"/>
            <g data-name="Group 619" transform="translate(22.847 10)">
                <rect width="5.805" height="9.707" data-name="Rectangle 1922" rx="2.903" transform="translate(26.552)"/>
                <rect width="5.805" height="9.707" data-name="Rectangle 1923" rx="2.903"/>
            </g>
        </g>
    </Icon>
)
