export enum RecordState {
    START = 'start',
    STOP = 'stop',
    PLAY = 'play'
}

export enum ErrorRecording {
    ERROR_RECORDING = 'ERROR_RECORDING',
    ERROR_UPLOADING = 'ERROR_UPLOADING',
    ERROR_FILE_TYPE = 'ERROR_FILE_TYPE_UPLOADING',
    ERROR_FILE_SIZE = 'ERROR_FILE_SIZE_UPLOADING'
}
