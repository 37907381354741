import React from 'react'
import { SvgIcon } from 'lib/types'
import { Svg } from 'app/UI/Icons'

export const Play: React.FunctionComponent<SvgIcon> = ({ width, height, fill }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 33.645 33.645"
        svgWdith={width}
        svgHeight={height}
    >
        <g fill={fill}>
            <path
                data-name="Path 5"
                d="M16.822 33.645a16.822 16.822 0 1116.823-16.823 16.841 16.841 0 01-16.823 16.823zm0-31.057a14.234 14.234 0 1014.234 14.234A14.25 14.25 0 0016.822 2.588z"
            />
            <path
                data-name="Path 6"
                d="M13.613 23.167l9.256-5.592a.881.881 0 000-1.5l-9.256-5.592a.872.872 0 00-1.32.753v11.182a.872.872 0 001.32.753z"
            />
        </g>
    </Svg>
)
