import styled from 'styled-components'

interface DescriptionProps {
    widthDescription?: number,
    dangerColor?: boolean,
    bottomPadding?: boolean,
    textNotCenter?: boolean
}

interface NoteContainerProps {
    topBorder?: boolean,
    center?: boolean,
    columnDirection?: boolean,
    centerIItems?: boolean,
    responsiveMdalignICenter?: boolean,
    alignCenter?: boolean
}

interface IconContainerProps {
    color: string,
    shadow?: boolean,
    responsiveMdIcon?: boolean,
    size?: number,
    switchMouseToCursor?: boolean
}

export const DescriptionContainer = styled.div<DescriptionProps>`
	margin: 1.75rem 0;
	${({ widthDescription }) =>
        widthDescription ? `width: ${widthDescription}%;` : ''};
	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		width: 100%;
	}
`

export const Description = styled.p<DescriptionProps>`
	font: normal normal normal 14px/19px Roboto;
	letter-spacing: 0px;
	text-align: center;
	color: ${({ theme, dangerColor }) =>
        dangerColor ? theme.colors.stateColors.danger : theme.colors.black[100]};
	${({ textNotCenter }) => (!textNotCenter ? 'text-align: center;' : '')}
	${({ bottomPadding }) => (bottomPadding ? 'padding-bottom: 1rem;' : '')}
	${({ widthDescription }) =>
        widthDescription ? `width: ${widthDescription}%;` : ''};
`

export const NoteContainer = styled.div<NoteContainerProps>`
	background: ${({ theme }) => theme.colors.grey[100]} 0% 0% no-repeat
		padding-box;
	position: relative;
	border-radius: 2px;
	padding: 1.8rem;
	display: flex;
	${({ columnDirection }) =>
        columnDirection ? `flex-direction: column;` : ''};
	${({ alignCenter, centerIItems }) =>
        centerIItems || alignCenter ? `align-items: center;` : ''};
	${({ centerIItems }) => (centerIItems ? `justify-content: center;` : '')}
	${({ responsiveMdalignICenter, theme }) =>
        responsiveMdalignICenter
            ? `
		@media (max-width: ${theme.breakpoints.md}) {
			align-items: center;
		}
	`
            : ''}
	${({ topBorder, theme }) =>
        topBorder
            ? `
		&:before {
			content: "";
			background: ${theme.colors.orange} 0% 0% no-repeat
				padding-box;
			border-radius: 1px;
			position: absolute;
			width: 100%;
			top: 0;
			height: 6px;
			left: 0;
		}
	`
            : ``}
`

export const IconContainer = styled.div<IconContainerProps>`
	width: ${({ size }) => (size ? `${size}px` : '37px')};
	height: ${({ size }) => (size ? `${size}px` : '37px')};
	${({ switchMouseToCursor }) =>
        switchMouseToCursor ? 'cursor: pointer;' : ''}
	border-radius: 100%;
	margin-right: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	${({ responsiveMdIcon, theme }) =>
        responsiveMdIcon
            ? `
		@media (max-width: ${theme.breakpoints.md}) {
			width: 70px;
		}
	`
            : ''}
	background: ${({ color }) => color} 0% 0% no-repeat padding-box;
	${({ shadow, theme }) =>
        shadow
            ? `
	box-shadow: 0px 0px 8px ${theme.colors.black[300]};
	`
            : ''}
`

export const RecordingTitleContainer = styled.div`
	display: flex;
	margin-bottom: 0.75rem;
	cursor: pointer;
`

export const RecordingTitle = styled.p`
	margin: 0;
	font: normal normal normal 14px/19px Roboto;
	letter-spacing: 0px;
	color: ${({ theme }) => theme.colors.black[200]};
`

export const TimeDescription = styled.span`
	font: normal normal normal 12px/16px Roboto;
	letter-spacing: 0px;
	color: ${({ theme }) => theme.colors.grey[400]};
`

export const Timeline = styled.div`
	position: relative;
	&:after {
		content: "";
		position: absolute;
		width: 1px;
		background-color: ${({ theme }) => theme.colors.grey[200]};
		top: 0;
		bottom: 0;
		left: 13px;
		margin-left: -3px;
	}
`

export const Content = styled.div`
	padding: 1.25rem 2.7rem;
	background-color: white;
	position: relative;
	border-radius: 6px;
	&:after {
		content: "";
		position: absolute;
		width: 23px;
		height: 23px;
		background: ${({ theme }) => theme.colors.blue[100]} 0% 0% no-repeat
			padding-box;
		top: 42px;
		border-radius: 50%;
		left: 0;
		z-index: 1;
	}
`

export const DateHeading = styled.h2`
	margin-bottom: 0.3rem;
	font: normal normal medium 25px/33px Roboto;
	letter-spacing: 0px;
	color: ${({ theme }) => theme.colors.black[100]};
`

export const SubTitle = styled.h4`
	font: normal normal 600 16px/21px Roboto;
	letter-spacing: 0px;
	color: ${({ theme }) => theme.colors.black[100]};
`
