import { useState } from 'react'
import axios from 'axios'
import { R } from 'lib/utils'
import { setAxiosDefaults } from 'lib/api'
import { ErrorResponse, KeyValuePair, RequestConfig, Response } from 'lib/types'
import { fetchCore } from './core'
import { RequestExtras } from './types'

setAxiosDefaults()

export const useFetch = <ResponseType , RequestParams = KeyValuePair>(
    config: RequestConfig,
    extras?: RequestExtras<ResponseType, RequestParams>
) => {
    const [ hasError, setError ] = useState(false)
    const [ isLoading, setLoading ] = useState(extras?.isLoadingAtStart || false)

    return {
        fetchState: {
            hasError,
            isLoading
        },
        fetch: (params?: RequestParams) => {
            const source = axios.CancelToken.source()

            setLoading(true)
            setError(false)

            fetchCore({
                config,
                params,
                source
            })
                .then((response: Response<ResponseType>) => {
                    setError(false)
                    setLoading(false)

                    R.ifDefined(extras && extras.onSuccess, cb => cb(response.data, params))
                })
                .catch((response: Response<ErrorResponse>) => {
                    if (axios.isCancel(response)) {
                        return
                    }

                    setError(true)
                    setLoading(false)

                    R.ifDefined(extras && extras.onError, cb => cb(response.response?.data))
                })

            return () => {
                if (isLoading && source) {
                    source.cancel()
                }
            }
        }
    }
}
