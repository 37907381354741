import { SvgIcon } from 'lib/types'
import React from 'react'
import { Svg } from 'app/UI/Icons'

export const CloseCircle: React.FunctionComponent<SvgIcon> = ({
    width,
    height,
    fill
}) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 33.644 33.644"
        svgWdith={width}
        svgHeight={height}
    >
        <g
            fill={fill}
            transform="translate(110.5 -80)"
        >
            <path
                d="M-93.678 113.644A16.841 16.841 0 01-110.5 96.822 16.841 16.841 0 01-93.678 80a16.841 16.841 0 0116.822 16.822 16.841 16.841 0 01-16.822 16.822zm0-31.056a14.25 14.25 0 00-14.234 14.234 14.25 14.25 0 0014.234 14.234 14.25 14.25 0 0014.234-14.234 14.25 14.25 0 00-14.234-14.234z"
                data-name="Path 9"
            />
            <path
                d="M29.146 220.94a1.29 1.29 0 01-.915-.379L23.97 216.3l-4.261 4.261a1.294 1.294 0 11-1.83-1.83l4.261-4.261-4.261-4.261a1.294 1.294 0 011.83-1.83l4.261 4.261 4.261-4.261a1.294 1.294 0 111.83 1.83L25.8 214.47l4.261 4.261a1.294 1.294 0 01-.915 2.209z"
                data-name="Path 10"
                transform="translate(-117.648 -117.648)"
            />
        </g>
    </Svg>
)
