import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { colors } from 'lib/styles'
import { useGetAvaConversations, useGetAvaUserStats } from '../actions'
import { Button, Dialog, Table } from 'app/Components'
import { dateHelpers, R } from 'lib/utils'
import { AvaConversation } from './AvaConversation'
import { useTranslations } from 'lib/hooks'
import { CONFIG } from 'lib/config'
import { GetAvaConversationsResponse, GetAvaUserStats } from '../types'
import { useAvaConversationsTableConfig, useAvaConversationsFilterTableConfig, AVA_DETAILS_LIMIT } from '../constants'
import { fromUnixTime } from 'date-fns'

type AvaConversationsProps = {
    rolePlay?: string
}

export const AvaConversationsAll: React.FunctionComponent<AvaConversationsProps> = ({rolePlay}) => {
    const T = useTranslations()
    const customRowLimit = 30
    const [avaConversations, setAvaConversations] = useState<GetAvaConversationsResponse>({
        conversations: [],
        count: 0
    })
    const [avaConversationUid, setAvaConversationUid] = useState<string>()
    const [currentUserStats, setCurrentUserStats] = useState<GetAvaUserStats>()
    const filterConfig = useAvaConversationsFilterTableConfig()
    const config = useAvaConversationsTableConfig({setAvaConversationUid, rolePlay})

    const { fetch: getAvaConversations, fetchState: { isLoading, hasError } } = useGetAvaConversations(setAvaConversations)
    const { fetch: getAvaStats } = useGetAvaUserStats(setCurrentUserStats)

    useEffect(() => {
        getAvaStats()
        getAvaConversations({
            limit: customRowLimit,
            forCurrentUser: true,
            rolePlay
        })
    }, [])

    return (
        <Container>
            {(!rolePlay || !avaConversationUid) && (<TableWrapper>
                <Table
                    // filtersConfig={filterConfig}
                    smallPadding
                    customRowLimit={customRowLimit}
                    withSorting
                    data={avaConversations.conversations}
                    hasError={hasError}
                    isLoading={isLoading}
                    config={config}
                    count={avaConversations.count}
                    withPagination
                    renderEmptyListMessage={() => T.common.noMatchResult}
                    renderError={() => (
                        <ErrorWrapper>
                            {T.common.error.fetchError}
                            <ButtonWrapper>
                                <Button.Button
                                    onClick={() => getAvaConversations({
                                        limit: customRowLimit,
                                        forCurrentUser: true
                                    })}
                                    backgroundColor={colors.primary}
                                    textColor={colors.white}
                                >
                                    {T.common.retry}
                                </Button.Button>
                            </ButtonWrapper>
                        </ErrorWrapper>
                    )}
                    onChange={requestParams => {
                        const startDate = requestParams.startDate ? dateHelpers.getStartOfDayInDefaultTimezone(fromUnixTime(requestParams.startDate)) : undefined
                        const endDate = requestParams.endDate ? dateHelpers.getStartOfDayInDefaultTimezone(fromUnixTime(requestParams.endDate)) : undefined
                        const dataToSend = R.clearObject({
                            ...requestParams,
                            startDate,
                            endDate,
                            forCurrentUser: true
                        } || {})

                        getAvaConversations(dataToSend)
                    }}
                />
            </TableWrapper>)}
            {rolePlay && avaConversationUid && (
                <AvaConversation
                    avaConversationUid={avaConversationUid}
                    reachedTheLimit={CONFIG.IS_FREE_CLIENT && ((currentUserStats?.totalDetailsViewed || 0) >= AVA_DETAILS_LIMIT)}
                    rolePlay={rolePlay}
                    setAvaConversationUid={setAvaConversationUid}
                />
            )}
            {avaConversationUid && !rolePlay && (<Dialog
                bodyStyles={{minHeight: 'unset', minWidth: '700px', maxHeight: '600px'}}
                title={T.pages.avaConversations.singleTitle}
                onClose={() => setAvaConversationUid(undefined)}
            >
                <AvaConversation
                    avaConversationUid={avaConversationUid}
                    reachedTheLimit={CONFIG.IS_FREE_CLIENT && ((currentUserStats?.totalDetailsViewed || 0) >= AVA_DETAILS_LIMIT)}/>
            </Dialog>)}
        </Container>
    )
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 600px;
    margin: auto;
    color: ${colors.typography.primary};
    * {
      box-sizing: border-box;
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    }
`

const Text = styled.div`
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
`

const Container = styled.div`
    //padding: 20px;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 18px;
    box-shadow: '0px 0px 30px #00000029';
    position: relative;
    background-color: ${colors.white};
`

const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const TableWrapper = styled.div`
    display: flex;
    margin-top: 40px;
    height: 100%;
    overflow-y: hidden;
`

const ButtonWrapper = styled.div`
    width: 120px;
`

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`