import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
    colors: {
        white: '#fff',
        black: {
            100: '#15273F',
            200: '#000000',
            300: '#00000029'
        },
        orange: '#fca947',
        grey: {
            100: '#f4f4f4 ',
            200: '#DEDEDE',
            300: '#e4e4e4',
            400: '#676767',
            500: '#484848'
        },
        blue: {
            100: '#65AFDB',
            200: '#517ece'
        },
        stateColors: {
            info: '#65afdb',
            danger: '#FB1F1D'
        }
    },
    breakpoints: {
        md: '500px'
    }
}

export default theme
