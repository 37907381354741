import React from 'react'
import styled from 'styled-components'
import { NewLoader } from 'app/Components'
import { colors, constants, stylesHelpers } from 'lib/styles'
import { useButtons } from './hook'

type ButtonProps = {
    content?: string,
    backgroundColor?: string,
    textColor?: string,
    onClick?: VoidFunction,
    isLoading?: boolean,
    disabled?: boolean,
    secondary?: boolean,
    leftIcon?: React.ReactNode,
    rightIcon?: React.ReactNode,
    height?: number,
    fontSize?: number
}

type ButtonStyles = {
    backgroundColor?: string,
    textColor?: string,
    leftIcon?: boolean,
    rightIcon?: boolean,
    secondary?: boolean,
    disabled?: boolean,
    height?: number,
    fontSize?: number
}

export const Button: React.FunctionComponent<ButtonProps> = ({
    content,
    onClick,
    disabled,
    textColor,
    backgroundColor,
    isLoading,
    leftIcon,
    rightIcon,
    secondary,
    height,
    fontSize
}) => {
    const { buttonRipple } = useButtons()

    return (
        <ButtonContent
            disabled={disabled}
            backgroundColor={backgroundColor}
            textColor={textColor}
            height={height}
            fontSize={fontSize}
            secondary={secondary}
            rightIcon={Boolean(rightIcon)}
            leftIcon={Boolean(leftIcon)}
            onClick={event => {
                if (disabled) {
                    return
                }

                buttonRipple(event, onClick)
            }}
        >
            {!isLoading && (
                <WrapperButtonElements>
                    {leftIcon}
                    {content && (
                        <ContentButtonWrapper>
                            {content}
                        </ContentButtonWrapper>
                    )}
                    {rightIcon}
                </WrapperButtonElements>
            )}
            {isLoading && (
                <NewLoader
                    size={18}
                    color={colors.white}
                />
            )}
        </ButtonContent>
    )
}

const ButtonContent = styled.button<ButtonStyles>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    width: 100%;
    font-size: ${props => props.fontSize ? `${props.fontSize}px` : '16px'};
    height: ${props => props.height ? `${props.height}px` : '40px'};
    border: none;
    background-color: ${props => props.disabled ? colors.layout.backgroundDisabled : props.backgroundColor || (props.secondary ? colors.layout.secondaryButton : colors.layout.primary)};
    color: ${props => props.textColor || props.theme.colors.white};
    padding: ${props => props.rightIcon || props.leftIcon ? '7px 10px' : '5px 5px'};
    box-shadow: ${props => props.secondary ? `0 0 14px 0 ${colors.layout.buttonShadow}` : `${constants.boxShadow(stylesHelpers.customBlackTransparent(0.08))}`};
    transition: box-shadow 0.2s ease-in-out;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'}!important;
    :hover {
        opacity: ${props => props.disabled || props.secondary ? 1 : 0.8};
        box-shadow: ${props => props.disabled ? 'none' : props.secondary ? `0px 0px 18px 0px ${colors.layout.buttonShadow}` : `${constants.boxShadow(stylesHelpers.customBlackTransparent(0.16))}`};
    }
    @keyframes ripple {
        to {
            transform: scale(4);
            opacity: 0;
        }
    }
    span.ripple {
        position: absolute;
        border-radius: 50%;
        transform: scale(0);
        animation: ripple 600ms linear;
        background-color: ${props => stylesHelpers.hexToRGBA(props.backgroundColor || colors.gray.border, 0.7)};
    }
`

const WrapperButtonElements = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ContentButtonWrapper = styled.div<ButtonStyles>`
    margin: 0 10px;
`
