import { HttpMethod } from 'lib/types'
import { RecordingInfo } from 'lib/models'
import { CONFIG } from 'lib/config'
import { useFetchPromise } from 'lib/hooks'
import { GetAllRecordingsRequest, GetAppointmentsResponse } from './types'

export const useGetAllRecordings = () => useFetchPromise<Array<RecordingInfo>, GetAllRecordingsRequest>({
    url: `${CONFIG.VC_API}/recording`,
    method: HttpMethod.POST
})

export const useGetAppointments = () => useFetchPromise<GetAppointmentsResponse>({
    url: `/appointment`,
    method: HttpMethod.GET
})
