import { useState } from 'react'
import { Dictionary } from 'lib/types'
import { languages, Languages } from 'lib/locale'
import { CONFIG } from 'lib/config'

export const useTranslationStore = () => {
    const [ language, setLanguage ] = useState(Languages[CONFIG.LANGUAGE])

    return {
        language: languages[language] as Dictionary,
        languageActions: {
            setLanguage
        }
    }
}
