import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const QuestionSets: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <g transform="translate(-4051 20183)">
            <g transform="translate(4061 -20173)">
                <path d="M244.442,106.466a30,30,0,1,1,30-30A30.034,30.034,0,0,1,244.442,106.466Zm0-55.384a25.385,25.385,0,1,0,25.384,25.384A25.413,25.413,0,0,0,244.442,51.081Z" transform="translate(-214.442 -46.466)"/>
            </g>
            <path d="M-2.643-5.48v4.123H1.48V-5.48ZM-8.7-19.595H-5.54a9.346,9.346,0,0,1,.3-2.414A5.221,5.221,0,0,1-4.3-23.94a4.748,4.748,0,0,1,1.616-1.3,5.1,5.1,0,0,1,2.3-.483A4.331,4.331,0,0,1,2.8-24.572a4.793,4.793,0,0,1,1.319,3.194,5.256,5.256,0,0,1-.334,2.34,6.68,6.68,0,0,1-1.1,1.764A17.209,17.209,0,0,1,1.2-15.75,16.755,16.755,0,0,0-.321-14.172a8.4,8.4,0,0,0-1.226,1.95,7.191,7.191,0,0,0-.576,2.656V-7.82H1.034V-9.269A5,5,0,0,1,1.313-11a5.909,5.909,0,0,1,.743-1.411A9.729,9.729,0,0,1,3.1-13.633q.576-.576,1.17-1.133.594-.594,1.17-1.226a8.6,8.6,0,0,0,1.021-1.374,7.419,7.419,0,0,0,.724-1.653A7.038,7.038,0,0,0,7.46-21.08a8.113,8.113,0,0,0-.576-3.157,6.476,6.476,0,0,0-1.616-2.321A7.117,7.117,0,0,0,2.8-28.008a9.547,9.547,0,0,0-3.176-.5,8.778,8.778,0,0,0-3.491.669,7.374,7.374,0,0,0-2.637,1.876,8.413,8.413,0,0,0-1.653,2.841A9.951,9.951,0,0,0-8.7-19.595Z" transform="translate(4091.605 -20128.303)"/>
        </g>
    </Icon>
)
