import React, { useEffect, useState } from 'react'
import { Dialog } from 'app/Components'
import initialReminder from 'assets/ava-never-done-rc.png'
import longMissingReminder from 'assets/ava-5-day-reminder.png'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { useGetAvaConversations } from '../actions'
import { getUnixTime } from 'date-fns'
import styled from 'styled-components'
import { CONFIG } from 'lib/config'

export const AvaReplayChallengeReminder: React.FunctionComponent = () => {
    const T = useTranslations()
    const [showInitialReminder, setShowInitialReminder] = useState<boolean>(false)
    const [showLongMissingReminder, setShowLongMissingReminder] = useState<boolean>(false)
    const {fetch: getAvaConversations} = useGetAvaConversations(response => {
        if (response) {
            if (response.count > 0) {
                const timeSinceLastRC = getUnixTime(new Date()) - response.conversations[0].created_at
                if (timeSinceLastRC > 60 * 60 * 24 * 5) {
                    setShowLongMissingReminder(true)
                }
            } else {
                setShowInitialReminder(true)
            }
        }
    })

    const onClose = () => {
        setShowInitialReminder(false)
        setShowLongMissingReminder(false)
    }

    useEffect(() => {
        getAvaConversations({
            hasMessages: true,
            forCurrentUser: true,
            limit: 1,
            orderBy: 'created_at',
            orderWay: 'DESC'
        })
    }, [])

    return (
        <span>
            {(showInitialReminder || showLongMissingReminder) && (<Dialog
                title={showInitialReminder ? T.announcements.replyChallengeReminder.initial.title : T.announcements.replyChallengeReminder.longMissing.title}
                onClose={onClose}
                dialogStyles={{
                    maxWidth: '600px',
                    minWidth: '400px',
                    minHeight: '600px',
                    maxHeight: '80%'
                }}
                actions={[
                    {
                        backgroundColor: colors.orange,
                        color: colors.white,
                        onClick: () => onClose(),
                        name: T.common.close
                    }
                ]}
            >
                <Container>
                    <AnnouncementImage src={`${CONFIG.IMAGE_API}${CONFIG.PATH_NAME}${showInitialReminder ? initialReminder : longMissingReminder}`} />
                    <Note>{showInitialReminder ? T.announcements.replyChallengeReminder.initial.note : T.announcements.replyChallengeReminder.longMissing.note}</Note>
                </Container>
            </Dialog>)}
        </span>
    )
}

const AnnouncementImage = styled.img`
    z-index: -1;
    width: 450px;
`

const Container = styled.div`
    text-align: center;
`

const Note = styled.div`
    text-align: left;
`