export enum ConsultationLanguage  {
    Japanese = 'japanese',
    English = 'english'
}

export type Consultation = {
    date: number,
    teacherUid: string,
    consultationLanguage: ConsultationLanguage
}

export type AppointmentDate = {
    appointmentId: number,
    appointmentDate: number
}
