import React from 'react'
import styled from 'styled-components'
import { Icons } from 'assets'
import { HeaderAlignment, Sort } from 'lib/types'
import { colors } from 'lib/styles'

type TableHeaderCellProps = {
    width?: number,
    alignment?: HeaderAlignment,
    className?: string,
    sorting?: Sort,
    sticky?: boolean,
    headerHeight?: number,
    onSortChange(sort: Sort): void
}

type CellContentProps = {
    width?: number,
    alignment?: HeaderAlignment,
    sticky?: boolean,
    headerHeight?: number
}

export const TableHeaderCell: React.FunctionComponent<TableHeaderCellProps> = ({
    className,
    children,
    sorting,
    onSortChange,
    width,
    sticky,
    alignment,
    headerHeight
}) => (
    <TableCell
        className={className}
        sticky={sticky}
        width={width}
        headerHeight={headerHeight}
    >
        <CellContent
            alignment={alignment}
            onClick={() => onSortChange(sorting === Sort.Asc ? Sort.Desc : Sort.Asc)}
        >
            {children}
            {(sorting && sorting === Sort.Asc) && (
                <ArrowDown>
                    <Icons.ArrowDown/>
                </ArrowDown>
            )}
            {sorting && sorting === Sort.Desc && (
                <ArrowUp>
                    <Icons.ArrowDown/>
                </ArrowUp>
            )}
        </CellContent>
    </TableCell>
)

const TableCell = styled.td<CellContentProps>`
    min-width: ${props => props.width ? `${props.width}px` : '80px'};
    padding-right: 20px;
    position: ${props => props.sticky ? 'sticky' : 'static'};
    left: 0;
    z-index: 10;
    background-color: ${colors.white};
`

const CellContent = styled.div<CellContentProps>`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: ${props => props.alignment ? props.alignment : 'unset'};
    height: ${props => props.headerHeight ? `${props.headerHeight}px` : '30px'};
`

const ArrowDown = styled.div`
    margin-left: 5px;
    display: flex;
`

const ArrowUp = styled.div`
    transform: rotate(180deg);
    margin-left: 5px;
    display: flex;
`
