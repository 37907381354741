import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'

type ErrorMessageProps = {
    error: string,
    top?: number
}

type WrapperStyles = {
    top?: number
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
    error,
    top
}) => (
    <ErrorWrapper>
        <AnchorWrapper top={top}>
            {error}
        </AnchorWrapper>
    </ErrorWrapper>
)

const AnchorWrapper = styled.div<WrapperStyles>`
    top: ${props => props.top || 30}px;
    z-index: 100;
    display: flex;
    max-width: 100%;
    position: absolute;
    border-radius: 12px;
    padding: 8px 16px;
    word-break: break-word;
    flex-direction: column;
    color: ${colors.red};
    background-color: ${colors.layout.foreground};
    border: solid 1px ${colors.red};
    ::before {
        top: -5px;
        content: "";
        position: absolute;
        align-self: center;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid ${colors.layout.primary};
    }
    ::after {
        top: -4px;
        content: "";
        align-self: center;
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid ${colors.layout.foreground};
    }
`

const ErrorWrapper = styled.div`
    display: flex;
    justify-content: center;
`
