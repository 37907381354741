import { colors } from 'lib/styles'

export const generateInputStyles = (selected?: boolean) => {

    if (selected) {

        return {
            backgroundColor: colors.blue,
            color: colors.white
        }
    }

    return  {
        backgroundColor: colors.white
    }
}
