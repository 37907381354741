export const upperCaseFirstLetter = (text?: string) => {
    if (!text) {
        return ''
    }

    const firstLetter = text
        .charAt(0)
        .toUpperCase()
    const rest = text
        .slice(1, text.length)

    return `${firstLetter}${rest}`
}
