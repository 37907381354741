import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Delete: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M28.903 70a6.23 6.23 0 0 1-6.2-6.116l-2.7-42.091a1.934 1.934 0 0 1 1.8-2.053 1.9 1.9 0 0 1 2.053 1.8l2.7 42.185a2.559 2.559 0 0 0 2.367 2.409h24.852a2.367 2.367 0 0 0 2.367-2.315l2.713-42.28a1.892 1.892 0 0 1 2.053-1.8 1.92 1.92 0 0 1 1.8 2.053l-2.71 42.187a6.224 6.224 0 0 1-6.221 6.022H28.903Z" data-name="Path 381"/>
        <path d="M64.009 23.594h-46.4a1.938 1.938 0 1 1 0-3.875h46.406a1.938 1.938 0 1 1-.01 3.875Z" data-name="Path 382"/>
        <path d="M49.985 22.515a1.94 1.94 0 0 1-1.937-1.938v-5.414a1.287 1.287 0 0 0-1.288-1.3H35.958a1.333 1.333 0 0 0-.932.377 1.283 1.283 0 0 0-.377.911v5.425a1.938 1.938 0 1 1-3.875 0v-5.413A5.149 5.149 0 0 1 35.916 10h10.812a5.349 5.349 0 0 1 3.645 1.508 5.136 5.136 0 0 1 1.519 3.645v5.435a1.891 1.891 0 0 1-1.907 1.927Z" data-name="Path 383"/>
        <path d="M40.811 62.261a1.94 1.94 0 0 1-1.938-1.937V29.396a1.938 1.938 0 0 1 3.875 0v30.933a1.931 1.931 0 0 1-1.937 1.932Z" data-name="Path 384"/>
        <path d="M48.54 62.261a1.94 1.94 0 0 1-1.938-1.937V29.396a1.938 1.938 0 0 1 3.875 0v30.933a1.924 1.924 0 0 1-1.937 1.932Z" data-name="Path 385"/>
        <path d="M33.082 62.261a1.94 1.94 0 0 1-1.937-1.937V29.396a1.938 1.938 0 0 1 3.875 0v30.933a1.938 1.938 0 0 1-1.938 1.932Z" data-name="Path 386"/>
    </Icon>
)
