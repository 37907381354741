import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { Tick } from 'app/UI'

type CheckboxProps = {
    checked: boolean,
    borderSize?: number,
    iconSize?: number,
    className?: string,
    label?: string,
    withoutIcon?: boolean,
    onChange(checked: boolean): void
}

type BorderStyle = {
    checked?: boolean,
    borderSize?: number
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    checked,
    onChange,
    label,
    className,
    withoutIcon,
    iconSize = 20,
    borderSize= 24
}) => (
    <Container
        onClick={() => onChange(!checked)}
        className={className}
    >
        <Border
            checked={checked}
            borderSize={borderSize}
        >
            {checked && !withoutIcon && (
                <IconWrapper>
                    <Tick
                        width={iconSize}
                        fill={colors.primary}
                    />
                </IconWrapper>
            )}
            {checked && withoutIcon && (
                <CheckedBox/>
            )}
        </Border>
        {label && (
            <Label>
                {label}
            </Label>
        )}
    </Container>
)

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
`

const Border = styled.div<BorderStyle>`
    width: ${props => props.borderSize ? `${props.borderSize}px` : '24px'};
    height: ${props => props.borderSize ? `${props.borderSize}px` : '24px'};;
    transition: border 0.2s;
    border: 2px solid ${({ checked }) => checked ? colors.primary : colors.gray.inputBorder};
    border-radius: 4px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Label = styled.div`
    margin-left: 8px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: normal;
    color: ${colors.typography.regular};
`

const IconWrapper = styled.div`
    position: absolute;
    display: flex;
`

const CheckedBox = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${colors.primary};
    border-radius: 2px;
    border: 1px solid ${props => props.theme.colors.white};
`
