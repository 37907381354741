import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

type ClickAwayComponentProps = {
    onClickOutside: VoidFunction,
    stretch?: boolean,
    isOpened: boolean
}

type ContainerStyles = {
    stretch?: boolean
}

export const ClickAwayComponent: React.FunctionComponent<ClickAwayComponentProps> = ({
    children,
    onClickOutside,
    stretch,
    isOpened
}) => {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!isOpened) {
            return
        }

        const onClick = (event: MouseEvent) => {
            if (ref.current && event.composedPath().includes(ref?.current)) {
                return
            }

            return onClickOutside()
        }

        window.addEventListener('click', onClick)

        return () => {
            window.removeEventListener('click', onClick)
        }
    }, [isOpened])

    return (
        <Container
            ref={ref}
            stretch={stretch}
        >
            {children}
        </Container>
    )
}

const Container = styled.div<ContainerStyles>`
    width: ${props => props.stretch ? '100%' : 'auto'};
`
