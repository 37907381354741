import React from 'react'
import styled from 'styled-components'
import equals from 'fast-deep-equal'
import { KeyValuePair, TableConfig } from 'lib/types'
import { colors, stylesHelpers } from 'lib/styles'
import { RowTemplate } from './RowTemplate'

type BodyContainerProps = {
    hasError: boolean,
    data: Array<KeyValuePair>,
    offset: number,
    config: Array<TableConfig<KeyValuePair>>
}

export const BodyContainer = React.memo<BodyContainerProps>(({
    offset,
    data,
    config,
    hasError
}) => (
    <TableBody>
        {!hasError && data?.map((item, index) => (
            <RowTemplate
                key={index}
                item={item}
                config={config}
                offset={offset}
                rowIndex={index}
            />
        ))}
    </TableBody>
), (prevProps, nextProps) => equals(prevProps.data, nextProps.data) && prevProps.hasError === nextProps.hasError && equals(prevProps.config, nextProps.config))

const TableBody = styled.tbody`
    vertical-align: baseline;
    td {
        padding: 5px;
        border-right: 1px solid ${stylesHelpers.hexToRGBA(colors.table.border, 0.47)};
    }
    tr:nth-child(even) {
        background-color: ${colors.table.tableSeparator};
    }
    tr:nth-child(odd) {
        background-color: ${colors.white};
    }
    tr:hover {
        background-color: ${colors.table.tableHoverColor};
    }
`
