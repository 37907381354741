import React from 'react'
import { useTheme } from 'styled-components'
import { ButtonStyleProps } from 'lib/types'
import * as IconUI from 'app/UI'
import { ButtonStyle } from './styles'

interface ButtonProps {
    label: string,
    iconName?: string,
    onClick?: VoidFunction
}

export const Button: React.FunctionComponent<ButtonProps & ButtonStyleProps> = ({
    label,
    state,
    iconName,
    noBorder,
    noPadding,
    noFlex,
    noMargin,
    onClick
}) => {
    const Icon = iconName && IconUI[iconName]
    const theme = useTheme()

    return (
        <ButtonStyle
            state={state}
            onClick={onClick}
            noBorder={noBorder}
            noPadding={noPadding}
            noMargin={noMargin}
            noFlex={noFlex}
        >
            {
                iconName && <Icon
                    width={30}
                    height={15}
                    fill={state && theme.colors.stateColors[state]}
                />
            }
            {label}
        </ButtonStyle>
    )
}
