import styled from 'styled-components'

export const DragAndDropContainer = styled.div`
	display: inline-block;
	position: relative;
`

export const DragAndDropOverlay = styled.div`
	border: dashed grey 4px;
	background-color: rgba(255,255,255,.8);
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`

export const DragAndDropOverlayContent = styled.div`
	position: absolute;
	top: 10%;
	right: 0;
	left: 0;
	text-align: center;
	color: grey;
	font-size: 3em;
`
