import React from 'react'
import styled from 'styled-components'
import { fromUnixTime, getUnixTime } from 'date-fns'
import { Icons } from 'assets'
import { FilterType } from 'lib/types'
import DatePicker from 'react-datepicker'
import { CustomInputDatePicker, Filter, OutlinedInput } from 'app/Components'
import { DateFormats } from 'common'

type FilterTemplateProps = {
    filters: Record<string, string | number>,
    filtersConfig: Array<Filter>,
    onChangeFilter(key: string, value: number | string | boolean | undefined): void
}

type InputWrapperProps = {
    width?: number
}

export const FilterTemplate: React.FunctionComponent<FilterTemplateProps> = ({
    filtersConfig,
    onChangeFilter,
    filters
}) => (
    <FiltersWrapper>
        {filtersConfig?.map(filter => {
            if (filter.typ === FilterType.Input) {

                return (
                    <FieldWrapper
                        key={filter.key}
                        width={filter.width}
                    >
                        <OutlinedInput
                            label={filter.label}
                            rightIcon={<Icons.Search/>}
                            debounce={filter.debounce ? 300 : undefined}
                            controlledValue={filters[filter.key] as string || ''}
                            onChange={value => onChangeFilter(filter.key, filter.liveParser ? filter.liveParser(value) : value)}
                        />
                    </FieldWrapper>
                )
            }

            if (filter.typ === FilterType.DatePicker) {

                return (
                    <FieldWrapper
                        key={filter.key}
                        width={filter.width}
                    >
                        <DatePicker
                            placeholderText={filter.label}
                            // selected={chosenDate}
                            dateFormat={DateFormats.FullExtendedDate}
                            // customInput={<CustomInputDatePicker/>}
                            onChange={(date: Date) => {
                                onChangeFilter(filter.key, date ? getUnixTime(date) : '')
                            }}
                        />
                    </FieldWrapper>
                )
            }

            return null
        })}
    </FiltersWrapper>
)

const FiltersWrapper = styled.div`
    display: flex;
    margin-top: 5px;
    margin-bottom: 30px;
    > div {
        margin-right: 10px;
    }
    > div:last-child {
        margin-right: unset;
    }
`

const FieldWrapper = styled.div<InputWrapperProps>`
    width: ${props => props.width ? `${props.width}px` : 'auto'}
`
