import React, { forwardRef, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { R, regexes, textUtils } from 'lib/utils'
import { colors, stylesHelpers } from 'lib/styles'
import { getInputPaddings } from './utils'
import { InputSizes, InputStyle, IconsStyle, InputContainerStyle } from './types'
import { Close } from 'app/UI'

type InputProps = {
    type?: string,
    label?: string,
    error?: string,
    margin?: boolean,
    disabled?: boolean,
    select?: boolean,
    filter?: boolean,
    maxLength?: number,
    debounce?: number,
    isNumeric?: boolean,
    isNumericFloat?: boolean,
    placeholder?: string,
    onBlur?: VoidFunction,
    onClick?: VoidFunction,
    onEnter?: VoidFunction,
    withoutClear?: boolean,
    controlledValue?: string,
    rightIcon?: React.ReactNode,
    frozenValue?: string | number,
    className?: string,
    leftIcon?: React.ReactNode,
    height?: number,
    onChange(value: string): void
}

export const OutlinedInput = forwardRef<HTMLInputElement, InputProps>(({
    type,
    label,
    onBlur,
    error,
    maxLength,
    margin,
    disabled,
    filter,
    select,
    debounce,
    frozenValue,
    isNumeric,
    isNumericFloat,
    onChange,
    withoutClear,
    onEnter,
    leftIcon,
    rightIcon,
    placeholder,
    controlledValue,
    className,
    height,
    onClick
}, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const prevControlledValue = useRef(controlledValue)
    const [value, setValue] = useState(controlledValue || '')
    const { rightPadding, leftPadding } = getInputPaddings(leftIcon, rightIcon)
    const [errorsParent] = useAutoAnimate<HTMLDivElement>()

    useEffect(() => {
        if (value !== '' && controlledValue === undefined && prevControlledValue.current !== undefined) {
            setValue('')
        }

        prevControlledValue.current = controlledValue
    }, [controlledValue])

    return (
        <InputContainer
            margin={margin}
            className={className}
        >
            <Input
                height={height}
                ref={ref}
                hasError={Boolean(error)}
                readOnly={select}
                select={select}
                disabled={disabled}
                onClick={onClick}
                maxLength={maxLength}
                rightPadding={rightPadding}
                leftPadding={leftPadding}
                onBlur={() => R.ifDefined(onBlur, R.call)}
                value={frozenValue || value}
                placeholder={textUtils.upperCaseFirstLetter(placeholder || label)}
                onChange={event => {
                    if (isNumeric && onChange) {
                        setValue(regexes.allowOnlyNumbers(event.target.value))

                        return onChange(regexes.allowOnlyNumbers(event.target.value))
                    }

                    if (isNumericFloat && onChange) {
                        setValue(regexes.allowNumbersWithComma(event.target.value))

                        return onChange(regexes.allowNumbersWithComma(event.target.value))
                    }

                    setValue(event.target.value)

                    if (onChange) {
                        return onChange(event.target.value)
                    }
                }}
                onKeyDown={event => {
                    if (onEnter && event.key === 'Enter') {
                        return onEnter()
                    }
                }}
                type={type}
            />
            <CloseIcon
                frozenValue={frozenValue}
                disabled={disabled}
                value={frozenValue || value}
                withoutClear={withoutClear}
                onClick={() => {
                    setValue('')

                    if (onChange) {
                        onChange('')
                    }
                }}
            >
                <Close
                    fill={colors.icon.lightGray}
                    width={18}
                    height={18}
                />
            </CloseIcon>
            {leftIcon && (
                <LeftIconWrapper>
                    {leftIcon}
                </LeftIconWrapper>
            )}
            {rightIcon && (
                <RightIconWrapper
                    frozenValue={frozenValue}
                    isFilter={filter}
                    value={value}
                    withoutClear={withoutClear}
                >
                    {rightIcon}
                </RightIconWrapper>
            )}
            <ErrorContainer ref={errorsParent}>
                {Boolean(error) && (
                    <ErrorMessage>
                        {error}
                    </ErrorMessage>
                )}
            </ErrorContainer>
        </InputContainer>
    )
})

const ErrorMessage = styled.div`
    display: block;
    word-break: break-word;
    color: ${colors.red};
    font-size: 14px;
`

const InputContainer = styled.div<InputContainerStyle>`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`

const Input = styled.input<InputStyle>`
    pointer-events: ${props => props.disabled ? `none` : `auto`};
    font-size: 16px;
    border-radius: 6px;
    line-height: 14px;
    border: ${props => props.hasError
        ? `1px solid ${colors.red}`
        : `1px solid ${stylesHelpers.hexToRGBA(colors.gray.inputBorder, 0.8)}`
};
    padding: 16px;
    width: 100%;
    height: ${props => props.height ? `${props.height}px` : '66px'};
    transition: border-color 0.5s;
    outline: none;
    color: ${props => props.disabled ? colors.typography.regularBrighter : colors.black};
    background-color: ${props => props.disabled ? colors.layout.backgroundDisabled : colors.layout.foreground};
    :focus {
        outline-color: ${colors.black};
        border: 1px solid ${colors.black};
    }
    :hover {
        cursor: ${props => props.select ? 'pointer' : 'text' };
    }
    ::placeholder {
        color: ${colors.typography.placeholder};
        font-size: 16px;
    }
    :focus::placeholder {
        color: transparent;
    }
`

const CloseIcon = styled.div<IconsStyle>`
    display: ${props => props.value && Boolean(!props.disabled) && Boolean(!props.withoutClear) ? 'flex' : 'none'};
    position: absolute;
    right: 16px;
`

const LeftIconWrapper = styled.div`
    position: absolute;
    display: flex;
    left: 5px;
    align-items: center;
`

const RightIconWrapper = styled.div<IconsStyle>`
    position: absolute;
    display: flex;
    align-items: center;
    right: ${props => (props.value || props.frozenValue) && !props.withoutClear
        ? InputSizes.DefaultSpacing + InputSizes.DefaultSize + (InputSizes.DefaultSpacing / 2)
        : InputSizes.DefaultSpacing}px;
`

const ErrorContainer = styled.div`
    position: absolute;
    bottom: -10px;
    left: 0;
    transform: translateY(100%);
`
