import React from 'react'
import { ModelProps } from 'app/Components/types'
import { CloseCircle } from 'app/UI'
import {
    CloseContainer,
    ModelContainer,
    ModelContent,
    ChildrenContent
} from './style'

export const Modal: React.FunctionComponent<ModelProps> = ({
    children,
    showModel,
    fixedHeight,
    controlWidthHeight,
    onClick
}) => {
    React.useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [])

    return (
        <ModelContainer showModel={showModel}>
            <ModelContent controlWidthHeight={controlWidthHeight}>
                <CloseContainer onClick={onClick}>
                    <CloseCircle
                        width={30}
                        height={30}
                    />
                </CloseContainer>
                <ChildrenContent fixedHeight={fixedHeight}>
                    {children}
                </ChildrenContent>
            </ModelContent>
        </ModelContainer>
    )
}
