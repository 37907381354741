import React from 'react'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { datadogRum } from '@datadog/browser-rum'
import { Provider } from 'outstated'
import { stores } from 'lib/stores'
import { history } from 'lib/routing'
import { CONFIG } from 'lib/config'
import theme from 'lib/theme'
import { AccessVerification } from './Pages'

datadogRum.init({
    sampleRate: 100,
    trackInteractions: true,
    applicationId: CONFIG.DATADOG_APP_ID,
    clientToken: CONFIG.DATADOG_CLIENT_TOKEN,
    env: CONFIG.DD_ENV
})

export const App: React.FunctionComponent = () => (
    <Provider stores={stores}>
        <Router history={history}>
            <ThemeProvider theme={theme}>
                <AccessVerification />
            </ThemeProvider>
        </Router>
    </Provider>
)
