import React from 'react'
import { SvgIcon } from 'lib/types'
import { Svg } from 'app/UI/Icons'

export const Stop: React.FunctionComponent<SvgIcon> = ({ width, height, fill }) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31.877 31.877"
        svgWdith={width}
        svgHeight={height}
    >
        <g fill={fill}>
            <path
                data-name="Path 8"
                d="M15.939 31.877A15.957 15.957 0 010 15.939 15.957 15.957 0 0115.939 0a15.957 15.957 0 0115.938 15.939 15.957 15.957 0 01-15.938 15.938zm0-29.425A13.5 13.5 0 002.452 15.939a13.5 13.5 0 0013.487 13.487 13.5 13.5 0 0013.486-13.487A13.5 13.5 0 0015.939 2.452z"
            />
            <path
                data-name="Path 9"
                d="M20.108 22.069H11.77a1.963 1.963 0 01-1.962-1.961v-8.337a1.963 1.963 0 011.962-1.963h8.338a1.963 1.963 0 011.962 1.962v8.338a1.963 1.963 0 01-1.962 1.961z"
            />
        </g>
    </Svg>
)
