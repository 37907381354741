import { RecordsArrayType } from 'app/Actions'

export const groupByDate = (array: Array<RecordsArrayType>, property: string) => {
    const groupedArr = array.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
            acc[key] = []
        }
        // Add object to list for given key's value
        acc[key].push(obj)

        return acc
    }, {})
    const formatData = Object.keys(groupedArr).map(val => ({
        recordedAt: val,
        data: groupedArr[val]
    }))

    return formatData
}
