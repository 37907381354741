import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Vip: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M11.538 25.769V53.12h56.924V25.769H11.538M10 24.33h60v30.229H10z" data-name="Rectangle 1951"/>
        <path d="M30.904 49.081l5.551-16.723h-3.794L28.89 44.116h-.047l-3.724-11.758h-3.771l5.411 16.723zm6.977-16.723v16.723h3.68V32.358zm10.59 7.866v-5.012h2.857a7.7 7.7 0 011.218.094 2.834 2.834 0 011.031.363 1.978 1.978 0 01.714.761 2.676 2.676 0 01.27 1.294 2.676 2.676 0 01-.269 1.288 1.978 1.978 0 01-.714.761 2.834 2.834 0 01-1.031.363 7.7 7.7 0 01-1.218.094zm-3.677-7.87v16.727h3.677v-6h3.865a6.965 6.965 0 002.67-.457 5 5 0 001.791-1.2 4.618 4.618 0 001.007-1.722 6.36 6.36 0 00.317-1.978 6.325 6.325 0 00-.316-1.991 4.634 4.634 0 00-1.007-1.71 5 5 0 00-1.792-1.206 6.965 6.965 0 00-2.67-.457z" data-name="Path 354"/>
    </Icon>
)
