import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const OneToOne: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M50.001 40.613a9.577 9.577 0 01-7-3.23 12.491 12.491 0 01-3.249-7.629 10.432 10.432 0 012.641-7.97 10.659 10.659 0 0115.193.02 10.406 10.406 0 012.656 7.953 12.518 12.518 0 01-3.251 7.625 9.572 9.572 0 01-6.99 3.231zm0-18a6.279 6.279 0 00-4.677 1.9 6.4 6.4 0 00-1.578 4.96 8.481 8.481 0 002.186 5.186 5.206 5.206 0 008.135 0 8.506 8.506 0 002.19-5.188 6.454 6.454 0 00-1.6-4.94 6.258 6.258 0 00-4.656-1.919z" data-name="Path 10"/>
        <path d="M66.481 62.614H33.524a3.463 3.463 0 01-2.749-1.334 3.78 3.78 0 01-.657-3.224 16.475 16.475 0 017.54-10.135 24.842 24.842 0 0124.683-.051 16.383 16.383 0 017.548 10.194 3.788 3.788 0 01-.665 3.222 3.461 3.461 0 01-2.743 1.328zm-32.372-4h31.789a12.481 12.481 0 00-5.652-7.336 20.862 20.862 0 00-20.481.044 12.586 12.586 0 00-5.656 7.292z" data-name="Path 11"/>
        <path d="M26.375 41.113c-4.4 0-8.264-4.09-8.62-9.116a8.781 8.781 0 012.242-6.7 8.9 8.9 0 0112.745.016 8.759 8.759 0 012.254 6.69c-.362 5.021-4.229 9.11-8.621 9.11zm0-14.5a4.636 4.636 0 00-3.452 1.408 4.767 4.767 0 00-1.178 3.694c.207 2.926 2.328 5.4 4.63 5.4s4.419-2.473 4.63-5.4a4.8 4.8 0 00-1.192-3.677 4.619 4.619 0 00-3.438-1.426z" data-name="Path 12"/>
        <path d="M27.251 58.615H13.215a3.167 3.167 0 01-2.511-1.219 3.427 3.427 0 01-.6-2.919 13.559 13.559 0 016.2-8.342 20.781 20.781 0 0118.277-1.089 2 2 0 01-1.666 3.637 15.472 15.472 0 00-6.542-1.25 15.293 15.293 0 00-7.962 2.1 9.723 9.723 0 00-4.182 5.082h13.02a2 2 0 010 4z" data-name="Path 13"/>
    </Icon>
)
