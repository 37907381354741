import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Products: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <g transform="translate(-64.737 -4.466)">
            <path d="M130.461 74.466h-20a2 2 0 01-.236-.014 2.482 2.482 0 01-.264.014h-31.5a2 2 0 01-2-2v-52a6.007 6.007 0 016-6h24a6.007 6.007 0 016 6v14h14a6.007 6.007 0 016 6v32a2 2 0 01-2 2zm-18-4h16v-30a2 2 0 00-2-2h-14zm-16 0h12v-50a2 2 0 00-2-2h-24a2 2 0 00-2 2v50h12v-6a2 2 0 114 0z" data-name="Path 7"/>
            <path d="M84.721 66.449a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm0-10a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm0-10a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm0-10a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm0-10a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm10 30a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm0-10a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm0-10a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm0-10a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm10 40a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm0-10a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm0-10a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724z" data-name="Path 8"/>
            <ellipse cx="1.994" cy="2.004" data-name="Ellipse 2" rx="1.994" ry="2.004" transform="rotate(-45 92.411 -105.454)"/>
            <path d="M104.721 26.449a2 2 0 111.724-1.724 2 2 0 01-1.724 1.724zm17.74 36.016a2 2 0 102 2 2 2 0 00-2.001-2zm0-10a2 2 0 102 2 2 2 0 00-2.001-1.999zm0-10a2 2 0 102 2 2 2 0 00-2.001-1.999zm-8 20a2 2 0 102 2 2 2 0 00-2-2zm0-10a2 2 0 102 2 2 2 0 00-2-1.999zm0-10a2 2 0 102 2 2 2 0 00-2-1.999z" data-name="Path 9"/>
        </g>
    </Icon>
)
