import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { colors } from 'lib/styles'
import { Button } from 'app/Components'
import { useTranslations } from 'lib/hooks'
import { CONFIG } from 'lib/config'
import { Nullable } from 'lib/types'
import { PURCHASE_PRODUCT_LINK } from 'lib/utils/constants'
import { getCookieByKey } from 'lib/api'
import { ProductList } from './screens'

export const Purchase: React.FunctionComponent = () => {
    const T = useTranslations()

    return (
        <Wrapper>
            <ProductList />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    color: ${colors.typography.primary};
    * {
      box-sizing: border-box;
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    }
`

const Text = styled.div`
    margin: 20px 0 15px 0;
    font-size: 18px;
    line-height: 22px;
`

const Link = styled.div`
    max-width: 500px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: underline;
    padding: 10px 0;
    font-weight: bold;
    color: ${colors.linkColor};
`

const Section = styled.div`
    max-width: 600px;
    font-size: 18px;
    padding: 10px 0;
    line-height: 22px;
`

const RolePlayTitle = styled.div`
    padding: 15px 0;
    font-size: 24px;
`

const BackLink = styled.div`
    width: 300px;
    font-size: 16px;
    cursor: pointer;
    padding: 0px 10px 15px 0;
`