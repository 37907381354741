import React from 'react'
import styled from 'styled-components'
import { Icons } from 'assets'
import { colors } from 'lib/styles'

export enum Direction {
    Left = 'left',
    Right = 'right',
    First = 'first',
    Last = 'last'
}

type PaginationArrowProps = {
    direction: Direction,
    onClick: VoidFunction,
    isDisabled?: boolean
}

type ArrowWrapperProps = {
    isActive?: boolean
}

export const PaginationArrow: React.FunctionComponent<PaginationArrowProps> = ({
    direction,
    onClick,
    isDisabled
}) => {
    const iconColor = isDisabled
        ? colors.icon.disabledArrow
        : colors.icon.activeArrow

    const renderIcon = (direction: Direction) => {
        switch (direction) {
            case Direction.First:
                return (
                    <Icons.ArrowDoubleLeft fill={iconColor}/>
                )
            case Direction.Left:
                return (
                    <Icons.ArrowLeft fill={iconColor}/>
                )
            case Direction.Right:
                return (
                    <Icons.ArrowRight fill={iconColor}/>
                )
            default:
                return (
                    <Icons.ArrowDoubleRight fill={iconColor}/>
                )
        }
    }

    return (
        <ArrowWrapper
            isActive={!isDisabled}
            onClick={() => !isDisabled && onClick()}
        >
            {renderIcon(direction)}
        </ArrowWrapper>
    )
}

const ArrowWrapper = styled.div<ArrowWrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: ${props => props.isActive ? `1px solid #3281B7` : `1px solid #808C8D`};
    cursor: ${props => props.isActive ? 'pointer' : 'initial'};
    border-radius: 50%;
`
