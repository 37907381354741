import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Students: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M50.001 40.002a9.578 9.578 0 01-7-3.23 12.492 12.492 0 01-3.249-7.629 10.433 10.433 0 012.641-7.97 10.659 10.659 0 0115.193.02 10.405 10.405 0 012.656 7.953 12.517 12.517 0 01-3.251 7.625 9.572 9.572 0 01-6.99 3.231zm0-18a6.279 6.279 0 00-4.677 1.9 6.4 6.4 0 00-1.578 4.96 8.482 8.482 0 002.187 5.186 5.207 5.207 0 008.135 0 8.506 8.506 0 002.189-5.188 6.453 6.453 0 00-1.6-4.94 6.257 6.257 0 00-4.656-1.919z" data-name="Path 10"/>
        <path d="M66.481 62.003H33.524a3.463 3.463 0 01-2.749-1.334 3.78 3.78 0 01-.657-3.224 16.476 16.476 0 017.54-10.135 24.841 24.841 0 0124.683-.051 16.383 16.383 0 017.548 10.194 3.788 3.788 0 01-.665 3.222 3.461 3.461 0 01-2.743 1.328zm-32.372-4h31.789a12.481 12.481 0 00-5.652-7.336 20.862 20.862 0 00-20.481.044 12.586 12.586 0 00-5.656 7.292z" data-name="Path 11"/>
        <path d="M26.376 40.502c-4.4 0-8.264-4.09-8.62-9.117a8.78 8.78 0 012.242-6.7 8.9 8.9 0 0112.745.016 8.759 8.759 0 012.254 6.69c-.363 5.022-4.23 9.111-8.621 9.111zm0-14.5a4.636 4.636 0 00-3.452 1.408 4.767 4.767 0 00-1.178 3.694c.207 2.926 2.328 5.4 4.63 5.4s4.419-2.473 4.63-5.4a4.8 4.8 0 00-1.192-3.677 4.619 4.619 0 00-3.438-1.426z" data-name="Path 12"/>
        <path d="M27.251 58.004H13.215a3.166 3.166 0 01-2.511-1.218 3.428 3.428 0 01-.6-2.919 13.559 13.559 0 016.2-8.342 20.779 20.779 0 0118.277-1.089 2 2 0 01-1.666 3.637 15.472 15.472 0 00-6.542-1.25 15.293 15.293 0 00-7.962 2.1 9.722 9.722 0 00-4.182 5.081h13.02a2 2 0 110 4z" data-name="Path 13"/>
    </Icon>
)
