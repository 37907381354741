import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import {
    AVA_CONVERSATION_LIST
} from './constants'
import { AvaConversationsAll } from './screens/AvaConversationsAll'
import { AvaConversationsMicroTopics } from './screens/AvaConversationsMicroTopics'

export const AvaConversations: React.FunctionComponent = () => {
    const T = useTranslations()
    const [viewMode, setViewMode] = useState<string>(AVA_CONVERSATION_LIST.ALL)

    return (
        <div>
            <SubNavigationContainer>
                <SubNavigationLink onClick={() => setViewMode(AVA_CONVERSATION_LIST.ALL)}>{T.pages.avaConversations.allConversations}</SubNavigationLink>
                <SubNavigationLink onClick={() => setViewMode(AVA_CONVERSATION_LIST.MICRO_TOPIC)}>{T.pages.avaConversations.microTopics}</SubNavigationLink>
            </SubNavigationContainer>
            {viewMode === AVA_CONVERSATION_LIST.ALL && (<AvaConversationsAll/>)}
            {viewMode === AVA_CONVERSATION_LIST.MICRO_TOPIC && (<AvaConversationsMicroTopics/>)}
        </div>
    )
}

const SubNavigationLink = styled.a`
  text-wrap: nowrap;
  margin-right: 25px;
  cursor: pointer;
  text-decoration: underline;
`

const SubNavigationContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
`

const Wrapper = styled.div`
    width: 100%;
    max-width: 600px;
    margin: auto;
    color: ${colors.typography.primary};
    * {
      box-sizing: border-box;
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    }
`

const Text = styled.div`
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
`

const Container = styled.div`
    padding: 20px;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 15px;
    box-shadow: '0px 0px 30px #00000029';
    position: relative;
    background-color: ${colors.white};
`

const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const TableWrapper = styled.div`
    display: flex;
    margin-top: 40px;
    height: 100%;
    overflow-y: hidden;
`

const ButtonWrapper = styled.div`
    width: 120px;
`

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`