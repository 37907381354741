export enum InputSizes {
    DefaultPadding = 8,
    CloseIcon = 14,
    RightIcon = 14,
    LeftIcon = 8,
    DefaultSize = 12,
    DefaultSpacing = 8
}

export type InputStyle = {
    rightPadding: number,
    leftPadding: number,
    select?: boolean,
    disabled?: boolean,
    hasError?: boolean
}

export type IconsStyle = {
    value: string | number,
    frozenValue?: string | number,
    isFilter?: boolean,
    disabled?: boolean,
    withoutClear?: boolean
}

export type InputContainerStyle = {
    margin?: boolean
}
