import React from 'react'
import { useTheme } from 'styled-components'
import {
    IconContainer,
    RecordingTitle,
    RecordingTitleContainer,
    TimeDescription
} from 'app/Components'
import * as UI from 'app/UI'
import { useTranslations } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'

interface RecordingPlayerProps {
    recordNumber: number,
    timeLabel: string,
    recordUrl: string
}

export const RecordingPlayer: React.FunctionComponent<RecordingPlayerProps> = ({ recordNumber, timeLabel, recordUrl }) => {
    const [play, setPlay] = React.useState(false)
    const theme = useTheme()
    const T = useTranslations()
    const IconToShow = play ? 'Stop' : 'Play'
    const Icon = UI[IconToShow]

    return (
        <RecordingTitleContainer
            onClick={() => setPlay(prevState => !prevState)}
        >
            {play && <audio
                preload="auto"
                src={recordUrl}
                onPause={() => setPlay(false)}
                autoPlay
            />
            }
            <IconContainer
                shadow
                switchMouseToCursor
                color={theme.colors.white}
            >
                <Icon
                    width={25}
                    height={25}
                    fill={theme.colors.black[100]}
                />
            </IconContainer>
            <div>
                <RecordingTitle>
                    {T.components.recordings.answer} {recordNumber}
                </RecordingTitle>
                <TimeDescription>
                    {T.pages.timeLabel}: {dateHelpers.formatDate(timeLabel, 'dd.MM.yyyy HH:mm')}
                </TimeDescription>
            </div>
        </RecordingTitleContainer>
    )
}
