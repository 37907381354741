import { useFetchPromise } from 'lib/hooks'
import { HttpMethod } from 'lib/types'
import { dateHelpers, group } from 'lib/utils'
import { RecordsArrayType } from './types'
interface RecordingsType {
    data: Array<RecordsArrayType>
}

export const useBlackboard = () => {
    const { fetch } = useFetchPromise<RecordingsType>({
        url: `/blackboard/recording`,
        method: HttpMethod.GET
    })
    const fetchRecordings = () =>
        fetch().then(res => {
            const formatData = res.data.map(val => {
                const recordedAt = dateHelpers.formatToLocaleDateString(new Date(parseInt(val.recorded_at, 10) * 1000))

                return {
                    ...val,
                    // eslint-disable-next-line camelcase
                    recorded_at: recordedAt
                }
            })

            return group.groupByDate(formatData, 'recorded_at')
        })

    return { fetchRecordings }
}
