import { ReactNode } from 'react'
import { InputSizes } from './types'

export const getInputPaddings = (left?: ReactNode, right?: ReactNode) => {
    const leftPadding = left
        ? InputSizes.DefaultPadding + InputSizes.LeftIcon
        : InputSizes.DefaultPadding
    const rightPadding = right
        ? (2 * InputSizes.DefaultPadding) + InputSizes.CloseIcon + InputSizes.RightIcon
        : InputSizes.CloseIcon + InputSizes.DefaultPadding

    return {
        leftPadding,
        rightPadding
    }
}
