import { useField } from '@codegateinc/react-form-builder-v2'
import { useTranslations } from 'lib/hooks'
import { AvaSettingsFields } from './types'
import { FormComponents } from 'app/Components'
import { AvaSettings } from '../types'
import { AVA_SPEECH_SPEED } from '../constants'

export const useAvaSettingsForm = (settings: AvaSettings) => {
    const T = useTranslations()

    const autoSubmitTimeout = useField<number>({
        key: AvaSettingsFields.AutoSubmitTimeout,
        initialValue: settings.autoSubmitTimeout,
        isRequired: true,
        validateOnBlur: true,
        placeholder: '',
        validationRules: [
            {
                errorMessage: T.validationErrors.empty,
                validate: Boolean
            }
        ]
    })

    const isHandsFree = useField<boolean>({
        initialValue: settings.handsFree,
        key: AvaSettingsFields.IsHandsFree,
        isRequired: false
    })

    const enableCorrections = useField<boolean>({
        initialValue: settings.enableCorrections,
        key: AvaSettingsFields.EnableCorrections,
        isRequired: false
    })

    const speechSpeed = useField<FormComponents.SelectOption>({
        initialValue: {
            value: settings.speechSpeed,
            label: AVA_SPEECH_SPEED.find(entry => entry.value === settings.speechSpeed)?.label || 'Slow'
        },
        key: AvaSettingsFields.SpeechSpeed,
        placeholder: 'Ava speech speed',
        isRequired: true,
        validationRules: [
            {
                errorMessage: T.validationErrors.empty,
                validate: Boolean
            }
        ]
    })

    return {
        autoSubmitTimeout,
        isHandsFree,
        speechSpeed,
        enableCorrections
    }
}
