import { useTranslationStore } from './useTranslationStore'
import { useTryFreeStore } from './useTryFreeStore'

export const stores = [
    useTranslationStore,
    useTryFreeStore
]

export {
    useTranslationStore,
    useTryFreeStore
}
