import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Alarm: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M40.01 68.339a25.422 25.422 0 01-25.393-25.39A25.422 25.422 0 0140.01 17.556a25.422 25.422 0 0125.393 25.393 25.422 25.422 0 01-25.393 25.39zm0-46.169a20.8 20.8 0 00-20.776 20.779A20.8 20.8 0 0040.01 63.725a20.8 20.8 0 0020.776-20.776A20.8 20.8 0 0040.01 22.173zm-25.518 6.928h-.243l-.1-.008a2.971 2.971 0 01-2.1-1.134 7.8 7.8 0 01-2.06-5.351 9.47 9.47 0 018.693-9.65h.073l.437-.016h.039a9.052 9.052 0 015.836 2.172l.012.01a3.077 3.077 0 011.056 2.211 3.089 3.089 0 01-.889 2.28l-.024.024-8.695 8.54a2.8 2.8 0 01-2.016.922zm4.434-11.533a4.861 4.861 0 00-4.311 5.034 3.55 3.55 0 00.094.891l5.828-5.724a4.428 4.428 0 00-1.292-.21zm46.819 11.532h-.261a2.794 2.794 0 01-2.016-.922l-8.719-8.563a3.094 3.094 0 01-.888-2.294 3.07 3.07 0 011.055-2.2l.012-.01a9.051 9.051 0 015.837-2.169h.039l.514.016A9.472 9.472 0 0170 22.612a7.8 7.8 0 01-2.062 5.348 2.97 2.97 0 01-2.1 1.13zm-6.284-11.331l5.828 5.724a3.533 3.533 0 00.094-.884 4.863 4.863 0 00-4.311-5.04l-.32-.009a4.427 4.427 0 00-1.291.209z" data-name="Path 3"/>
        <path d="M63.094 68.342a2.3 2.3 0 01-1.632-.676l-5.771-5.771a2.308 2.308 0 010-3.265 2.308 2.308 0 013.265 0l5.771 5.771a2.308 2.308 0 01-1.632 3.941zm-46.169 0a2.3 2.3 0 01-1.632-.676 2.308 2.308 0 010-3.265l5.771-5.771a2.308 2.308 0 013.265 0 2.308 2.308 0 010 3.265l-5.771 5.771a2.3 2.3 0 01-1.633.676zm23.084-23.085H28.467a2.308 2.308 0 01-2.308-2.308 2.308 2.308 0 012.308-2.308h9.234V26.789a2.308 2.308 0 012.308-2.309 2.308 2.308 0 012.308 2.308v16.161a2.308 2.308 0 01-2.308 2.308z" data-name="Path 4"/>
    </Icon>
)
