import styled from 'styled-components'

interface VideoContainerProps {
    controlWidthHeight?: boolean
}

export const VideoContent = styled.video`
	background: ${({ theme }) => theme.colors.black[200]};
	width: 100%;
	height: 100%;
	z-index: auto;
	display: flex !important;
`
export const ChatContianer = styled.div`
	flex: 2;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	border-radius: 0 10px 10px 0;
	padding: 0 10px;
	background-color: ${({ theme }) => theme.colors.grey[300]};
	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		border-radius: 10px;
		margin-top: 1rem;
	}
`

export const VideoContainer = styled.div<VideoContainerProps>`
	flex: 4;
	${({ controlWidthHeight }) => controlWidthHeight ? `
		width: 100vw;
		height: 77vh;
	` : `height: 100%`};
	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		flex: 2;
		width: 100%;
	}
`
