import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Edit: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M52.692 70H18.078a8.086 8.086 0 01-8.077-8.077V27.309a8.086 8.086 0 018.077-8.077h24.155a2.308 2.308 0 010 4.615H18.078a3.465 3.465 0 00-3.461 3.461v34.614a3.465 3.465 0 003.461 3.461h34.614a3.465 3.465 0 003.461-3.461V35.385a2.308 2.308 0 114.615 0v26.538A8.086 8.086 0 0152.692 70z" data-name="Path 60"/>
        <path d="M69.416 10.758a2.316 2.316 0 00-3.272-.154l-.077.073-1.784 1.775a1.154 1.154 0 000 1.631l1.635 1.633a1.154 1.154 0 001.632 0l1.739-1.731a2.345 2.345 0 00.127-3.227zm-8.74 5.3L34.64 42.048a1.3 1.3 0 00-.333.567l-1.2 3.587a.564.564 0 00.7.7l3.584-1.2a1.3 1.3 0 00.567-.333l25.99-26.039a1.3 1.3 0 000-1.826l-1.435-1.442a1.3 1.3 0 00-1.84-.003z" data-name="Path 60"/>
    </Icon>
)
