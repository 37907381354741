import styled from 'styled-components'
import { ButtonStyleProps } from 'lib/types'

export const ButtonStyle = styled.button<ButtonStyleProps>`
	${({ noPadding }) => noPadding ? 'padding: 0;' : ''}
	${({ noMargin }) => noMargin ? '' : 'margin-right: 0.3rem;' }
	${({noFlex}) => noFlex ? '' : 'display: flex;'}
	border: ${({ state, theme, noBorder }) => !noBorder && state ? `1px solid ${theme.colors.stateColors[state]}` : 'none'};
	color: ${({ state, theme }) => state ? theme.colors.stateColors[state] : theme.colors.black[100]};
	padding: ${({ noPadding }) => noPadding ? '0' : '0.2rem 0.5rem'};
	border-radius: 100px;
	background: transparent 0% 0% no-repeat padding-box;
	align-items: center;
	cursor: pointer;
`
