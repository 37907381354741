import React from 'react'
import { Container } from 'app/UI'
import {
    ErrorSection,
    Loader,
    Timeline
} from 'app/Components'
import { RecordingsContainer } from 'app/Containers'
import { useTranslations } from 'lib/hooks'
import useBlackBoardRecord from './useBlackBoardRecord'

const BlackBoardRecordings: React.FunctionComponent = () => {
    const {
        isLoading,
        hasError,
        canRenderRecording,
        recordingData,
        hasEmptyRecords,
        onReload
    } = useBlackBoardRecord()
    const T = useTranslations()

    return (
        <Container>
            <Loader isLoading={isLoading} />
            <ErrorSection
                hasEmptyRecords={hasEmptyRecords}
                hasError={hasError}
                onRefetch={onReload}
                message={T.components.notes.fetchError}
            />

            {canRenderRecording && (
                <Timeline>
                    {recordingData.map((record, i) => (
                        <RecordingsContainer
                            key={i}
                            record={record}
                        />
                    ))}
                </Timeline>
            )}
        </Container>
    )
}

export default BlackBoardRecordings
