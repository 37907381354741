import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const ConsultationList: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M1.433,59.5C-.036,58.726-.023,56.76.008,56.23V6.5A3.915,3.915,0,0,1,.821,3.875,3.748,3.748,0,0,1,3.415,2.859h2.5A2.858,2.858,0,0,1,8.773,0H37.365a2.858,2.858,0,0,1,2.859,2.859h1.619a4.35,4.35,0,0,1,3.363,1.022,4.8,4.8,0,0,1,.916,3.142V55.072c.046.69.08,2.725-1.006,3.8a5.207,5.207,0,0,1-3.459,1.117H4.962c-.136,0-.309.008-.5.008A7.359,7.359,0,0,1,1.433,59.5ZM2.941,5.813a1.946,1.946,0,0,0-.075.579v.075l0,49.923a2.412,2.412,0,0,0,.046.62,9.5,9.5,0,0,0,1.971.124H41.7a3.112,3.112,0,0,0,1.384-.273,4.142,4.142,0,0,0,.183-1.632l-.005-.106,0-48.2a2.937,2.937,0,0,0-.165-1.1,3.156,3.156,0,0,0-1.091-.113l-.113.005H40.224a2.858,2.858,0,0,1-2.859,2.859H8.773A2.858,2.858,0,0,1,5.914,5.718H3.433A1.667,1.667,0,0,0,2.941,5.813Zm5.831-.1H37.365V2.859H8.773Zm1.608,40.968a1.429,1.429,0,0,1,0-2.859H35.409a1.429,1.429,0,0,1,0,2.859Zm0-9.1a1.431,1.431,0,0,1,0-2.862H35.409a1.431,1.431,0,0,1,0,2.862Zm0-9.1a1.429,1.429,0,0,1,0-2.859H35.409a1.429,1.429,0,0,1,0,2.859Zm.175-9.1a1.429,1.429,0,0,1,0-2.859H35.584a1.429,1.429,0,0,1,0,2.859Z" transform="translate(17 10)"/>
    </Icon>
)

