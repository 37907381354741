import styled from 'styled-components'

export enum ContentAlignment  {
    Center = 'center',
    Left = 'left',
    Right = 'right'
}

type DynamicItemStyle = {
    size?: number,
    alignment?: ContentAlignment
}

export const RowItem = styled.td<DynamicItemStyle>`
    text-align: ${props => props.alignment ? props.alignment : 'left'};
    font-size: ${props => props.size ? `${props.size}px` : '14px'};
    padding-left: 30px;
`
