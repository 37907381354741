import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { R } from 'lib/utils'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { Nullable } from 'lib/types'
import { ClickAwayComponent, OutlinedInput } from 'app/Components'
import { ArrowDown } from 'app/UI'

type SelectStyleActionProps = {
    hasError: boolean,
    hasClose: boolean,
    noOptions: boolean
}

type OptionContainerProps = {
    numberOfItems: number,
    dropdownWidth?: number,
    invert?: boolean
}

type Select = {
    label: string,
    value: string | number
}

export type SingleSelectProps = {
    label?: string,
    className?: string,
    disabled?: boolean,
    searchable?: boolean,
    errorMessage?: string,
    placeholder?: string,
    options: Array<SelectOption>,
    withoutClear?: boolean,
    selected?: Select,
    customOptionStyle?: CSSProperties,
    customInputStyles?: CSSProperties,
    dropdownWidth?: number,
    checkIcon?: boolean,
    customInput?: React.ReactNode,
    optionFontSize?: number,
    customDropdownStyles?: CSSProperties,
    iconColor?: string,
    onBlur?(): void,
    onChangeSearchInput?(value: string): void,
    onChange?(item: Nullable<Select>): void
}

type OptionStyles = {
    isSelected: boolean,
    checkIcon?: boolean,
    fontSize?: number,
    optionColor?: string
}

export type SelectOption = {
    value: string | number,
    label: string,
    isSelected?: boolean,
    color?: string
}

export const SingleSelect: React.FunctionComponent<SingleSelectProps> = ({
    label,
    options,
    disabled = false,
    searchable = true,
    className,
    errorMessage,
    placeholder,
    onChange,
    selected,
    onBlur,
    dropdownWidth,
    customDropdownStyles,
    optionFontSize,
    customOptionStyle,
    customInputStyles,
    iconColor
}) => {
    const T = useTranslations()
    const [invertOption, setInvertOption] = useState<boolean>()
    const [isOpen, setIsOpen] = useState(false)
    const initialValue = (selected && selected?.value || '') ?? ''
    const optionsRef = useRef<HTMLDivElement>(null)
    const [selectedValue, setSelectedValue] = useState<string | number>(initialValue)
    const [localFilter, setLocalFilter] = useState('')
    const inputRef = useRef<HTMLInputElement>(null)
    const filteredOptions = options
        .filter(item => item.label.toLocaleLowerCase().includes(localFilter.toLocaleLowerCase()))
    const availableOptions = selected ? options.concat(selected) : options
    const selectedOptionLabel = availableOptions.find(item => item.value === selectedValue)?.label || ''
    const [errorsParent] = useAutoAnimate<HTMLDivElement>()
    const [changeOnBlur, setChangeOnBlur] = useState(false)
    const firstCallComponent = useRef(true)

    useEffect(() => {
        setSelectedValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }

        if (isOpen) {
            const position = optionsRef.current?.getBoundingClientRect()
            const bottomPosition = (position?.y || 0) + (position?.height || 0)

            if (bottomPosition > window.innerHeight) {

                return setInvertOption(true)
            }

            setInvertOption(false)
        }

        if (!isOpen) {
            setInvertOption(undefined)
        }
    }, [isOpen])

    useEffect(() => {
        if (!firstCallComponent.current) {
            R.ifDefined(onBlur, R.call)
        }

        if (firstCallComponent.current) {
            firstCallComponent.current = false
        }
    }, [changeOnBlur])

    return (
        <ClickAwayComponent
            isOpened={isOpen}
            onClickOutside={() => {
                setIsOpen(false)
                setLocalFilter('')
                setChangeOnBlur(prev => !prev)
            }}
        >
            <Container className={className}>
                {Boolean(label) && (
                    <StyledLabel>
                        {label}
                    </StyledLabel>
                )}
                <StyledSelect
                    style={customInputStyles}
                    placeholder={placeholder}
                    readOnly
                    hasError={Boolean(errorMessage)}
                    noOptions={disabled}
                    hasClose={Boolean(selectedValue)}
                    onClick={() => !disabled && setIsOpen(true)}
                    value={selectedOptionLabel}
                />
                {isOpen && (
                    <OptionsContainer
                        ref={optionsRef}
                        invert={invertOption}
                        numberOfItems={filteredOptions.length}
                        dropdownWidth={dropdownWidth}
                        style={customDropdownStyles}
                    >
                        {searchable && (<StyledInputSearch
                            ref={inputRef}
                            height={46}
                            placeholder={T.common.search}
                            label={T.common.inputPlaceholder}
                            onChange={value => setLocalFilter(value)}
                        />)}
                        {filteredOptions.length === 0 && (
                            <NoOptionMessage>
                                {T.common.noResults}
                            </NoOptionMessage>
                        )}
                        <InnerOptionContainer>
                            {filteredOptions.map((item, index) => {
                                const isSelected = item.value === (selected?.value || selectedValue)

                                return (
                                    <Option
                                        fontSize={optionFontSize}
                                        style={customOptionStyle}
                                        key={index}
                                        isSelected={isSelected}
                                        onClick={() => {
                                            setSelectedValue(item.value)
                                            setIsOpen(false)
                                            setLocalFilter('')

                                            if (onChange) {
                                                onChange(item)
                                            }
                                        }}
                                    >
                                        {item.label}
                                    </Option>
                                )
                            })}
                        </InnerOptionContainer>
                    </OptionsContainer>
                )}
                {!disabled && (
                    <IconContainer style={{ right: 16 }}>
                        <ArrowDown
                            width={20}
                            height={20}
                            fill={iconColor || colors.gray.typography}
                        />
                    </IconContainer>
                )}
                <ErrorContainer ref={errorsParent}>
                    {Boolean(errorMessage) && (
                        <ErrorMessage>
                            {errorMessage}
                        </ErrorMessage>
                    )}
                </ErrorContainer>
            </Container>
        </ClickAwayComponent>
    )
}

const ErrorContainer = styled.div`
    position: absolute;
    bottom: -10px;
    left: 0;
    transform: translateY(100%);
`

const ErrorMessage = styled.div`
    display: block;
    word-break: break-word;
    color: ${colors.red};
    font-size: 14px;
`

const Container = styled.div`
    width: 100%;
    display: block;
    position: relative;
`

const StyledLabel = styled.div`
    position: absolute;
    top: -6px;
    left: 10px;
`

const StyledSelect = styled.input<SelectStyleActionProps>`
    appearance: none;
    outline: none;
    width: 100%;
    cursor: ${props => props.noOptions ? 'not-allowed' : 'pointer'};
    display: flex;
    align-items: center;
    height: 66px;
    font-size: 16px;
    padding: 16px 40px 16px 16px;
    border-radius: 4px;
    color: ${colors.black};
    border: solid 1px ${props => props.hasError
        ? colors.red
        : colors.layout.border};
    overflow: hidden;
    white-space: nowrap;
    line-clamp: 1;
    ::placeholder {
    color: ${colors.typography.placeholder};
    font-size: 16px;
    } 
`

const IconContainer = styled.div`
    display: flex;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

`

const OptionsContainer = styled.div<OptionContainerProps>`
    padding-top: 10px;
    position: absolute;
    bottom: ${props => props.invert ? 'unset' : 0};
    top: ${props => props.invert ? 0 : 'unset'};
    transform: ${props => `translateY(${props.invert ? '-100%' : '100%'})`};
    width: ${props => props.dropdownWidth ? `${props.dropdownWidth}px` : '100%'};
    display: flex;
    flex-direction: column;
    z-index: 10;
    background-color: white;
    box-shadow: ${`0px 0px 18px rgba(0, 0, 0, .12)`};
    border-radius: 18px;
    min-height: ${props => props.numberOfItems === 0 ? '200px' : 'auto'};
    opacity: ${({ invert }) => invert !== undefined ? 1 : 0};
    max-height: 250px;
    padding-bottom: 10px;
`

const InnerOptionContainer = styled.div`
    overflow-y: auto;
    > div {
        border-bottom: ${`1px solid ${colors.gray}`};
    }
    > div:last-of-type {
        border-bottom: unset;
    }
`

const Option = styled.div<OptionStyles>`
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px 23px 15px 5px;
    cursor: pointer;
    font-size: ${props => props.fontSize ? `${props.fontSize}px` : '16px'};
    color: ${props => props.isSelected ? colors.blue : colors.black};
    margin-left: 20px;
    margin-right: 20px;
    :hover {
        color: ${colors.blue}
    }
`

const NoOptionMessage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
`

const StyledInputSearch = styled(OutlinedInput)`
    padding-left: 7px;
    padding-right: 7px;
`
