import styled from 'styled-components'

export const UploadInput = styled.input`
	display: none !important;
`

export const UploadButtonContainer = styled.div`
	margin-top: 2rem;
`
export const Border = styled.hr`
	width: 100%;
	border-color: ${({ theme }) => theme.colors.grey[100]}
`
