import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslations } from 'lib/hooks'
import { Button, NoteIcon } from 'app/UI'
import {
    Description,
    IconContainer,
    NoteContainer
} from './styles'

type ErrorSectionProps = {
    hasError: boolean,
    message: string,
    onRefetch: VoidFunction,
    hasEmptyNotes?: boolean,
    hasEmptyRecords?: boolean
}

export const ErrorSection: React.FunctionComponent<ErrorSectionProps> = ({
    hasError,
    hasEmptyNotes,
    hasEmptyRecords,
    message,
    onRefetch
}) => {
    const T = useTranslations()
    const theme = useTheme()
    const errorMessage = hasEmptyNotes ? T.components.noteNotAvailable : hasEmptyRecords ? T.pages.noRecordsAvailable : message

    return  hasError ? (
        <Container>
            <NoteContainer
                responsiveMdalignICenter
                topBorder
            >
                <IconContainer
                    responsiveMdIcon
                    color={theme.colors.orange}
                >
                    <NoteIcon
                        width={25}
                        height={25}
                        fill={theme.colors.white}
                    />
                </IconContainer>
                <div>
                    <Description>
                        {errorMessage}
                        {!hasEmptyNotes && !hasEmptyRecords && <Button
                            state="info"
                            label={T.common.tryAgain}
                            onClick={onRefetch}
                            noBorder
                            noPadding
                        />}
                    </Description>
                </div>
            </NoteContainer>
        </Container>
    ) : null
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	height: 100vh;
`
