import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslations } from 'lib/hooks'
import { FilterType, KeyValuePair, Position, SelectOption, Sort, TableConfig, TableMethods, Values } from 'lib/types'
import { colors, stylesHelpers } from 'lib/styles'
import { Loader } from '../Loader'
import { useTable } from './hooks'
import { TableHeaderCell } from './tableHelpers'
import { BodyContainer, Pagination, FilterTemplate } from './components'

enum FilterConfigKeys {
    OrderBy = 'orderBy',
    OrderWay = 'orderWay'
}

export type ActionButton = {
    text: string,
    onClick: VoidFunction,
    width?: number,
    textTooltip?: string,
    disabled?: boolean,
    customButton?(): React.ReactNode
}

export enum OptionType {
    Number = 'number'
}

export type Filter = {
    key: string,
    typ: FilterType,
    label?: string,
    options?: Array<SelectOption>,
    optionType?: OptionType,
    width?: number,
    debounce?: boolean,
    maxDate?: Date,
    liveParser?(value: string): string
}

type TableProps<T> = {
    data: Array<T>,
    footerActionButton?: Array<ActionButton>,
    hasError: boolean,
    isLoading: boolean,
    limitPicker?: boolean,
    withSorting?: boolean,
    config: Array<TableConfig<T>>,
    withPagination?: boolean,
    filtersConfig?: Array<Filter>,
    count: number,
    defaultLimit?: number,
    limitPickerOption?: Array<number>,
    smallPadding?: boolean,
    customRowHeight?: number,
    customRowLimit?: number,
    enableAutoScrollTop?: boolean,
    dropdownLimitPickerPosition?: Position,
    headerHeight?: number,
    onInitialize?(data: TableMethods): void,
    renderEmptyListMessage?(): React.ReactNode,
    renderError?(): React.ReactNode,
    onChange?(requestParams: KeyValuePair): void,
    renderActions?(filters: KeyValuePair): React.ReactNode
}

type TableContainerStyles = {
    hasPagination: boolean,
    customRowHeight?: number
}

type TableStyleProps = {
    smallPadding: boolean,
    customRowHeight?: number
}

export type SortConfig = {
    value: Sort,
    key: string
}

type FilterObject = {
    key: string,
    value: string | number
}

export type FilterConfig = Array<FilterObject>

export const Table = <T extends KeyValuePair>({
    config,
    data,
    isLoading,
    hasError,
    renderError,
    withPagination,
    footerActionButton,
    limitPicker,
    count,
    onChange,
    renderEmptyListMessage,
    onInitialize,
    limitPickerOption,
    customRowHeight,
    smallPadding = false,
    filtersConfig,
    dropdownLimitPickerPosition,
    customRowLimit = 10,
    headerHeight
}: TableProps<T>) => {
    const filteredConfig = config
        .filter(item => !item.hidden)
    const Translation = useTranslations()
    const hasPagination = Boolean(withPagination && !hasError && data.length > 0)
    const [ sorting, setSorting ] = useState<KeyValuePair<Sort>>({})
    const { values, functions } = useTable({
        onChange,
        defaultLimit: customRowLimit,
        allRows: count
    })
    const valuesRef = useRef<Values>(values)

    useEffect(() => {
        if (onInitialize) {
            onInitialize({
                getValues: () => valuesRef.current,
                resetSorting: () => {
                    setSorting({})
                    functions.setFilters({})
                },
                setSortingState: (config: SortConfig) => {
                    setSorting({
                        [config.key]: config.value
                    })
                    functions.setFilters(prevState => ({
                        ...prevState,
                        [FilterConfigKeys.OrderBy]: config.key,
                        [FilterConfigKeys.OrderWay]: config.value
                    }))
                },
                setFilterState: (config: FilterConfig) => {
                    const filters = config.reduce((acc, item) => ({
                        ...acc,
                        [item.key]: item.value
                    }), {} as FilterObject)

                    functions.setCurrentPage(1)
                    functions.setFilters(prevState => ({
                        ...prevState,
                        ...filters
                    }))
                },
                setFirstPage: () => functions.setCurrentPage(1)
            })
        }
    }, [])

    useEffect(() => {
        valuesRef.current = values
    }, [values])

    return (
        <TableWrapper>
            {filtersConfig && (
                <FilterTemplate
                    filters={values.filters}
                    filtersConfig={filtersConfig}
                    onChangeFilter={functions.changeFilter}
                />
            )}
            <TableContainer
                hasPagination={hasPagination}
                customRowHeight={customRowHeight}
            >
                {isLoading && (
                    <AbsoluteContainer>
                        <Loader isLoading />
                    </AbsoluteContainer>
                )}
                <MainTable
                    smallPadding={smallPadding}
                    customRowHeight={customRowHeight}
                >
                    <TableHeader>
                        <tr>
                            {filteredConfig?.map((item, index) => (
                                <TableHeaderCell
                                    headerHeight={headerHeight}
                                    sticky={item.stickyColumn}
                                    key={`${item.label}-${index}`}
                                    width={item.width}
                                    alignment={item.alignment}
                                    sorting={sorting[item.key || item.label]}
                                    onSortChange={value => {
                                        if (item.withoutSorting) {
                                            return
                                        }

                                        functions.changeSort(item.key || item.label, value)

                                        setSorting({
                                            [item.key || item.label]: value
                                        })
                                    }}
                                >
                                    {item.label}
                                </TableHeaderCell>
                            ))}
                        </tr>
                    </TableHeader>
                    <BodyContainer
                        data={data}
                        hasError={hasError}
                        config={filteredConfig}
                        offset={values.offset}
                    />
                </MainTable>
                {data.length === 0 && !isLoading && !hasError && (
                    <EmptyListContainer>
                        {renderEmptyListMessage ? renderEmptyListMessage() : 'Empty list'}
                    </EmptyListContainer>
                )}
                {hasError && (
                    <EmptyListContainer>
                        {renderError ? renderError() : Translation.components.notes.fetchError}
                    </EmptyListContainer>
                )}
            </TableContainer>
            {hasPagination && (
                <Pagination
                    dropdownLimitPickerPosition={dropdownLimitPickerPosition}
                    count={values.count}
                    limit={values.limit}
                    limitPicker={limitPicker}
                    isLastPage={values.isLastPage}
                    isFirstPage={values.isFirstPage}
                    currentPage={values.currentPage}
                    onPageClick={functions.onPageClick}
                    onNextClick={functions.onNextClick}
                    onPrevClick={functions.onPrevClick}
                    limitPickerOption={limitPickerOption}
                    footerActionButton={footerActionButton}
                    onChangeLimit={functions.onChangeLimit}
                    onLastPageClick={functions.onLastPageClick}
                    onFirstPageClick={functions.onFirstPageClick}
                    renderPageNumbers={functions.renderPageNumbers()}
                />
            )}
        </TableWrapper>
    )
}

const TableWrapper = styled.div`
    position: relative;
    padding: 2px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
`

const TableContainer = styled.div<TableContainerStyles>`
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: ${props => props.hasPagination && !props.customRowHeight ? '30px' : ''};
    min-height: 200px;
    height: 100%;
    overflow: auto;
`

const MainTable = styled.table<TableStyleProps>`
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    td {
        color: ${colors.typography};
    }
    tr {
        border-bottom: 1px solid ${stylesHelpers.hexToRGBA(colors.table.border, 0.47)};
    }
    td:last-child {
        border-right: unset;
    }
    thead td {
        color: ${colors.typography};
        font-size: 14px;
        font-weight: bold;
        padding: ${props => props.smallPadding ? `0 12px 2px 12px` : `0 25px 5px 25px`};
    }
    tbody td {
        height: ${props => props.customRowHeight ? `${props.customRowHeight}px` : 'auto'};
        padding: ${props => props.smallPadding ? `12px` : '10px 25px 10px 25px'};
        vertical-align: middle;
        font-size: 14px;
    }
`

const TableHeader = styled.thead`
    position: sticky;
    top: 0;
    background-color: ${colors.white};
    z-index: 0;
    td {
        padding-left: 3px;
        border-bottom: 1px solid ${stylesHelpers.hexToRGBA(colors.table.border, 0.47)};
    }
`

const AbsoluteContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    flex-direction: column;
    z-index: 2;
`

const EmptyListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    font-size: 12px;
    font-weight: bold;
    height: 100%;
`
