import { RecordingInfo } from './Recording'
import { Nullable } from 'lib/types'

export enum ReplyStatus {
    Created = 'Created',
    Finished = 'Finished',
    Missed = 'Missed',
    Canceled = 'Canceled'
}

export type Reply = {
    createdAt: string,
    url: string,
    status: ReplyStatus
}

export type NoteBody = {
    content: string,
    createdAt: number,
    teacherName: string
}

export type NEINote = {
    appointmentId: string,
    callTime: string,
    note: Nullable<NoteBody>,
    replayChallengeAnswerRecordings: Array<Reply>
}

export type VCNote = {
    recordingInfo: RecordingInfo
}

export interface Note extends NEINote, VCNote {
    status: 'Created' | 'Finished'
}
