import React, { useEffect } from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { CookieKeys, TryFreeEnum } from 'lib/types'
import { useSetLanguage, useTryFree } from 'lib/hooks'
import { useGeneratePage } from './hooks'
import { getAuthCookie, getCookieByKey } from 'lib/api'
import { Languages } from 'lib/locale'
import { useGetUserData } from './actions'
import { NewLoader } from 'app/Components'
import './styles.css'
import { globalActions } from 'lib/actions'
import { CONFIG } from 'lib/config'

export const TryFree: React.FunctionComponent = () => {
    const userIsLogged = getAuthCookie()
    const { tryFreeActions: { setPage, setUserIsLogged, setTimezones, setAreaCodes } } = useTryFree()
    const Component = useGeneratePage()
    const { setLanguage } = useSetLanguage()
    const { fetch: getUserData, fetchState: { isLoading } } = useGetUserData(
        hasAppointment => {
            setUserIsLogged(true)

            if (hasAppointment) {
                setPage({
                    page: TryFreeEnum.ConfirmBooking,
                    consultationBooked: true
                })

                return
            }

            setPage({
                page: TryFreeEnum.Book
            })
        }
    )
    const { fetch: getTimezones, fetchState: { isLoading: gettingTimezones } } = globalActions.useGetTimezones(setTimezones)
    const { fetch: getAreaCodes, fetchState: { isLoading: gettingAreaCodes } } = globalActions.useGetAreaCodes(setAreaCodes)

    useEffect(() => {
        getTimezones()
        getAreaCodes()

        if (!userIsLogged || CONFIG.IS_PUBLIC_LANDING_PAGE) {
            return
        }

        getUserData()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [Component])

    useEffect(() => {
        const language = getCookieByKey(CookieKeys.Language)

        if (!language) {
            return
        }

        const data = decodeURIComponent(language).split('"')
        const selectedLanguage = data[data.length - 2]

        if (selectedLanguage === Languages.en_US || selectedLanguage === Languages.ru_RU || selectedLanguage === Languages.sp_SP) {
            setLanguage(Languages[selectedLanguage])

            return
        }

        if (selectedLanguage === 'Japanese') {
            setLanguage(Languages.ja_JP)
        }
    }, [])

    return (
        <Wrapper>
            {isLoading || gettingTimezones || gettingAreaCodes ? (
                <LoaderWrapper>
                    <NewLoader/>
                </LoaderWrapper>
            ) : (
                <Component/>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 920px;
    margin: auto;
    margin-top: 20px;
    color: ${colors.typography.primary};
    * {
      box-sizing: border-box;
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
   }
`

const LoaderWrapper = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`
