import React from 'react'
import { SvgIcon } from 'lib/types'
import { Svg } from 'app/UI/Icons'

export const ArrowDown: React.FunctionComponent<SvgIcon> = ({
    width,
    height,
    fill,
    viewBox
}) => (
    <Svg
        viewBox={viewBox || '0 0 80 80'}
        svgWdith={width}
        svgHeight={height}
    >
        <path fill={fill} d="M10.003 27.531a2.532 2.532 0 014.235-1.872l25.764 23.384 25.764-23.384a2.533 2.533 0 113.405 3.751L41.705 54.341a2.523 2.523 0 01-3.405 0L10.833 29.407a2.546 2.546 0 01-.83-1.876z"/>
    </Svg>
)
