import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Picar: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M34.484 53.857a2.306 2.306 0 01-1.714-.764l-8.3-9.225a2.307 2.307 0 013.429-3.086l6.528 7.254 17.667-21.032a2.306 2.306 0 013.532 2.967L36.253 53.034a2.306 2.306 0 01-1.726.823z" data-name="Path 14"/>
        <path d="M60.776 70.001H19.262a9.236 9.236 0 01-9.225-9.23V19.262a9.236 9.236 0 019.225-9.225h41.514a9.236 9.236 0 019.225 9.225v41.509a9.236 9.236 0 01-9.225 9.23zM19.262 14.65a4.618 4.618 0 00-4.613 4.613v41.508a4.618 4.618 0 004.613 4.613h41.514a4.618 4.618 0 004.613-4.613V19.262a4.618 4.618 0 00-4.613-4.613z" data-name="Path 15"/>
    </Icon>
)
