import styled from 'styled-components'
import { ModelProps } from 'app/Components/types'

export const ModelContainer = styled.div<ModelProps>`
	display: ${({ showModel }) => (showModel ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	position: fixed;
	background: ${({ theme }) => theme.colors.black[300]} 0% 0% no-repeat padding-box;
	width: 100%;
	height: 100%;
	z-index: 1000;
	top: 0;
	left: 0;
	cursor: auto;
	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		left: -9px;
	}
	body {
		overflow: hidden;
	}
`

export const ModelContent = styled.div<ModelProps>`
	background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
	border-radius: 16px;
	opacity: 1;
	width: 90%;
	height: 85%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	${({ controlWidthHeight, theme }) => controlWidthHeight ? `
		@media (max-width: ${theme.breakpoints.md}) {
			height: 50%;
		}
	` : ''}
`

export const CloseContainer = styled.button`
	background: none;
	cursor: pointer;
	border: none;
	padding: 0;
	position: absolute;
	top: 10px;
	right: 10px;
`

export const ChildrenContent = styled.div<ModelProps>`
	width: 90%;
	display: flex;
	${({ fixedHeight }) => fixedHeight ? 'height: 80%;' : ''}
	align-items: center;
	@media (max-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-direction: column;
	}
`
