import React from 'react'
import { SvgIcon } from 'lib/types'
import { Svg } from 'app/UI/Icons'

export const Upload: React.FunctionComponent<SvgIcon> = ({
    width,
    height,
    fill
}) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 38.312 31.16"
        svgWdith={width}
        svgHeight={height}
    >
        <g
            data-name="Group 3"
            fill={fill}
        >
            <path
                data-name="Path 1"
                d="M29.632 25.142h-5.687a1.2 1.2 0 010-2.395h5.687a7.434 7.434 0 004.55-1.349 4.461 4.461 0 001.736-3.709 4.356 4.356 0 00-1.9-3.633 7.854 7.854 0 00-4.144-1.427 1.2 1.2 0 01-1.131-1.071 10.4 10.4 0 00-3.208-6.748 9.4 9.4 0 00-6.379-2.418 8.845 8.845 0 00-5.572 1.878 8.727 8.727 0 00-2.866 4.115 1.2 1.2 0 01-1.027.825c-3.632.345-7.3 2.572-7.3 6.77a6.3 6.3 0 002.226 4.83 8.465 8.465 0 005.559 1.935h4.19a1.2 1.2 0 010 2.395h-4.19a10.855 10.855 0 01-7.124-2.518A8.66 8.66 0 010 15.983 8.4 8.4 0 012.912 9.51 11.373 11.373 0 018.7 6.924 11.1 11.1 0 0119.156 0a11.791 11.791 0 017.995 3.045 12.532 12.532 0 013.839 7.292 10.065 10.065 0 014.454 1.8 6.723 6.723 0 012.868 5.555 6.834 6.834 0 01-2.66 5.6 9.816 9.816 0 01-6.02 1.85z"
            />
            <path
                data-name="Path 2"
                d="M19.156 31.16a1.2 1.2 0 01-1.2-1.2v-16.3l-2.745 2.745a1.2 1.2 0 01-1.693-1.693l4.789-4.789a1.2 1.2 0 011.693 0l4.789 4.789a1.2 1.2 0 01-1.693 1.693l-2.745-2.745v16.3a1.2 1.2 0 01-1.195 1.2z"
            />
        </g>
    </Svg>
)
