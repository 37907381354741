import { getMonth, getYear } from 'date-fns'
import React from 'react'
import { R } from 'lib/utils'
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import styled from 'styled-components'
import { Months } from './constants'

export const CustomHeader: React.FunctionComponent<ReactDatePickerCustomHeaderProps> = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
}) => {
    const yearsRange = R.range(1950, getYear(new Date()) + 1)

    return (
        <HeaderWrapper>
            <NextButton
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
            >
                {'<'}
            </NextButton>
            <SelectWrapper>
                <select
                    value={getYear(date) as number}
                    onChange={({ target: { value } }) => changeYear(Number(value))}
                >
                    {yearsRange.map(option => (
                        <option
                            key={option}
                            value={option}
                        >
                            {option}
                        </option>
                    ))}
                </select>
                <select
                    value={Months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                        changeMonth(Months.indexOf(value))
                    }
                >
                    {Months.map(option => (
                        <option
                            key={option}
                            value={option}
                        >
                            {option}
                        </option>
                    ))}
                </select>
            </SelectWrapper>
            <NextButton
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
            >
                {'>'}
            </NextButton>
        </HeaderWrapper>
    )
}

const HeaderWrapper = styled.div`
    display: flex;
    margin: 10px;
    justify-content: center;
`

const SelectWrapper = styled.div`
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    gap: 10px;
`

const NextButton = styled.button`
    border: none;
    background-color: transparent;
    font-size: 18px;
`
