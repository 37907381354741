import React from 'react'
import styled, { keyframes } from 'styled-components'
import { NewLoader } from 'app/Components'
import { textUtils } from 'lib/utils'
import { colors, stylesHelpers, animations } from 'lib/styles'
import { Close } from 'app/UI'
import { Button } from '../Buttons'

export type Action = {
    name: string,
    flag?: string,
    color?: string,
    backgroundColor?: string,
    isLoading?: boolean,
    disabled?: boolean,
    hidden?: boolean,
    rightAuto?: boolean,
    hoverOptions?: Array<Action>,
    onClick?(event?: HTMLDivElement): void
}

type DialogProps = {
    title: string,
    minWidth?: number,
    actions?: Array<Action>,
    customFooter?: React.ReactNode,
    subtitle?: React.ReactNode,
    isLoading?: boolean,
    bodyLoader?: boolean,
    dialogStyles?: React.CSSProperties,
    bodyStyles?: React.CSSProperties,
    headerStyle?: React.CSSProperties,
    onClose?(): void
}

type DialogStyles = {
    minWidth?: number
}

type ButtonStyles = {
    autoRight?: boolean
}

export const Dialog: React.FunctionComponent<DialogProps> = ({
    title,
    children,
    onClose,
    actions,
    minWidth,
    customFooter,
    subtitle,
    isLoading,
    bodyLoader,
    dialogStyles,
    bodyStyles,
    headerStyle
}) => (
    <Container>
        <Wrapper
            minWidth={minWidth}
            style={dialogStyles}
        >
            <Header style={headerStyle}>
                <TopHeader>
                    <Title>
                        {textUtils.upperCaseFirstLetter(title)}
                    </Title>
                    {onClose && (
                        <CloseWrapper>
                            <Close
                                fill={colors.icon.lightGray}
                                width={18}
                                height={18}
                                onClick={() => !isLoading && onClose()}
                            />
                        </CloseWrapper>
                    )}
                </TopHeader>
                {subtitle}
            </Header>
            <Body style={bodyStyles}>
                {isLoading && bodyLoader && (
                    <LoaderWrapper>
                        <NewLoader/>
                    </LoaderWrapper>
                )}
                {!isLoading && children && (
                    <DialogContent>
                        {children}
                    </DialogContent>
                )}
            </Body>
            {(actions || customFooter) && (
                <Footer>
                    {actions && actions.filter(item => !item.hidden).map((action, index) => (
                        <ButtonWrapper
                            autoRight={action.rightAuto}
                            key={`${action.name}${index}`}
                        >
                            <Button
                                onClick={action.onClick}
                                textColor={action.color}
                                disabled={action.disabled}
                                content={action.name as string}
                                backgroundColor={action.backgroundColor}
                                isLoading={!action.disabled && action.isLoading}
                            />
                        </ButtonWrapper>
                    ))}
                    {customFooter}
                </Footer>
            )}
        </Wrapper>
    </Container>
)

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
  background-color: ${stylesHelpers.customBlackTransparent(0.6)};
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: ${animations.fadeIn} 0.5s;
`

const slideUp = keyframes`
  from {
    opacity: 0;
    margin-top: -200px;
  }
  
  to {
    opacity: 1;
    margin-top: 0;
  }
`

const Wrapper = styled.div<DialogStyles>`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 12px;
  padding: 20px;
  min-width: ${props => props.minWidth || 200}px;
  animation: ${slideUp} 0.4s ease-in-out;
`

const Body = styled.div`
  white-space: pre-line;
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const DialogContent = styled.div`
  overflow-y: auto;
`

const Header = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid ${colors.gray.border};
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 24px;
  line-height: 18px;
  text-align: left;
`

const Footer = styled.div`
  padding-top: 20px;
  border-top: 1px solid ${colors.gray.border};
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const TopHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ButtonWrapper = styled.div<ButtonStyles>`
  margin-left: 8px;
  min-width: 50px;
  margin-right: ${({ autoRight }) => autoRight ? 'auto' : 'unset'};
`

const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
`

const CloseWrapper = styled.div`
  cursor: pointer;
`
