import { useFetch } from 'lib/hooks'
import {HttpMethod, OnSuccessResponse, Consultation, OnErrorResponse} from 'lib/types'
import { CONFIG } from 'lib/config'
import { ConsultationAvailabilityRequest, SignUpResponse, SingUpRequest } from './types'

export const useGetConsultation = (onSuccess: OnSuccessResponse<Array<Consultation>>) =>
    useFetch<Array<Consultation>, ConsultationAvailabilityRequest>({
        url: `${CONFIG.SCHEDULER_API}/availability/consultation`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useGetUserData = (onSuccess: OnSuccessResponse<boolean>) =>
    useFetch<boolean>({
        url: `${CONFIG.USER_API}/user/appointment`,
        method: HttpMethod.GET
    }, {
        onSuccess
    })

export const useAddConsultation = (onSuccess: VoidFunction) =>
    useFetch<void, Consultation>({
        url: `${CONFIG.SCHEDULER_API}/appointment/consultation`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const useSignUp = (onSuccess: OnSuccessResponse<SignUpResponse, SingUpRequest>, onError: OnErrorResponse) =>
    useFetch<SignUpResponse, SingUpRequest>({
        url: `${CONFIG.USER_API}/user/try-free`,
        method: HttpMethod.POST
    }, {
        onSuccess,
        onError
    })
