import { SvgIcon } from 'lib/types'
import React from 'react'
import { Svg } from 'app/UI/Icons'

export const Close: React.FunctionComponent<SvgIcon> = ({
    width,
    height,
    fill,
    viewBox,
    onClick
}) => (
    <Svg
        viewBox={viewBox || '0 0 80 80'}
        svgWdith={width}
        svgHeight={height}
        onClick={onClick}
    >
        <path fill={fill} d="M65.942 69.672a3.717 3.717 0 01-2.637-1.092L39.836 45.111 16.367 68.58a3.73 3.73 0 01-5.274-5.274l23.465-23.472-23.469-23.469a3.73 3.73 0 115.274-5.274L39.832 34.56l23.469-23.469a3.73 3.73 0 115.274 5.274L45.11 39.834l23.469 23.469a3.73 3.73 0 01-2.637 6.367z" data-name="Path 364"/>
    </Svg>
)
