import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Company: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M61.607 65.608H17.939a7.948 7.948 0 01-7.94-7.94V29.88a7.949 7.949 0 017.94-7.94h43.667a7.949 7.949 0 017.94 7.94v27.788a7.949 7.949 0 01-7.939 7.94zM17.939 25.91a3.974 3.974 0 00-3.97 3.97v27.788a3.974 3.974 0 003.97 3.97h43.667a3.974 3.974 0 003.97-3.97V29.88a3.974 3.974 0 00-3.97-3.97z" data-name="Path 8"/>
        <path d="M46.72 43.773H32.826a2.981 2.981 0 01-2.977-2.977v-.992H11.985a1.985 1.985 0 110-3.97h55.577a1.985 1.985 0 110 3.97H49.698v.992a2.981 2.981 0 01-2.978 2.977zm6.947-17.864a1.985 1.985 0 01-1.985-1.985v-3.97a1.987 1.987 0 00-1.985-1.985H29.849a1.987 1.987 0 00-1.985 1.985v3.97a1.985 1.985 0 01-3.97 0v-3.97a5.961 5.961 0 015.955-5.955h19.849a5.961 5.961 0 015.955 5.955v3.97a1.985 1.985 0 01-1.986 1.985z" data-name="Path 9"/>
    </Icon>
)
