import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { colors } from 'lib/styles'
import { useGetAvaMicroTopicConversations, useGetAvaUserStats } from '../actions'
import { Button, Dialog, Table } from 'app/Components'
import { dateHelpers, R } from 'lib/utils'
import { useTranslations } from 'lib/hooks'
import {
    GetAvaConversationMicroTopicsResponse,
    GetAvaUserStats
} from '../types'
import {
    useAvaMicroTopicConversationsTableConfig
} from '../constants'
import { fromUnixTime } from 'date-fns'
import { AvaConversationsAll } from './AvaConversationsAll'

export const AvaConversationsMicroTopics: React.FunctionComponent = () => {
    const T = useTranslations()
    const customRowLimit = 30
    const [avaConversations, setAvaConversations] = useState<GetAvaConversationMicroTopicsResponse>({
        conversations: [],
        count: 0
    })
    const [avaMicroTopic, setAvaMicroTopic] = useState<string>()
    const [currentUserStats, setCurrentUserStats] = useState<GetAvaUserStats>()
    const config = useAvaMicroTopicConversationsTableConfig({setAvaMicroTopic})

    const { fetch: getAvaConversations, fetchState: { isLoading, hasError } } = useGetAvaMicroTopicConversations(setAvaConversations)

    useEffect(() => {
        getAvaConversations({
            limit: customRowLimit,
            forCurrentUser: true
        })
    }, [])

    return (
        <Container>
            <TableWrapper>
                <Table
                    // filtersConfig={filterConfig}
                    smallPadding
                    customRowLimit={customRowLimit}
                    withSorting
                    data={avaConversations.conversations}
                    hasError={hasError}
                    isLoading={isLoading}
                    config={config}
                    count={avaConversations.count}
                    withPagination
                    renderEmptyListMessage={() => T.common.noMatchResult}
                    renderError={() => (
                        <ErrorWrapper>
                            {T.common.error.fetchError}
                            <ButtonWrapper>
                                <Button.Button
                                    onClick={() => getAvaConversations({
                                        limit: customRowLimit,
                                        forCurrentUser: true
                                    })}
                                    backgroundColor={colors.primary}
                                    textColor={colors.white}
                                >
                                    {T.common.retry}
                                </Button.Button>
                            </ButtonWrapper>
                        </ErrorWrapper>
                    )}
                    onChange={requestParams => {
                        const startDate = requestParams.startDate ? dateHelpers.getStartOfDayInDefaultTimezone(fromUnixTime(requestParams.startDate)) : undefined
                        const endDate = requestParams.endDate ? dateHelpers.getStartOfDayInDefaultTimezone(fromUnixTime(requestParams.endDate)) : undefined
                        const dataToSend = R.clearObject({
                            ...requestParams,
                            startDate,
                            endDate,
                            forCurrentUser: true
                        } || {})

                        getAvaConversations(dataToSend)
                    }}
                />
            </TableWrapper>
            {avaMicroTopic && (<Dialog
                bodyStyles={{minHeight: 'unset', minWidth: '700px', maxHeight: '600px'}}
                title={avaMicroTopic?.replace(/([a-z\d])([A-Z])/g, '$1 $2')}
                onClose={() => setAvaMicroTopic(undefined)}
            >
                <AvaConversationsAll
                    rolePlay={avaMicroTopic}/>
            </Dialog>)}
        </Container>
    )
}

const Wrapper = styled.div`
    width: 100%;
    max-width: 600px;
    margin: auto;
    color: ${colors.typography.primary};
    * {
      box-sizing: border-box;
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    }
`

const Text = styled.div`
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
`

const Container = styled.div`
    //padding: 20px;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 18px;
    box-shadow: '0px 0px 30px #00000029';
    position: relative;
    background-color: ${colors.white};
`

const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const TableWrapper = styled.div`
    display: flex;
    margin-top: 40px;
    height: 100%;
    overflow-y: hidden;
`

const ButtonWrapper = styled.div`
    width: 120px;
`

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`