import React, { Fragment } from 'react'
import ReactPlayer from 'react-player'
import { useTranslations } from 'lib/hooks'
import { GoogleParticipant, VCEventType } from 'lib/models'
import { Message, Modal, SharedVideoTypes, VideoPlayerModelProps } from 'app/Components'
import useVideoPlayerModel from './useVideoPlayerModel'
import { ChatContianer, VideoContainer, VideoContent } from './style'
import { isIOS } from './utils'

export const VideoPlayerModel: React.FunctionComponent<VideoPlayerModelProps & SharedVideoTypes> = ({
    showModel,
    onClick,
    recording,
    chatHistory,
    participants
}) => {
    const T = useTranslations()
    const { hasChatHistory, chatUsers, videoRef, recordStartTime, setTime } = useVideoPlayerModel({
        chatHistory,
        participants,
        recording,
        showModel
    })
    const source = recording.files.m3u8 || recording.files.mp4
    const shouldPlayNative = isIOS() || !recording.files.m3u8

    return (
        <Modal
            fixedHeight
            onClick={onClick}
            showModel={showModel}
            controlWidthHeight={!hasChatHistory}
        >
            <VideoContainer controlWidthHeight={!hasChatHistory}>
                {source && !shouldPlayNative && (
                    <ReactPlayer
                        controls
                        url={source}
                        width="100%"
                        className="cg-video-player"
                        height="100%"
                        config={
                            {
                                file: {
                                    forceHLS: true,
                                    hlsOptions: {
                                        xhrSetup: (xhr: any) => {
                                            xhr.withCredentials = true
                                        }
                                    }
                                }
                            }
                        }
                    />
                )}
                {source && shouldPlayNative && (
                    <VideoContent
                        controls
                        crossOrigin="use-credentials"
                        onTimeUpdate={() => setTime(undefined)}
                        ref={ref => videoRef.current = ref}
                        src={source}
                    />
                )}
                {!source && (
                    <p>
                        {T.components.browserNotSupportVideo}
                    </p>
                )}
            </VideoContainer>
            {hasChatHistory && (
                <ChatContianer>
                    {chatUsers?.map((chat, i) => {
                        if (chat.type === VCEventType.GoogleMeetTranscript) {
                            const dataToMap = (JSON.parse(chat.message) as Array<GoogleParticipant>)
                                .reduce((acc, item, localIndex) => [
                                    ...acc,
                                    {
                                        ...item,
                                        isTeacher: acc[0]?.participantName === item.participantName || localIndex === 0
                                    }
                                ], [] as Array<GoogleParticipant>)

                            return (
                                <Fragment key={i}>
                                    {dataToMap.map((item, index) => (
                                        <Fragment key={`${item.timeFrom}${index}`}>
                                            {index === 0 && (
                                                <div>
                                                    Google meet
                                                </div>
                                            )}
                                            <Message
                                                chat={chat}
                                                onClick={setTime}
                                                googleChat={item}
                                                recordStartTime={recordStartTime}
                                            />
                                        </Fragment>
                                    ))}
                                </Fragment>
                            )
                        }

                        return (
                            <Message
                                key={i}
                                chat={chat}
                                onClick={setTime}
                                recordStartTime={recordStartTime}
                            />
                        )
                    })}
                </ChatContianer>
            )}
        </Modal>
    )
}
