import { AreaCode, Consultation, Timezone, TryFreeEnum, TryFreePage } from '../types'
import { useState } from 'react'

export const useTryFreeStore = () => {
    const [page, setPage] = useState<TryFreePage>({
        page: TryFreeEnum.About
    })
    const [consultation, setConsultation] = useState<Consultation>()
    const [userEmail, setUserEmail] = useState<string>()
    const [userIsLogged, setUserIsLogged] = useState(false)
    const [timezones, setTimezones] = useState<Array<Timezone>>([])
    const [areaCodes, setAreaCodes] = useState<Array<AreaCode>>([])

    return {
        tryFreeState: {
            page,
            userEmail,
            consultation,
            userIsLogged,
            timezones,
            areaCodes
        },
        tryFreeActions: {
            setPage,
            setConsultation,
            setUserEmail,
            setUserIsLogged,
            setTimezones,
            setAreaCodes
        }
    }
}
