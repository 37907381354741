import { useFetch } from 'lib/hooks'
import { HttpMethod, OnErrorResponse, OnSuccessResponse } from 'lib/types'
import { UploadRecordingResponse } from './types'

export const useUploadRecording = (
    appointmentId: string,
    onSuccess: OnSuccessResponse<UploadRecordingResponse>,
    onError: OnErrorResponse
) => useFetch({
    url: `/appointment/${appointmentId}/replay-challenge-answer`,
    method: HttpMethod.POST
}, {
    onSuccess,
    onError
})
