import React from 'react'
import { colors } from 'lib/styles'
import { Button, Dialog, FormAdapters, FormComponents } from 'app/Components'
import styled from 'styled-components'
import { useTranslations } from 'lib/hooks'
import { AVA_SPEECH_SPEED } from '../constants'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { AvaSettingsFormShape, useAvaSettingsForm } from '../form'
import { AvaSettings } from '../types'
import { usePostUserSettings } from '../actions'

interface AvaSettingsModalProps {
    onClose: VoidFunction,
    // eslint-disable-next-line @typescript-eslint/ban-types
    onSave: Function,
    initialSettings: AvaSettings
}

export const AvaSettingsModal: React.FunctionComponent<AvaSettingsModalProps> = ({ onClose, initialSettings, onSave }) => {
    const T = useTranslations()

    const { fetch: postUserSettings, fetchState: { isLoading: isPostingUserSettings } } = usePostUserSettings((response, request) => {
        onSave(request.ava)
    })

    const { submit, form } = useForm(useAvaSettingsForm(initialSettings), {
        onSuccess(form: AvaSettingsFormShape) {
            postUserSettings({
                ava: {
                    handsFree: form.isHandsFree,
                    autoSubmitTimeout: form.autoSubmitTimeout,
                    speechSpeed: form.speechSpeed.value,
                    enableCorrections: form.enableCorrections
                }
            })
        }
    })

    return (
        <Dialog
            title={T.pages.avaSession.settingsModal.title}
            onClose={onClose}
            dialogStyles={{
                maxWidth: '600px',
                maxHeight: '80%'
            }}
            actions={[
                {
                    backgroundColor: colors.orange,
                    color: colors.white,
                    onClick: () => onClose(),
                    name: T.common.close
                }
            ]}
        >
            <Container>
                <InlineSection>
                    <FormComponents.Checkbox
                        checked={form.isHandsFree.value}
                        onChange={() => form.isHandsFree.onChangeValue(!form.isHandsFree.value)}
                    />
                    <LabelInline>{T.pages.avaSession.settingsModal.handsFree}</LabelInline>
                </InlineSection>
                {form.isHandsFree.value && (
                    <StackSection>
                        <LabelStack>{T.pages.avaSession.settingsModal.silenceTimeout}</LabelStack>
                        <InlineSection>
                            <FormAdapters.Input
                                isNumeric
                                {...form.autoSubmitTimeout}
                            />&nbsp;
                            <LabelInline>{T.common.seconds}</LabelInline>
                        </InlineSection>
                    </StackSection>
                )}
                <InlineSection>
                    <FormComponents.Checkbox
                        checked={form.enableCorrections.value}
                        onChange={() => form.enableCorrections.onChangeValue(!form.enableCorrections.value)}
                    />
                    <LabelInline>{T.pages.avaSession.settingsModal.enableCorrections}</LabelInline>
                </InlineSection>
                <StackSection>
                    <LabelStack>{T.pages.avaSession.settingsModal.speechSpeed}</LabelStack>
                    <FormAdapters.Select
                        {...form.speechSpeed}
                        searchable={false}
                        withoutClear
                        options={AVA_SPEECH_SPEED}
                    />
                </StackSection>
                <br/>
                <Button.Button
                    height={56}
                    fontSize={14}
                    backgroundColor={colors.orange}
                    textColor={colors.white}
                    onClick={submit}
                    content={T.common.save}
                    isLoading={isPostingUserSettings}
                    disabled={false}
                />

            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 350px;
`

const InlineSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`

const StackSection = styled.div`
  margin-bottom: 15px;
`

const LabelInline = styled.div`
    font-weight: 500;
    text-wrap: nowrap;
    margin-left: 10px;
    margin-right: 10px;
`

const LabelStack = styled.div`
    font-weight: 500;
    text-wrap: nowrap;
    margin-bottom: 10px;
`

const Text = styled.div`
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 26px;
`

const Link = styled.a`
    color: ${colors.linkColor};
    text-decoration: none;
    text-transform: lowercase;
`
