import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from '@codegateinc/react-form-builder-v2'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import 'react-datepicker/dist/react-datepicker.css'
import { Button, CustomHeader, FormAdapters, FormComponents, TermsModal } from 'app/Components'
import { useTranslations, useTryFree } from 'lib/hooks'
import { colors } from 'lib/styles'
import { CookieKeys, TryFreeEnum } from 'lib/types'
import { SignUpFormShape, useSignUpForm } from '../form'
import { Typography } from 'app/UI'
import { useSignUp } from '../actions'
import { CONFIG } from 'lib/config'
import { getCookieByKey } from 'lib/api'
import { Languages } from 'lib/locale'

export const SignUp: React.FunctionComponent = () => {
    const T = useTranslations()
    const [errorsParent] = useAutoAnimate<HTMLDivElement>()
    const { tryFreeState: { timezones }, tryFreeActions: { setPage, setUserEmail } } = useTryFree()
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const initialSelectedTimezone = timezones.find(timezone => timezone.timezone === browserTimezone)
    const [termsModal, setTermsModal] = useState(false)
    const initialReferralCode = getCookieByKey('referralCode') || CONFIG.REFERRAL_CODE || ''
    const { fetch: signUp, fetchState: { isLoading } } = useSignUp((data, request) => {
        if (data && !data.success) {
            if (data.messages?.email) {
                form.email.setError(data.messages.email)
            }
            if (data.messages?.password) {
                form.newPassword.setError(data.messages.password)
            }
            if (data.messages?.name) {
                form.firstName.setError(data.messages.name)
            }
            if (data.messages?.surname) {
                form.lastName.setError(data.messages.surname)
            }

            return
        }

        setPage({
            page: TryFreeEnum.Confirm
        })
        setUserEmail(request.signUp.email)
    }, ()=> {
        setPage({
            page: TryFreeEnum.Confirm
        })
    })
    const { submit, form } = useForm(useSignUpForm(initialReferralCode), {
        onSuccess: (form: SignUpFormShape) => {
            const referralCompanyMapping = [
                {referral: 'StartupVC', companyId: 84},
                {referral: 'Musashino', companyId: 84},
                {referral: 'FinMT', companyId: 83},
                {referral: 'MedTechMT', companyId: 82},
                {referral: 'sgax', companyId: 85},
                {referral: 'atrcall', companyId: 89},
                {referral: 'yuime', companyId: 86}
            ]
            let companyId
            const referral = form.referralCode
            if (referral) {
                const companyMapping = referralCompanyMapping.find(entry => referral.toLowerCase().indexOf(entry.referral.toLocaleLowerCase()) !== -1)
                companyId = companyMapping?.companyId
            }
            const language = getCookieByKey(CookieKeys.Language)
            let selectedLanguage
            if (!language) {
                selectedLanguage = Languages.en_US
            } else {
                const data = decodeURIComponent(language).split('"')
                selectedLanguage = data[data.length - 2]
            }

            signUp({
                signUp: {
                    name: form.firstName,
                    surname: form.lastName,
                    email: form.email,
                    password: form.newPassword,
                    passwordRepeat: form.confirmNewPassword,
                    isAgree: form.acceptTerms,
                    timezoneId: initialSelectedTimezone?.timezoneId || null,
                    referral,
                    // isFree: CONFIG.CREATE_FREE_ACCOUNT,
                    isFree: false,
                    companyId,
                    language: selectedLanguage
                }
            })
            setUserEmail(form.email)
        }
    })

    return (
        <>
            <Header>
                <Typography.Heading>
                    {T.pages.signUp.title}
                </Typography.Heading>
                <Typography.SubHeading>
                    {T.pages.signUp.subtitle}
                </Typography.SubHeading>
            </Header>
            <ColumnsWrapper>
                <FormPart>
                    <Label>
                        {T.pages.signUp.firstName}
                    </Label>
                    <FormAdapters.Input
                        {...form.firstName}
                    />
                </FormPart>
                <FormPart>
                    <Label>
                        {T.pages.signUp.lastName}
                    </Label>
                    <FormAdapters.Input
                        {...form.lastName}
                    />
                </FormPart>
                <FormPart>
                    <Label>
                        {T.pages.signUp.email}
                    </Label>
                    <FormAdapters.Input
                        {...form.email}
                    />
                </FormPart>
                <FormPart>
                    <Label>
                        {T.pages.signUp.referralCode}
                    </Label>
                    <FormAdapters.Input
                        {...form.referralCode}
                    />
                </FormPart>
            </ColumnsWrapper>
            <MiddleTitle>
                {T.pages.signUp.setPasswordTitle}
            </MiddleTitle>
            <MiddleText>
                {T.pages.signUp.setPasswordNote}
            </MiddleText>
            <ColumnsWrapper>
                <FormPart>
                    <Label>
                        {T.pages.signUp.password}
                    </Label>
                    <FormAdapters.Input
                        {...form.newPassword}
                        password
                    />
                </FormPart>
                <FormPart>
                    <Label>
                        {T.pages.signUp.confirmPassword}
                    </Label>
                    <FormAdapters.Input
                        {...form.confirmNewPassword}
                        password
                    />
                </FormPart>
            </ColumnsWrapper>
            <Footer>
                <TermsWrapper>
                    <FormComponents.Checkbox
                        checked={form.acceptTerms.value}
                        onChange={() => form.acceptTerms.onChangeValue(!form.acceptTerms.value)}
                    />
                    <TermsText>
                        {T.pages.signUp.agree}&nbsp;
                        <TermLink onClick={() => window.open('/terms_of_use', '_blank')?.focus()}>
                            {T.pages.signUp.termsOfUse}
                        </TermLink>
                    </TermsText>
                    <ErrorContainer ref={errorsParent}>
                        {form.acceptTerms.errorMessage && (
                            <AcceptTerms>
                                {form.acceptTerms.errorMessage}
                            </AcceptTerms>
                        )}
                    </ErrorContainer>
                </TermsWrapper>
                <ButtonWrapper>
                    <Button.Button
                        height={56}
                        fontSize={16}
                        backgroundColor={colors.orange}
                        textColor={colors.white}
                        onClick={submit}
                        content={T.pages.signUp.signUp}
                        isLoading={isLoading}
                        disabled={isLoading}
                    />
                </ButtonWrapper>
            </Footer>
            {termsModal && (
                <TermsModal onClose={() => setTermsModal(false)}/>
            )}
        </>
    )
}

const AcceptTerms = styled.div`
    width: 300px;
    display: block;
    word-break: break-word;
    color: ${colors.red};
    font-size: 14px;
`

const ErrorContainer = styled.div`
    position: absolute;
    bottom: -10px;
    left: 0;
    transform: translateY(100%);
`

const Header = styled.div`
    margin-bottom: 40px;
`

const FormPart = styled.div`
    margin-bottom: 36px;
    width: 50%;
    padding-right: 20px;
    @media only screen and (max-width: 770px) {
      width: 100%;
      padding-right: 0px;
    } 
`

const LeftColumn = styled.div`
    width: 50%;
    padding-right: 20px;
`

const RightColumn = styled.div`
    width: 50%;
    padding-left: 20px;
`

const Label = styled.div`
    margin-bottom: 15px;
    font-size: 16px;
`

const PhoneWrapper = styled.div`
    display: flex;
`

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    flex-flow: row wrap;
`

const ButtonWrapper = styled.div`
    width: 320px;
`

const TermsWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    @media only screen and (max-width: 770px) {
      padding-bottom: 20px;
    }
`

const MiddleTitle = styled.div`
    font-size: 20px;
    margin-bottom: 12px;
`

const MiddleText = styled.div`
    font-size: 18px;
    margin-bottom: 24px;
    color: ${colors.typography.blue};
`

const ColumnsWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
`

const TermLink = styled.div`
    cursor: pointer;
    color: ${colors.orange};
    text-decoration: underline;
`

const TermsText = styled.span`
    display: flex;
`

