import { T, call, always, cond, is, range } from 'ramda'
import { KeyValuePair } from '../types'

const isDefined = (subject: any) => typeof subject !== 'undefined' && subject !== null
const ifDefined = <T = any>(subject: any, then: (subject: T) => void) => isDefined(subject) && then(subject)
const hasElements = (subject: any) => Array.isArray(subject) && subject.length > 0

const clearObject = <T = KeyValuePair>(value: T): T => Object
    .keys(value)
    .filter(key => Boolean(value[key]) || typeof value[key] === 'boolean')
    .reduce((acc, curr) => ({
        ...acc,
        [curr]: value[curr]
    }), {} as T)

export {
    T,
    call,
    isDefined,
    ifDefined,
    hasElements,
    always,
    cond,
    is,
    range,
    clearObject
}
