import React from 'react'
import styled from 'styled-components'
import { Dialog } from 'app/Components'
import { useTranslations } from 'lib/hooks'
import { colors } from 'lib/styles'
import { constants } from 'lib/utils'

type TermsModalProps = {
    onClose: VoidFunction
}

export const TermsModal: React.FunctionComponent<TermsModalProps> = ({ onClose }) => {
    const T = useTranslations()

    return (
        <Dialog
            title={T.components.termsModal.title}
            onClose={onClose}
            dialogStyles={{
                maxWidth: '800px',
                maxHeight: '80%'
            }}
            actions={[
                {
                    backgroundColor: colors.orange,
                    color: colors.white,
                    onClick: () => onClose(),
                    name: T.common.close
                }
            ]}
        >
            <Label>
                {T.components.termsModal.title}
            </Label>
            <Text>
                {T.components.termsModal.termsText}
            </Text>
            <Label>
                {T.components.termsModal.policy}
            </Label>
            <Text>
                {T.components.termsModal.readOur}
                <Link
                    target="_blank"
                    href={constants.CANCELLATION_POLICY_LINK}
                >
                    &nbsp;{T.components.termsModal.policy}
                </Link>
            </Text>
            <Label>
                {T.components.termsModal.privacy}
            </Label>
            <Text>
                {T.components.termsModal.privacyText}
            </Text>
            <Text>
                {T.components.termsModal.privacyFooter}
            </Text>
            <Label>
                {T.components.termsModal.propertyRights}
            </Label>
            <Text>
                {T.components.termsModal.propertyRightsText}
            </Text>
        </Dialog>
    )
}

const Label = styled.div`
    margin-bottom: 10px;
    font-weight: 500;
`

const Text = styled.div`
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 26px;
`

const Link = styled.a`
    color: ${colors.linkColor};
    text-decoration: none;
    text-transform: lowercase;
`
