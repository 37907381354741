import React from 'react'
import { R } from 'lib/utils'

export const useButtons = () => {
    const buttonRipple = (event: React.MouseEvent, onClick?: VoidFunction) => {
        const button = event.currentTarget
        const circle = document.createElement('span')
        const diameter = Math.max(button.clientWidth, button.clientHeight)
        const radius = diameter / 2

        circle.style.width = circle.style.height = `${diameter}px`
        circle.style.top = `${event.clientY - button.getBoundingClientRect().top - radius}px`
        circle.style.left = `${event.clientX - button.getBoundingClientRect().left - radius}px`
        circle.classList.add('ripple')

        const ripple = button.getElementsByClassName('ripple').item(0)

        R.ifDefined<Element>(ripple, ripple => ripple.remove())

        button.appendChild(circle)

        R.ifDefined(onClick, R.call)
    }

    return {
        buttonRipple
    }
}
