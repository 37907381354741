import React from 'react'
import { CONFIG } from 'lib/config'
import { mobileCheck } from 'app/Pages/utils'

export const AvaBetaRelease2023: React.FunctionComponent = () => {
    const isJapanese = () => {
        return CONFIG.LANGUAGE === 'ja_JP'
    }
    const width = mobileCheck() ? 340 : 560
    const height = mobileCheck() ? 192 : 315

    return (
        <span>
            {isJapanese() && (<iframe width={width} height={height}
                src="https://www.youtube.com/embed/3l4BbHofHgg?si=ShpJfuZ5eh3v95wK"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen/>
            )}
            {!isJapanese() && (<iframe width={width} height={height}
                src="https://www.youtube.com/embed/jIRtXAcO8QI?si=U8JS8BKAaGKRJZbG"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen />
            )}
        </span>
    )
}
