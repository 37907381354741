import React, { useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { colors } from 'lib/styles'
import { AvaSession } from './screens/AvaSession'
import { CONVERSATION_TYPE, MICRO_TOPIC_TITLES } from './constants'
import { Nullable } from 'lib/types'
import { AvaMicroTopicGroup } from './types'

export interface AvaRolePlaysProps {
    group?: AvaMicroTopicGroup,
    expandList?: boolean,
    expandedGroupsHierarchy?: Array<number>,
    outerSetRolePlay?: ((rolePlay: string)=>void),
    outerSetRolePlayTitle?: ((rolePlayTitle: string)=>void)
}

export const AvaRolePlays: React.FunctionComponent<AvaRolePlaysProps> = ({
    group,
    outerSetRolePlay,
    outerSetRolePlayTitle,
    expandList = false,
    expandedGroupsHierarchy= []
}) => {

    const [rolePlay, setRolePlay] = useState<Nullable<string>>()
    const [isExpanded, setIsExpanded] = useState<boolean>(expandList)

    const isGroupExpanded = (index: number) => {
        return expandedGroupsHierarchy.length > 0 && expandedGroupsHierarchy[0] === index
    }

    return (
        <Container>
            {!rolePlay && group && (<RolePlays>
                <SubTitle onClick={() => setIsExpanded(!isExpanded)}><ExpandGroupIcon>{isExpanded ? '-' : '+'}</ExpandGroupIcon>{group.name}</SubTitle>
                {isExpanded && group.topics && (<SubList>
                    {group.topics.map((topic, index) => {
                        return (
                            <RolePlay onClick={() => {
                                console.log(`outerSetRolePlay = ${outerSetRolePlay}`)
                                if (outerSetRolePlay) {
                                    outerSetRolePlay(topic.alias)
                                }
                                if (outerSetRolePlayTitle) {
                                    outerSetRolePlayTitle(topic.name)
                                }
                            }} key={index}>{topic.name}</RolePlay>
                        )
                    })}
                </SubList>)}
                {isExpanded && group.groups && (<SubList>
                    {group.groups.map((subGroup, index) => {
                        return (
                            <AvaRolePlays
                                expandList={isGroupExpanded(index)}
                                expandedGroupsHierarchy={isGroupExpanded(index) ? expandedGroupsHierarchy.slice(1) : []}
                                group={subGroup}
                                outerSetRolePlay={outerSetRolePlay}
                                outerSetRolePlayTitle={outerSetRolePlayTitle}
                                key={index} />
                        )
                    })}
                </SubList>)}
            </RolePlays>)}
        </Container>
    )
}

const RolePlays = styled.div`
`

const SubList = styled.div`
    padding-left: 20px;
`

const ExpandGroupIcon = styled.div`
    display: inline-block;
    width: 20px;
    padding-right: 5px;
`

const RolePlay = styled.div`
    max-width: 600px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: underline;
    padding: 7px 0;
    color: ${colors.linkColor};
`

const Container = styled.div`
    padding: 10px 15px 10px 0;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 18px;
    box-shadow: '0px 0px 30px #00000029';
    position: relative;
    background-color: ${colors.white};
`

const Title = styled.div`
    padding: 15px 10px;
    font-size: 24px;
`

const SubTitle = styled.div`
    padding: 5px 0 10px 0;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
`

const BackLink = styled.div`
    width: 300px;
    font-size: 16px;
    cursor: pointer;
    padding: 0px 10px 15px 10px;
`
