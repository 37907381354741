import React from 'react'
import { Record, Recording } from 'lib/models'
import {
    Content,
    NoteContainer,
    DescriptionContainer,
    SubTitle,
    VideoPlayer
} from 'app/Components'

interface RecordingsContainerProps {
    record: Record
}

export const RecordingsContainer: React.FunctionComponent<RecordingsContainerProps> = ({ record }) => {
    const newData = record.data.map(dt => {
        const recording: Recording = {
            files : {
                mp4: dt.url,
                m3u8: ''
            },
            duration: dt.duration,
            startTime: 0,
            endTime: 0
        }

        return {
            ...recording
        }
    })
    const hasRecordings = Array.isArray(newData)
    const hasOnlyOneRecording = hasRecordings && newData.length === 1

    return (
        <Content>
            <SubTitle>
                {record.recordedAt}
            </SubTitle>
            <DescriptionContainer>
                <NoteContainer columnDirection>
                    {newData.map((dt, i) => (
                        <VideoPlayer
                            key={i}
                            hasOnlyOneRecording={hasOnlyOneRecording}
                            recording={dt}
                            recordingNumber={i + 1}
                        />
                    ))}
                </NoteContainer>
            </DescriptionContainer>
        </Content>
    )
}
