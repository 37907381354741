import styled from 'styled-components'

interface UserMsgContainerProps {
    alignRight?: boolean
}

interface TextProps {
    whiteColor?: boolean
}

export const UserMsgContainer = styled.div<UserMsgContainerProps>`
	width: 100%;
	display: flex;
	justify-content: ${({ alignRight }) =>
        alignRight ? 'flex-end' : 'flex-start'};
`

export const MsgContianer = styled.div<UserMsgContainerProps>`
	padding: 0.5rem 0.5rem;
	width: 60%;
	margin: 1rem 0 2rem 0;
	border-radius: 15px;
	cursor: pointer;
	position: relative;
	background-color: ${({ theme, alignRight }) =>
        alignRight ? theme.colors.blue[200] : theme.colors.white};
`

export const UserName = styled.h6<TextProps>`
	margin: 0;
	${({ theme, whiteColor }) => whiteColor ? `color: ${theme.colors.white}` : ''}
`

export const CircleAvatarName = styled.div`
	margin: 1rem 0.5rem 0 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	width: 30px;
	height: 30px;
	color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.grey[400]};
`

export const MessageText = styled.p<TextProps>`
	margin: 0.3rem 0;
	font-size: .8em;
	${({ whiteColor, theme }) => whiteColor ? `color: ${theme.colors.white}` : ''}
`

export const EventTime = styled.span`
	position: absolute;
	margin-top: 0.7rem;
	font-size: 0.7em;
	color: ${({ theme }) => theme.colors.grey[400]};
`
