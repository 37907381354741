import React from 'react'
import styled from 'styled-components'
import { constants } from 'common'
import { colors } from 'lib/styles'
import { SvgIcon } from 'lib/types'

type ContainerStyle = {
    width?: number,
    height?: number,
    tabletSize?: number,
    hoverColor?: boolean
}

export const Icon: React.FunctionComponent<SvgIcon> = ({
    fill,
    width = 24,
    height = 24,
    children,
    viewBox,
    pointer,
    onClick,
    hoverColor,
    tabletSize
}) => (
    <Container
        role="svg"
        width={width}
        height={height}
        onClick={onClick}
        hoverColor={hoverColor}
        tabletSize={tabletSize}
        fill={fill || colors.typography.blue}
        viewBox={viewBox || '0 0 80 80'}
        style={{
            cursor: (pointer || onClick) ? 'pointer' : undefined
        }}
    >
        {children}
    </Container>
)

const Container = styled.svg<ContainerStyle>`
  transition: fill 0.25s;
  @media (max-width: ${constants.Breakpoints.Tablet}px) {
    width: ${props => props.tabletSize || 18}px;
    height: ${props => props.tabletSize || 18}px;
  }
  :hover {
    fill: ${props => props.hoverColor && colors.primary};
  }
`
