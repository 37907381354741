import { constants } from 'common'
import { format, parseISO, isValid, differenceInSeconds, getUnixTime, differenceInMinutes, addMinutes } from 'date-fns'
import { DateFnsDate, TimezoneName } from 'lib/types'
import { utcToZonedTime } from 'date-fns-tz'

export const formatDate = (date: DateFnsDate, dateFormat?: string) => {
    if (!date) {
        return ''
    }

    const parsedDate = typeof date === 'number'
        ? new Date(date)
        : typeof date === 'string'
            ? parseISO(date)
            : date

    if (!isValid(parsedDate)) {
        return date
    }

    return format(parsedDate, dateFormat || 'dd.MM.yyyy HH:mm:ss')
}

export const toRecordingTime = (startTime: number, endTime: number) => {
    const ellapsedSeconds = differenceInSeconds(endTime, startTime)

    return secondsToTime(ellapsedSeconds)
}

export const secondsToTime = (secs: number, normalTime?: boolean) => {
    const hours = String(Math.floor(secs / 3600)).padStart(2)
    const minutes = String(parseInt(String(secs % (60 * 60) / 60), 10)).padStart(2)
    const seconds = String(parseInt(String(secs % 60), 10)).padStart(2)

    if (normalTime) {
        return `${parseInt(hours, 10) > 0 ? `${hours.padStart(2, '0')}:` : ''} ${parseInt(minutes, 10) > 0 ? `${minutes.padStart(2, '0')}:` : ''} ${seconds.padStart(2, '0')}`
    }

    return `${parseInt(hours, 10) > 0 ? `${hours} h ` : ' '}${parseInt(minutes, 10) > 0 ? `${minutes} min ` : ''}${parseInt(seconds, 10) > 0 ? `${seconds} sec` : ''}`
}

export const formatAMPM = (date: Date) => format(date, 'hh:mm aa')

export const formatToLocaleDateString = (date: Date | string) => new Date(date).toLocaleDateString(undefined, constants.optionsDate)

export const getDateWithTimeZoneOffset = (date: Date, timeZone: string) => {
    const zonedTime = utcToZonedTime(date, timeZone)
    const timeZoneOffset = differenceInMinutes(zonedTime, date)

    return addMinutes(date, -timeZoneOffset)
}

export const getStartOfDayInDefaultTimezone = (date: Date) => {
    const dateWithTimeZoneOffset = getDateWithTimeZoneOffset(date, TimezoneName.AmericaTimezone)

    return getUnixTime(dateWithTimeZoneOffset)
}
