import { CONFIG } from 'lib/config'

export const getCookieByKey = (key: string) => {
    const targetCookie = document.cookie
        .split('; ')
        .find(row => row.startsWith(`${key}=`))

    if (!targetCookie) {
        return undefined
    }

    const [, value] = targetCookie.split('=')

    return value
}

export const shouldAttachAuthCookie = () => {
    if (!CONFIG.COOKIE_KEY) {
        return false
    }

    return Boolean(getCookieByKey(CONFIG.COOKIE_KEY))
}

export const getAuthCookie = () => getCookieByKey(CONFIG.COOKIE_KEY)
