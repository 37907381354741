import { TryFreeEnum } from 'lib/types'
import { useTryFree } from 'lib/hooks'
import { About, Book, Confirmation, SignUp } from '../screens'
import { BookingConfirmation } from '../screens/BookingConfirmation'
import { CONFIG } from 'lib/config'

export const useGeneratePage = () => {
    const { tryFreeState: { page } } = useTryFree()

    switch (page.page) {
        case TryFreeEnum.Confirm:
            return Confirmation
        case TryFreeEnum.ConfirmBooking:
            return BookingConfirmation
        case TryFreeEnum.Book:
            return Book
        case TryFreeEnum.SignUp:
            return SignUp
        default:
            if (CONFIG.CREATE_FREE_ACCOUNT) {
                return About
            } else {
                return SignUp
            }
    }
}
