import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const CloseCircle: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <g>
            <g fill="none" stroke={props.fill} strokeWidth="1.5" data-name="Ellipse 8" transform="translate(10 10)">
                <circle cx="30" cy="30" r="30" stroke="none"/>
                <circle cx="30" cy="30" r="29.25"/>
            </g>
        </g>
        <path d="M50.616 52.08a1.508 1.508 0 0 1-1.07-.443l-9.522-9.522-9.522 9.522a1.513 1.513 0 0 1-2.14-2.14l9.522-9.522-9.522-9.522a1.513 1.513 0 1 1 2.14-2.14l9.522 9.522 9.522-9.522a1.513 1.513 0 1 1 2.14 2.14l-9.522 9.522 9.522 9.522a1.513 1.513 0 0 1-1.07 2.583Z" data-name="Path 57"/>
    </Icon>
)
