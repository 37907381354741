import { useField } from '@codegateinc/react-form-builder-v2'
import { ApplyCouponFields } from './types'
import { useTranslations } from 'lib/hooks'

export const useApplyCouponForm = () => {
    const T = useTranslations()

    const code = useField<string>({
        key: ApplyCouponFields.CouponCode,
        initialValue: '',
        isRequired: true,
        validateOnBlur: false,
        placeholder: 'Promo Code',
        validationRules: [
            {
                errorMessage: T.validationErrors.empty,
                validate: Boolean
            }
        ]
    })

    return {
        code
    }
}