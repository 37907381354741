import { useEffect, useState } from 'react'
import { Note } from 'lib/models'
import { useGetAllRecordings, useGetAppointments } from './actions'

export const useNotes = () => {
    const [notes, setNotes] = useState<Array<Note>>([])
    const { fetch: getAllRecordings } = useGetAllRecordings()
    const { fetch: getAppointments } = useGetAppointments()
    const [hasError, setError] = useState<boolean>(false)
    const [hasEmptyNotes, setHasEmptyNotes] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const filterNote = (note: Note) => new Date(note.callTime) < new Date() &&
			(note.status === 'Created' || note.status === 'Finished') &&
			(note.note !== null || (note?.recordingInfo?.recordings?.length || 0) > 0)
    const pastNotes = notes.filter(filterNote)
    const fetchNotes = () => {
        setError(false)
        setIsLoading(true)

        getAppointments()
            .then(({ data }) => {
                const notes = data
                const appointmentIds = notes.reduce((acc, note) => [
                    ...acc,
                    note.appointmentId
                ], [])
                if (appointmentIds.length === 0) {
                    setHasEmptyNotes(true)

                    return []
                }

                return getAllRecordings({ appointmentIds })
                    .then(allRecordings => notes.map(note => {
                        const recordingInfo = allRecordings.find(recording => recording.appointmentId === note.appointmentId) || {}

                        return {
                            ...note,
                            recordingInfo
                        } as Note
                    }))
            })
            .then(setNotes)
            .catch(() => setError(true))
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        fetchNotes()
    }, [])

    return {
        notes: pastNotes,
        hasEmptyNotes,
        isLoading,
        hasError: hasError && !isLoading || pastNotes.length === 0 && !isLoading,
        canRenderNotes: !isLoading && !hasError && pastNotes.length > 0,
        onReload: fetchNotes,
        filterNote
    }
}
