export const colors = {
    primary: '#ED6C05',
    black: '#000000',
    white: '#FFFFFF',
    success: '#57A200',
    red: '#DC3545',
    blue: '#66AFD9',
    orange: '#E7AD6F',
    darkGreen: '#31A37C',
    transparent: 'transparent',
    typography: {
        primary: '#19273D',
        blue: '#425777',
        regularBrighter: '#D0D0D0',
        regular: '#CDCDCD',
        inputBorder: '#D9D9D9',
        placeholder: '#C2C2C2'
    },
    layout: {
        primary: '#ED6C05',
        secondary: '#fe753596',
        background: '#EDE4DD',
        backgroundDisabled: '#DBDBDB',
        foreground: '#FFFFFF',
        border: '#D7D7D7',
        shadow: '#D7D7D7',
        secondaryButton: '#161456',
        oppositeTheme: '#1B191B',
        buttonShadow: '#8F8F8F',
        modalItemBackground: '#E4E6E7'

    },
    gray: {
        light: '#F5F5F5',
        border: '#D1D1D9',
        typography: '#7F8C8D',
        inputBorder: '#D9D9D9',
        iconBackground: '#E4E6E7',
        background: '#E4E6E7',
        dxBorder: '#AAAAAA',
        normal: '#C2C2C2',
        dark: '#19273D'
    },
    icon: {
        lightGray: '#C2C2C2',
        gray: '#9A9A9A',
        disabledArrow: '#808C8D',
        activeArrow: '#3281B7'
    },
    linkColor: '#337ab7',
    table: {
        background: '#ECF0F1',
        cellBorder: '#818C8D',
        dataCellBorder: '#E0E2E4',
        tableSeparator: '#F5F5F5',
        tableHoverColor: '#97bad7',
        border: '#D1D1D1'
    }
}
