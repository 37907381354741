import { AreaCode, HttpMethod, OnSuccessResponse, Timezone } from '../types'
import { useFetch } from '../hooks'
import { CONFIG } from '../config'

export const useGetTimezones = (onSuccess: OnSuccessResponse<Array<Timezone>>) =>
    useFetch<Array<Timezone>>({
        url: `${CONFIG.SCHEDULER_API}/timezone/public`,
        method: HttpMethod.GET
    }, {
        onSuccess,
        isLoadingAtStart: true
    })

export const useGetAreaCodes = (onSuccess: OnSuccessResponse<Array<AreaCode>>) =>
    useFetch<Array<AreaCode>>({
        url: `${CONFIG.SCHEDULER_API}/country-iso`,
        method: HttpMethod.GET
    }, {
        onSuccess,
        isLoadingAtStart: true
    })
