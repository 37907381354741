import React from 'react'
import { useTheme } from 'styled-components'
import {
    IconContainer,
    RecordingTitle,
    RecordingTitleContainer,
    TimeDescription,
    VideoPlayerModel
} from 'app/Components'
import { SharedVideoTypes } from 'app/Components/types'
import { Play } from 'app/UI'
import { useTranslations } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'
import { CONFIG } from 'lib/config'

interface VideoPlayerProps {
    hasOnlyOneRecording: boolean,
    recordingNumber?: number
}

export const VideoPlayer: React.FunctionComponent<VideoPlayerProps & SharedVideoTypes> = ({
    hasOnlyOneRecording,
    recordingNumber,
    recording,
    chatHistory,
    participants
}) => {
    const theme = useTheme()
    const [showModel, setShowModel] = React.useState(false)
    const T = useTranslations()
    const getFormatedTime = () => {
        const recordNoteTime = dateHelpers.toRecordingTime(
            recording.startTime,
            recording.endTime
        )
        const recordBlackBoardTime = dateHelpers.secondsToTime(recording.duration)

        return CONFIG.MODULE !== 'BLACKBOARD_RECORDINGS' ? recordNoteTime : `${recordBlackBoardTime}`
    }

    return (
        <>
            {showModel && <VideoPlayerModel
                showModel={showModel}
                chatHistory={chatHistory}
                recording={recording}
                participants={participants}
                onClick={() => setShowModel(false)}
            />}
            <RecordingTitleContainer onClick={() => setShowModel(true)}>
                <IconContainer
                    shadow
                    switchMouseToCursor
                    color={theme.colors.white}
                >
                    <Play
                        width={25}
                        height={25}
                        fill={theme.colors.black[100]}
                    />
                </IconContainer>
                <div>
                    <RecordingTitle>
                        {hasOnlyOneRecording
                            ? T.components.vcRecordings.recording
                            : `${T.components.vcRecordings.recording}${
                                recordingNumber || ''}`}
                    </RecordingTitle>
                    <TimeDescription data-testid="time">
                        {T.pages.timeLabel}:{getFormatedTime()}
                    </TimeDescription>
                </div>
            </RecordingTitleContainer>
        </>
    )
}
