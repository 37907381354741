import React from 'react'
import { SvgIcon } from 'lib/types'
import { Svg } from 'app/UI/Icons'

export const NoteIcon: React.FunctionComponent<SvgIcon> = ({
    width,
    height,
    fill
}) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24.231 33.043"
        svgWdith={width}
        svgHeight={height}
    >
        <g
            data-name="Group 26"
            fill={fill}
        >
            <path
                data-name="Path 6"
                d="M19.826 33.043H4.406A4.411 4.411 0 010 28.637V6.609a4.411 4.411 0 014.406-4.406h2.2v2.2h-2.2a2.205 2.205 0 00-2.2 2.2v22.029a2.205 2.205 0 002.2 2.2h15.42a2.205 2.205 0 002.2-2.2V6.609a2.205 2.205 0 00-2.2-2.2h-2.2V2.203h2.2a4.411 4.411 0 014.406 4.406v22.029a4.411 4.411 0 01-4.406 4.405z"
            />
            <path
                data-name="Path 7"
                d="M15.824 6.609H8.407a2.9 2.9 0 01-2.9-2.9V2.9a2.9 2.9 0 012.9-2.9h7.417a2.9 2.9 0 012.9 2.9v.808a2.9 2.9 0 01-2.9 2.901zM8.407 2.2a.7.7 0 00-.7.7v.808a.7.7 0 00.7.7h7.417a.7.7 0 00.7-.7V2.9a.7.7 0 00-.7-.7z"
            />
        </g>
    </Svg>
)
