import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { colors, stylesHelpers } from 'lib/styles'
import { ArrowDown } from 'app/UI'

type CustomDatePickerProps = {
    value?: string,
    onClick?: VoidFunction
}

export const CustomInputDatePicker = forwardRef<HTMLButtonElement, CustomDatePickerProps>(({ value, onClick }, ref) => (
    <ButtonWrapper
        onClick={onClick}
        ref={ref}
    >
        {value}
        <IconContainer>
            <ArrowDown
                width={20}
                height={20}
                fill={colors.white}
            />
        </IconContainer>
    </ButtonWrapper>
))

const ButtonWrapper = styled.button`
    display: flex;
    position: relative;
    font-size: 18px;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid ${stylesHelpers.hexToRGBA(colors.gray.inputBorder, 0.8)};
    padding: 16px;
    width: 100%;
    height: 66px;
    color: ${`${colors.white}`};
    background: ${`${colors.blue}`};
`

const IconContainer = styled.div`
    display: flex;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);`
