/* eslint-disable camelcase */
import { en_US } from './en_US'
import { ja_JP } from './ja_JP'
import { ru_RU } from './ru_RU'
import { sp_SP } from './sp_SP'

export enum Languages {
    en_GB = 'en_US',
    en_US = 'en_US',
    ja_JP = 'ja_JP',
    ru_RU = 'ru_RU',
    sp_SP = 'sp_SP'
}

export const languages = {
    [Languages.en_GB]: en_US,
    [Languages.en_US]: en_US,
    [Languages.ja_JP]: ja_JP,
    [Languages.ru_RU]: ru_RU,
    [Languages.sp_SP]: sp_SP
}
