export enum Styles {
    FullWidth = 'fullWidth',
}

export interface ButtonStyleProps {
    state?: 'info' | 'danger',
    noBorder?: boolean,
    noPadding?: boolean,
    noMargin?: boolean,
    noFlex?: boolean
}
