import React from 'react'
import { SvgIcon } from 'lib/types'
import { Svg } from 'app/UI/Icons'

export const Mic: React.FunctionComponent<SvgIcon> = ({
    width,
    height,
    fill
}) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 23.292 33.645"
        svgWdith={width}
        svgHeight={height}
    >
        <g fill={fill}>
            <path
                data-name="Path 3"
                d="M16.822 33.646H6.47a1.294 1.294 0 010-2.588h3.882v-3.953A11.663 11.663 0 010 15.528V12.94a1.294 1.294 0 012.588 0v2.588a9.058 9.058 0 0018.116 0V12.94a1.294 1.294 0 012.588 0v2.588A11.664 11.664 0 0112.94 27.103v3.953h3.882a1.294 1.294 0 010 2.588z"
            />
            <path
                data-name="Path 4"
                d="M11.646 22a6.374 6.374 0 01-4.573-1.949 6.543 6.543 0 01-1.9-4.6V6.412A6.452 6.452 0 0111.62 0h.029a6.4 6.4 0 016.467 6.47v8.977A6.518 6.518 0 0111.646 22zM11.62 2.59a3.856 3.856 0 00-3.856 3.837v9.023a3.976 3.976 0 003.882 3.963 3.928 3.928 0 003.882-3.963V6.47a3.816 3.816 0 00-3.882-3.882z"
            />
        </g>
    </Svg>
)
