import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Tick: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <defs>
            <clipPath id="a">
                <path d="M0 0h80v80H0z"/>
            </clipPath>
        </defs>
        <g clipPath="url(#a)" data-name="iPhone 12, 12 Pro – 1">
            <path d="M29.714 65.429a4.285 4.285 0 0 1-3.186-1.419L11.099 46.867a4.286 4.286 0 0 1 6.371-5.734l12.132 13.479 32.83-39.078a4.286 4.286 0 1 1 6.563 5.513l-36 42.857a4.285 4.285 0 0 1-3.208 1.529Z" data-name="Path 442"/>
        </g>
    </Icon>
)
