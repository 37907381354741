import React, { useEffect, useState } from 'react'
import {
    Description,
    MicRecord,
    NoteContainer,
    DescriptionContainer,
    RecordingPlayer,
    DragAndDropFileUpload, IconContainer, MicButtonContainer, Button
} from 'app/Components'
import { RecordState } from 'app/Components/types'
import { Reply } from 'lib/models'
import { useTranslations } from 'lib/hooks'
import { useVoiceRecorder } from './useVoiceRecorder'
import { Border } from './style'
import * as UI from 'app/UI'
import { AvaSession } from '../../Pages/Ava/screens/AvaSession'
import { colors } from 'lib/styles'
import { CONVERSATION_TYPE } from '../../Pages/Ava/constants'

interface RecordingsProps {
    records: Array<Reply>,
    recordingState?: boolean,
    appointmentId: string,
    resetRecordingState: VoidFunction
}

export const Recordings: React.FunctionComponent<RecordingsProps> = ({
    appointmentId,
    records,
    resetRecordingState
}) => {
    const T = useTranslations()
    const {
        recordState,
        audioUrl,
        color,
        fill,
        error,
        time,
        replayChallengeAnswerRecordings,
        uploadLoading,
        handelUpload,
        handleRecording,
        setStartRecording,
        resetErrorState,
        handleUploadFile
    } = useVoiceRecorder({ appointmentId, records, resetRecordingState })
    const timer = time !== '' ? `${time}` : `00:00`

    const [showAva, setShowAva] = useState<boolean>(false)

    return (
        <DescriptionContainer>
            {
                recordState === RecordState.PLAY
														&& audioUrl !== ''
														&& <audio preload="auto" autoPlay src={audioUrl} onPause={handleRecording} />
            }
            <NoteContainer
                centerIItems={!records}
                columnDirection
            >
                {Array.isArray(replayChallengeAnswerRecordings) ? (
                    <>
                        {(replayChallengeAnswerRecordings as Array<Reply>).map((record, index) => (
                            <RecordingPlayer
                                key={index}
                                recordNumber={index + 1}
                                recordUrl={record.url}
                                timeLabel={record.createdAt}
                            />
                        ))}
                        {replayChallengeAnswerRecordings.length > 0 && <Border />}
                    </>
                ) : (
                    <Description>
                        {T.components.noRecordingAvailable}
                    </Description>
                )}

                <MicButtonContainer>
                    {!showAva && (<Button.Button
                        height={46}
                        fontSize={14}
                        backgroundColor={colors.orange}
                        textColor={colors.white}
                        onClick={() => setShowAva(true)}
                        content={T.pages.avaSession.replyChallenge}
                        isLoading={false}
                        disabled={false}
                    />)}

                    {showAva && (<AvaSession type={CONVERSATION_TYPE.REPLAY_CHALLENGE} appointmentId={parseInt(appointmentId, 10)} />)}
                </MicButtonContainer>

                {/* <DragAndDropFileUpload handleDropProps={handleUploadFile}>*/}
                {/*    <MicRecord*/}
                {/*        startRecording={recordState}*/}
                {/*        color={color}*/}
                {/*        fill={fill}*/}
                {/*        uploadLoading={uploadLoading}*/}
                {/*        handelUpload={handelUpload}*/}
                {/*        handleRecording={handleRecording}*/}
                {/*        setStartRecording={setStartRecording}*/}
                {/*        resetErrorState={resetErrorState}*/}
                {/*        handleUploadFile={handleUploadFile}*/}
                {/*        error={error}*/}
                {/*        time={timer}*/}
                {/*    />*/}
                {/* </DragAndDropFileUpload>*/}
            </NoteContainer>
        </DescriptionContainer>
    )
}
