import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Fam: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M1.538,1.439V28.79H58.461V1.439H1.538M0,0H60V30.229H0Z" transform="translate(10 25)"/>
        <path d="M1.9-29.988v16.453H5.516v-6.8h6.89v-2.811H5.516v-3.8h7.95v-3.042Zm17.12,10.093,2.143-6.038h.046l2.074,6.038Zm.346-10.093L13.142-13.535h3.641l1.29-3.664h6.153l1.244,3.664h3.756L23.074-29.988Zm11.866,0v16.453h3.387V-25.08h.046L38.7-13.535h2.788l4.033-11.66h.046v11.66H48.95V-29.988H43.857L40.216-18.673H40.17L36.322-29.988Z" transform="translate(14.576 62.45)"/>
    </Icon>
)
