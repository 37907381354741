import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const RequestAvailability: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <defs>
            <clipPath id="b">
                <path fill={props.fill} d="M0 0h60v51.95H0z" data-name="Rectangle 2001"/>
            </clipPath>
            <clipPath id="a">
                <path d="M0 0h80v80H0z"/>
            </clipPath>
        </defs>
        <g clipPath="url(#a)" data-name="iPhone 12, 12 Pro – 1">
            <g data-name="Group 1049">
                <g data-name="Group 1047">
                    <g clipPath="url(#b)" data-name="Group 1046" transform="translate(10 13.747)">
                        <path fill={props.fill} d="M53.499 18.293a3.927 3.927 0 0 0-.814.085 24.24 24.24 0 0 0-10.133-12.33 2.488 2.488 0 0 0-1.085-3.247 24.879 24.879 0 0 0-22.939 0 2.488 2.488 0 0 0-1.082 3.247 24.232 24.232 0 0 0-10.133 12.33 3.931 3.931 0 0 0-4.746 3.845v8.932a3.93 3.93 0 0 0 5.961 3.365A22.847 22.847 0 0 1 18.09 7.189l.1.172a2.467 2.467 0 0 0 3.27.973 18.7 18.7 0 0 1 17.084 0 2.467 2.467 0 0 0 3.27-.973l.1-.172a22.849 22.849 0 0 1-7.431 41.907 2.034 2.034 0 0 0-1.861-1.213h-5.24a2.034 2.034 0 1 0 0 4.067h5.237a2.034 2.034 0 0 0 1.974-1.543 24.191 24.191 0 0 0 18.092-15.406 3.932 3.932 0 0 0 4.744-3.845v-8.933a3.93 3.93 0 0 0-3.93-3.93" data-name="Path 416"/>
                        <path fill={props.fill} d="M13.302 48.182a.981.981 0 0 1-.512-1.816 9.965 9.965 0 0 0 4.313-6.085 18.677 18.677 0 1 1 6.1 3.872 16.016 16.016 0 0 1-9.824 4.026h-.074m16.7-38.922a17.424 17.424 0 0 0-11.729 30.321.654.654 0 0 1 .2.632 11.614 11.614 0 0 1-3.94 6.526 13.9 13.9 0 0 0 8.045-3.807.654.654 0 0 1 .723-.15 17.432 17.432 0 1 0 6.7-33.522" data-name="Path 417"/>
                        <path d="M13.304 48.636a1.434 1.434 0 0 1-.751-2.655 9.477 9.477 0 0 0 4.043-5.56 19.131 19.131 0 1 1 6.71 4.257 16.615 16.615 0 0 1-9.9 3.953 1.089 1.089 0 0 1-.102.005Zm16.7-40.23A18.278 18.278 0 0 0 17.42 39.952l.2.185-.069.26a10.446 10.446 0 0 1-4.513 6.355.527.527 0 0 0 .314.976 15.585 15.585 0 0 0 9.543-3.9l.209-.2.271.106a18.287 18.287 0 1 0 6.623-35.328Zm-17.062 39.02 1.3-1.042a11.2 11.2 0 0 0 3.781-6.275.2.2 0 0 0-.06-.194 17.822 17.822 0 1 1 5.155 3.284.2.2 0 0 0-.222.046 14.336 14.336 0 0 1-8.307 3.941ZM29.998 9.713a16.971 16.971 0 0 0-11.424 29.532 1.109 1.109 0 0 1 .333 1.07 12.531 12.531 0 0 1-2.922 5.684 12.68 12.68 0 0 0 6.259-3.382 1.113 1.113 0 0 1 .8-.339 1.1 1.1 0 0 1 .426.085A16.979 16.979 0 1 0 30 9.713Z" data-name="Path 417 - Outline"/>
                        <path fill={props.fill} d="M58.126 22.088v9.2a1.877 1.877 0 0 0 1.877-1.877v-5.447a1.877 1.877 0 0 0-1.877-1.877" data-name="Path 418"/>
                        <path fill={props.fill} d="M0 23.965v5.447a1.877 1.877 0 0 0 1.877 1.877v-9.2A1.877 1.877 0 0 0 0 23.965" data-name="Path 419"/>
                    </g>
                </g>
                <text fill={props.fill} fontFamily="Nunito-Medium, Nunito" fontSize="14" fontWeight="500" transform="translate(35.067 43.34)"><tspan x="0" y="0">R</tspan></text>
            </g>
        </g>
    </Icon>
)
