import React from 'react'
import { useTranslations } from 'lib/hooks'
import { RecordingInfo, VCEventType } from 'lib/models'
import { Description, DescriptionContainer, NoteContainer, VideoPlayer } from 'app/Components'

interface VCRecordingsProps {
    recordingInfo: RecordingInfo
}

export const VCRecordings: React.FunctionComponent<VCRecordingsProps> = ({ recordingInfo }) => {
    const chatHistory = recordingInfo.metadata?.filter(msg => msg.type === VCEventType.TextMessage || msg.type === VCEventType.GoogleMeetTranscript)
    const participants = recordingInfo.participants
    const T = useTranslations()
    const hasRecordings = Array.isArray(recordingInfo.recordings)
    const hasOnlyOneRecording = hasRecordings && recordingInfo.recordings.length === 1

    return (
        <DescriptionContainer>
            <NoteContainer columnDirection>
                {!hasRecordings && (
                    <Description>
                        {T.components.vcRecordings.noRecordings}
                    </Description>
                )}
                {
                    hasRecordings && (
                        <>
                            <Description bottomPadding>
                                {T.pages.noteRemeber}
                            </Description>
                            {
                                recordingInfo.recordings.map((recording, index) => (
                                    <VideoPlayer
                                        key={index}
                                        hasOnlyOneRecording={hasOnlyOneRecording}
                                        participants={participants}
                                        chatHistory={chatHistory}
                                        recording={recording}
                                        recordingNumber={index + 1}
                                    />
                                ))
                            }
                        </>
                    )
                }
            </NoteContainer>
        </DescriptionContainer>
    )
}
