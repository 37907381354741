import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const EE: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <defs>
            <clipPath>
                <rect width="48.007" height="58.096" fill="none"/>
            </clipPath>
            <clipPath>
                <rect width="80" height="80"/>
            </clipPath>
        </defs>
        <g>
            <g transform="translate(18.445 9.587)">
                <g transform="translate(-2.445 1.413)">
                    <g transform="translate(0 0)">
                        <path d="M45.792,6.138H35.405A1.034,1.034,0,0,1,34.371,5.1V1.034A1.034,1.034,0,0,1,35.405,0H45.792a1.035,1.035,0,0,1,1.034,1.034V5.1a1.034,1.034,0,0,1-1.034,1.034M36.44,4.069h8.318v-2H36.44Z" transform="translate(-16.596 0)"/>
                        <path d="M24,64.8a24,24,0,0,1-3.067-47.807V15.02h2.069v2.9a1.034,1.034,0,0,1-.944,1.03A21.933,21.933,0,1,0,45.939,40.8,22.066,22.066,0,0,0,25.95,18.953a1.034,1.034,0,0,1-.944-1.03V13.885h2.069v3.109A24,24,0,0,1,24,64.8" transform="translate(0 -6.704)"/>
                        <rect width="4.111" height="2.068" transform="translate(38.14 15.957) rotate(-49.716)"/>
                        <rect width="2.068" height="5.67" transform="matrix(0.647, -0.763, 0.763, 0.647, 38.756, 12.444)"/>
                        <path d="M3.234-12.248h8.127V-13.72H4.871v-4.509h6.154v-1.454H4.871v-4.209h6.491v-1.473H3.234Zm10.472,0h8.127V-13.72H15.343v-4.509H21.5v-1.454H15.343v-4.209h6.491v-1.473H13.707Z" transform="translate(11.884 52.585)"/>
                    </g>
                </g>
            </g>
        </g>
    </Icon>
)
