export const isValidEmail = (email: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
export const isValidPassword = (query: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z~!@#$%^&*()\-_+<>,.";:|?/[\]{}\d]{8,}$/.test(query)
export const allowOnlyNumbers = (query: string) => query.replace(/\D/g, '')
export const allowNumbersWithComma = (query: string) => {
    const parsedQuery = query.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')
    const dotIndex = parsedQuery.indexOf('.')

    if (dotIndex !== -1) {
        return parsedQuery.slice(0, dotIndex + 3)
    }

    return parsedQuery
}
