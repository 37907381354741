import React from 'react'
import styled from 'styled-components'
import { R } from 'lib/utils'
import { colors, constants, stylesHelpers } from 'lib/styles'
import { useButtons } from './hook'

type IconButtonProps = {
    color?: string,
    backgroundColor?: string,
    icon: React.ReactNode,
    onClick?: VoidFunction,
    withMargin?: boolean,
    withoutAnimation?: boolean
}

type ButtonStyles = {
    color?: string,
    backgroundColor?: string,
    withMargin?: boolean
}

export const IconButton: React.FunctionComponent<IconButtonProps> = ({
    color,
    backgroundColor,
    icon,
    onClick,
    withMargin,
    withoutAnimation
}) => {
    const { buttonRipple } = useButtons()

    return (
        <IconButtonContent
            color={color}
            backgroundColor={backgroundColor}
            withMargin={withMargin}
            onClick={event => withoutAnimation ? R.ifDefined(onClick, R.call) : buttonRipple(event, onClick)}
        >
            {icon}
        </IconButtonContent>
    )
}

const IconButtonContent = styled.div<ButtonStyles>`
    position: relative;
    overflow: hidden;
    border-radius: 100px;
    background: ${props => props.backgroundColor ? props.backgroundColor : colors.typography.regular};
    color: ${props => props.color ? props.color : colors.white};
    box-shadow: ${constants.boxShadow(stylesHelpers.customBlackTransparent(0.18))};
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;
    display: flex;
    padding: 5px;
    justify-content: center;
    margin-right: ${props => props.withMargin ? '20px' : 0};
    > svg {
        fill: ${props => props.color ? props.color : colors.primary};
   }
    :hover {
        cursor: pointer;
        opacity: 0.8;
        box-shadow: ${constants.boxShadow(stylesHelpers.customBlackTransparent(0.16))};
  }
  @keyframes ripple {
      to {
          transform: scale(4);
          opacity: 0;
    }
  }
  span.ripple {
      position: absolute;
      border-radius: 50%;
      transform: scale(0);
      animation: ripple 600ms linear;
      background-color: ${props => stylesHelpers.hexToRGBA(props.color || colors.gray.border, 0.7)};
  }
`
