import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Email: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M62.5 63.143h-45a7.508 7.508 0 01-7.5-7.5V23.5a7.508 7.508 0 017.5-7.5h45a7.508 7.508 0 017.5 7.5v32.143a7.508 7.508 0 01-7.5 7.5zm-45-42.857a3.218 3.218 0 00-3.214 3.214v32.143a3.218 3.218 0 003.214 3.214h45a3.218 3.218 0 003.214-3.214V23.5a3.218 3.218 0 00-3.214-3.214z"/>
        <path d="M40 43.857a2.139 2.139 0 01-1.316-.451l-19.286-15a2.143 2.143 0 112.631-3.383L39.999 39l17.97-13.977a2.143 2.143 0 112.631 3.383l-19.286 15a2.139 2.139 0 01-1.314.451z"/>
    </Icon>
)
