import React from 'react'
import styled from 'styled-components'
import { KeyValuePair, TableConfig, HeaderAlignment } from 'lib/types'

type RowTemplateProps<T> = {
    item: KeyValuePair,
    config: Array<TableConfig<T>>,
    rowIndex: number,
    offset: number
}

type TableCellProps = {
    orderNumbers?: boolean,
    alignment?: HeaderAlignment,
    onRowClick?: boolean,
    activeClick?: boolean,
    sticky?: boolean
}

type CellContainerStyle = {
    activeClick?: boolean,
    sticky?: boolean,
    alignment?: HeaderAlignment
}

export const RowTemplate = <T extends KeyValuePair>({
    item,
    rowIndex,
    config,
    offset
}: RowTemplateProps<T>) => {
    const getCellValue = (item: KeyValuePair, index: number) => config.map(element => {
        if (element.hidden) {
            return
        }

        if (element.renderCustomCell) {
            return (
                <CellContainer
                    alignment={element.alignment}
                    sticky={element.stickyColumn}
                    activeClick={Boolean(element.onCellClick)}
                    onClick={() => element.onCellClick && element.onCellClick(item as T)}
                    style={element.customTableCellStyles && element.customTableCellStyles(item as T)}
                    key={element.label}
                >
                    {element.renderCustomCell(item as T)}
                </CellContainer>
            )
        }

        return (
            <TableCell
                sticky={element.stickyColumn}
                onClick={() => element.onCellClick && element.onCellClick(item as T)}
                style={element.customTableCellStyles && element.customTableCellStyles(item as T)}
                key={element.label}
                orderNumbers={element.orderNumbers}
                activeClick={Boolean(element.onCellClick)}
                alignment={element.alignment}
                data-cy="company-label-item"
            >
                {element.orderNumbers ? index + offset + 1 : item[element.key]}
            </TableCell>
        )
    })

    return (
        <tr data-cy="label-row">
            {getCellValue(item, rowIndex)}
        </tr>
    )
}

const TableCell = styled.td<TableCellProps>`
    font-weight: ${props => props.orderNumbers ? '600' : 'normal'};
    text-align: ${props => props.alignment ? props.alignment : 'left'};
    cursor: ${props => props.activeClick ? 'pointer' : 'initial'};
    font-size: 14px;
    background-color: inherit;
    position: ${props => props.sticky ? 'sticky' : 'relative'};
    left: -1px;
`

const CellContainer = styled.td<CellContainerStyle>`
    text-align: ${props => props.alignment ? props.alignment : 'left'};
    cursor: ${props => props.activeClick ? 'pointer' : 'initial'};
    position: ${props => props.sticky ? 'sticky' : 'relative'};
    background-color: inherit;
    left: -1px;
`
