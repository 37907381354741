import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Position } from 'lib/types'
import { Direction, PaginationArrow } from './PaginationArrow'
import { PaginationNumber } from './PaginationNumber'
import { ActionButton } from '../Table'

type PaginationProps = {
    limit: number,
    count: number,
    onNextClick: VoidFunction,
    onPrevClick: VoidFunction,
    onFirstPageClick: VoidFunction,
    onLastPageClick: VoidFunction,
    limitPickerOption?: Array<number>,
    currentPage: number,
    isFirstPage: boolean,
    isLastPage: boolean,
    renderPageNumbers: Array<number>,
    limitPicker?: boolean,
    footerActionButton?: Array<ActionButton>,
    dropdownLimitPickerPosition?: Position,
    onChangeLimit(limit: number): void,
    onPageClick(page: number): void,
    onPageClick(page: number): void
}

export const Pagination = ({
    limit,
    onNextClick,
    onPageClick,
    onPrevClick,
    currentPage,
    isLastPage,
    isFirstPage,
    renderPageNumbers,
    count,
    onLastPageClick,
    onFirstPageClick
}: PaginationProps) => {
    const lastPage = Math.ceil(count / limit)

    return (
        <Container>
            <PaginationWrapper>
                <PaginationArrow
                    direction={Direction.First}
                    isDisabled={isFirstPage}
                    onClick={onFirstPageClick}
                />
                <PaginationArrow
                    direction={Direction.Left}
                    isDisabled={isFirstPage}
                    onClick={onPrevClick}
                />
                <PaginationNumbersContainer>
                    {renderPageNumbers.map(page => (
                        <PaginationNumber
                            key={page}
                            isActive={page === currentPage}
                            number={page}
                            onClick={page => onPageClick(page)}
                        />
                    ))}
                    {renderPageNumbers.length !== 4 && renderPageNumbers.length >= 3 && currentPage < lastPage - 2 && (
                        <Fragment>
                            <Dots>
                                ...
                            </Dots>
                            <PaginationNumber
                                key={lastPage}
                                isActive={lastPage === currentPage}
                                number={lastPage}
                                onClick={onPageClick}
                            />
                        </Fragment>
                    )}
                </PaginationNumbersContainer>
                <PaginationArrow
                    isDisabled={isLastPage}
                    direction={Direction.Right}
                    onClick={onNextClick}
                />
                <PaginationArrow
                    direction={Direction.Last}
                    isDisabled={isLastPage}
                    onClick={onLastPageClick}
                />
            </PaginationWrapper>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 2px;
`

const PaginationWrapper = styled.div`
    display: flex;
    margin-left: auto;
    > div:first-child {
        margin-right: 10px;
    }
    > div:last-child {
        margin-left: 10px;
    }
`

const PaginationNumbersContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 23px;
    margin-right: 23px;
    > div:last-child {
        margin-right: 0;
    }
`

const Dots = styled.div`
    margin-right: 10px;
`
