import React from 'react'
import { differenceInSeconds, addHours, addMinutes } from 'date-fns'
import { userDataWithChat } from 'app/Components/types'
import { dateHelpers } from 'lib/utils'
import { GoogleParticipant, VCEventType } from 'lib/models'
import { CircleAvatarName, EventTime, MessageText, MsgContianer, UserMsgContainer, UserName } from './style'

interface MessageProps {
    chat: userDataWithChat,
    recordStartTime: number,
    googleChat?: GoogleParticipant,
    onClick?(param: number): void
}

export const Message: React.FunctionComponent<MessageProps> = ({
    chat,
    recordStartTime,
    onClick,
    googleChat
}) => {
    const isStudent = chat.role === 'student'
    const name = `${chat.firstName} ${chat.lastName}`
    const secondsEvent = differenceInSeconds(chat.eventTime, recordStartTime)

    if (chat.type === VCEventType.GoogleMeetTranscript && googleChat) {
        const start = new Date(recordStartTime)
        const [ hours, minutes ] = googleChat.timeFrom.split(':')
        const time = addHours(addMinutes(start, Number(minutes || 0)), Number(hours || 0))

        return (
            <UserMsgContainer alignRight={googleChat.isTeacher}>
                <MsgContianer alignRight={googleChat.isTeacher}>
                    <UserName whiteColor={googleChat.isTeacher}>{googleChat.participantName}</UserName>
                    <MessageText whiteColor={googleChat.isTeacher}>{googleChat.message}</MessageText>
                    <EventTime>{dateHelpers.formatDate(time, 'hh:mm')}</EventTime>
                </MsgContianer>
            </UserMsgContainer>
        )
    }

    return (
        <UserMsgContainer
            alignRight={isStudent}
        >
            {!isStudent && <CircleAvatarName>M</CircleAvatarName>}
            <MsgContianer
                alignRight={isStudent}
                onClick={() => onClick?.(secondsEvent)}
            >
                <UserName whiteColor={isStudent}>{name}</UserName>
                <MessageText whiteColor={isStudent}>{chat.message}</MessageText>
                <EventTime>{dateHelpers.formatDate(new Date(chat.eventTime), 'hh:mm')}</EventTime>
            </MsgContianer>
            {isStudent && <CircleAvatarName>M</CircleAvatarName>}
        </UserMsgContainer>
    )
}
