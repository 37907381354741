export enum VCEventType {
    TextMessage = 'text-message',
    Connection = 'connection',
    Recording = 'recording',
    GoogleMeetTranscript = 'google-meet-transcript'
}

export type VCEvent = {
    userUid: string,
    eventTime: number, // timestamp ms
    message: string,
    type: VCEventType
}
