import { Button } from 'app/Components'
import React from 'react'
import styled from 'styled-components'
import background from 'assets/about_background.png'
import { colors } from 'lib/styles'
import { TryFreeEnum } from 'lib/types'
import { CONFIG } from 'lib/config'
import { useTryFree, useTranslations } from 'lib/hooks'

export const About: React.FunctionComponent = () => {
    const T = useTranslations()
    const { tryFreeActions: { setPage } } = useTryFree()

    return (
        <Wrraper>
            <Background src={`${CONFIG.IMAGE_API}${CONFIG.PATH_NAME}${background}`}/>
            <Content>
                <Title>
                    {T.pages.about.title}
                </Title>
                <ButtonWrapper>
                    <Button.Button
                        height={56}
                        fontSize={16}
                        backgroundColor={colors.orange}
                        textColor={colors.white}
                        onClick={() => setPage({
                            page: TryFreeEnum.SignUp
                        })}
                        content={T.pages.about.tryFree}
                    />
                </ButtonWrapper>
                <Description>
                    <Note>{T.common.seeInAction}</Note>
                    <iframe width="560" height="315"
                        src={T.pages.about.videoUrl}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen/>
                </Description>
            </Content>
        </Wrraper>
    )
}

const Wrraper = styled.div`
    min-height: calc(100vh - 228px);
`

const Background = styled.img`
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
`

const Title = styled.div`
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    margin-bottom: 32px;
    font-family: 'Roboto Slab' !important;
    white-space: break-spaces;
`

const Content = styled.div`
    max-width: 560px;
`

const Description = styled.div`
    font-size: 16px;
    line-height: 34px;
    max-width: 500px;
`

const Note = styled.div`
    font-size: 26px;
    font-family: 'Roboto Slab' !important;
    white-space: break-spaces;
    max-width: 410px;
    text-align: left;
    margin-bottom: 10px;
`

const ButtonWrapper = styled.div`
    display: inline-flex;
    min-width: 280px;
    margin-bottom: 32px;
`
