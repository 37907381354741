export enum TryFreeEnum {
    About = 'about',
    Book = 'book',
    SignUp = 'signUp',
    Confirm = 'confirm',
    ConfirmBooking = 'confirmBooking'
}

export type TryFreePage = {
    page: TryFreeEnum,
    consultationBooked?: boolean
}
