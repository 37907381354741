/* eslint-disable camelcase */
import React, { useState } from 'react'
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements, ExpressCheckoutElement
} from '@stripe/react-stripe-js'
import { CONFIG } from 'lib/config'
import { PostApplyCouponResponse, ProductInfo } from '../types'
import styled from 'styled-components'
import { Nullable } from 'lib/types'
import { useTranslations } from 'lib/hooks'

export interface PurchaseFormProps {
    price?: Nullable<number>,
    discount?: PostApplyCouponResponse,
    hasRecurringPayments?: boolean
}

export const CheckoutForm: React.FunctionComponent<PurchaseFormProps> = ({
    price,
    discount,
    hasRecurringPayments
}) => {
    const T = useTranslations()
    const stripe = useStripe()
    const elements = useElements()

    const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const backendUrl = `${CONFIG.USER_API}/subscription/purchase`

    const handleSubmit = async event => {
        event.preventDefault()
        setIsSubmitting(true)
        if (elements === null || stripe === null) {
            return
        }

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit()
        if (submitError?.message) {
            // Show error to your customer
            setErrorMessage(submitError.message)
            setIsSubmitting(false)

            return
        }

        const { error } = await stripe.confirmPayment({
            elements,
            // clientSecret,
            confirmParams: {
                return_url: `https://${window.location.host}/product/payment-confirmed`
            }
        })

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message || '')
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
        setIsSubmitting(false)
    }

    return (
        <form onSubmit={handleSubmit} className="px-4">
            {/* <ExpressCheckoutElement />*/}
            <PaymentElement />
            <PurchaseButton type="submit" disabled={!stripe || !elements || isSubmitting}>
                {T.pages.purchase.purchase} ${discount ? `${discount.newPrice}${hasRecurringPayments ? '*' : ''}` : price}
                {discount && hasRecurringPayments && (<DiscountNote>*First payment only. Recurring payments will be ${price}</DiscountNote>)}
            </PurchaseButton>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    )
}

const PurchaseButton = styled.button`
    padding: 15px 20px;
`

const DiscountNote = styled.div`
  font-size: 0.85em;
  min-width: 250px;
  padding-top: 10px;
`