import React from 'react'
import { Nullable } from 'lib/types'
import { Recording, UserWithRole, VCEvent } from 'lib/models'
import {
    userDataWithChat,
    userParticipantType
} from 'app/Components'
import { getUserInfo } from 'app/Actions'

const useVideoPlayerModel = ({
    chatHistory,
    recording,
    participants,
    showModel
}: {
    chatHistory?: Array<VCEvent>,
    recording: Recording,
    participants?: Array<UserWithRole>,
    showModel: boolean
}) => {
    const hasChatHistory = chatHistory && chatHistory.length > 0
    const [time, setTime] = React.useState<number>()
    const [chatUsers, setChatUsers] = React.useState<Array<userDataWithChat>>()
    const recordStartTime = recording.startTime
    const videoRef = React.useRef<Nullable<HTMLVideoElement>>()
    const fetchUserInfo = async () => {
        const userInfos = await Promise.all(participants?.map(val => {
            const userData = getUserInfo(val.userUid)

            return userData
        }) as Iterable<userParticipantType | PromiseLike<userParticipantType>>)
        const mergedChatData = chatHistory!.map(chat => {
            const userData = userInfos.filter(user => user.userUid === chat.userUid)[0]

            return {
                ...chat,
                ...userData
            }
        })
        setChatUsers(mergedChatData)
    }
    React.useEffect(() => {
        if (videoRef.current && time) {
            videoRef.current.currentTime = time
        }
        if (hasChatHistory) {
            fetchUserInfo()
        }
        if (showModel) {
            setTime(undefined)
        }
    }, [time, hasChatHistory])

    return {
        hasChatHistory,
        videoRef,
        recordStartTime,
        setTime,
        chatUsers
    }
}

export default useVideoPlayerModel
