import React, { forwardRef } from 'react'
import { Field } from '@codegateinc/react-form-builder-v2'
import { OutlinedInput } from '../OutlinedInput'

interface InputProps extends Field<string> {
    password?: boolean,
    disabled?: boolean,
    onClick?: VoidFunction,
    onKeyDown?: VoidFunction,
    leftIcon?: React.ReactNode,
    frozenValue?: string,
    isNumeric?: boolean,
    isNumericFloat?: boolean,
    select?: boolean,
    maxLength?: number
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
    <OutlinedInput
        ref={ref}
        maxLength={props.maxLength}
        isNumeric={props.isNumeric}
        isNumericFloat={props.isNumericFloat}
        select={props.select}
        margin
        disabled={props.disabled}
        label={props.label}
        error={props.errorMessage}
        frozenValue={props.frozenValue || props.value}
        onChange={value => props.onChangeValue(value)}
        onClick={props.onClick}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        leftIcon={props.leftIcon}
        type={props.password ? 'password' : 'text'}
        onEnter={props.onKeyDown}
    />
))
