import styled from 'styled-components'

interface DescriptionProps {
    widthDescription?: number
}

export const TimeDescription = styled.span`
	font: normal normal normal 12px/16px Roboto;
	letter-spacing: 0px;
	color: ${({ theme }) => theme.colors.grey[400]};
`

export const Description = styled.p<DescriptionProps>`
	font: normal normal normal 14px/19px Roboto;
	letter-spacing: 0px;
	color: ${({ theme }) => theme.colors.black[100]};
	${({ widthDescription }) =>
        widthDescription ? `width: ${widthDescription}%;` : ''};
`

export const ButtonContainer = styled.div`
	display: flex;
`
