import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Pic: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M11.538 26.52v27.351h56.924V26.52H11.538M10 25.081h60V55.31H10Z" data-name="Rectangle 1951"/>
        <path d="M21.01 49.302h4.308v-4.965h2.981c3.791 0 6.233-2.324 6.233-5.951v-.023c0-3.627-2.441-6-6.233-6H21.01Zm6.256-13.639c1.831 0 2.911.939 2.911 2.7v.023c0 1.761-1.08 2.71-2.911 2.71h-1.948v-5.433Zm10.423 13.639h4.308V32.365h-4.308Zm15.693.458c4.343 0 7.348-2.641 7.63-6.421v-.187h-4.143l-.023.129a3.338 3.338 0 0 1-3.451 2.915c-2.371 0-3.815-2.031-3.815-5.364v-.028c0-3.31 1.444-5.329 3.8-5.329a3.387 3.387 0 0 1 3.466 3.021l.011.1H61v-.21c-.246-3.744-3.357-6.479-7.618-6.479-5.082 0-8.193 3.392-8.193 8.909v.023c.001 5.517 3.123 8.921 8.194 8.921Z" data-name="Path 377"/>
    </Icon>
)
