import styled from 'styled-components'

export const MicButtonContainer = styled.button`
	padding: 0;
	border: none;
	background: none;
	cursor: pointer;
	padding-left: 1rem;
`

export const MicRecordContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	button {
		&:after {
			content: "|";
			margin-left: 1rem;
		}
	}
	button:last-child {
		&:after {
			content: "";
			margin-left: 0;
		}
	}
`

export const LoaderContainer = styled.div`
	position: relative;
	margin-right: 2.6rem;
`

export const UploadInput = styled.input`
	display: none !important;
`

export const UploadButtonContainer = styled.span`
	margin-top: 2rem;
`
