import { useState } from 'react'
import { KeyValuePair, Sort } from 'lib/types'
import { tableUtils } from '../utils'

export enum FilterConfigKeys {
    OrderBy = 'orderBy',
    OrderWay = 'orderWay'
}

type UseTableParams = {
    allRows: number,
    defaultLimit?: number,
    onChange?(params: KeyValuePair): void
}

export const useTable = ({
    allRows,
    onChange,
    defaultLimit
}: UseTableParams) => {
    const [filters, setFilters] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [limit, setLimit] = useState(defaultLimit || 5)
    const numberOfPages = allRows ? Math.ceil(allRows / limit) : 0
    const arrayOfPages = Array.from(Array(numberOfPages), (e, i) => i + 1)
    const isLastPage = currentPage === numberOfPages
    const isFirstPage = currentPage === 1
    const offset = limit * (currentPage - 1)

    const onChangeLimit = (value: number) => {
        setCurrentPage(1)
        setLimit(value)

        if (onChange) {
            onChange({
                limit: value,
                offset: 0,
                ...filters
            })
        }
    }

    const onFirstPageClick = () => {
        setCurrentPage(1)

        if (onChange) {
            onChange({
                limit,
                offset: 0,
                ...filters
            })
        }
    }

    const onLastPageClick = () => {
        if (allRows && onChange) {
            setCurrentPage(Math.ceil(allRows / limit))
            onChange({
                limit,
                offset: (Math.ceil(allRows / limit) - 1) * limit,
                ...filters
            })
        }
    }

    const onPageClick = (page: number) => {
        setCurrentPage(page)

        if (onChange) {
            onChange({
                limit,
                offset: (page - 1) * limit,
                ...filters
            })
        }
    }

    const onPrevClick = () => {
        setCurrentPage(prevState => prevState - 1)

        if (onChange) {
            onChange({
                limit,
                offset: (currentPage - 2) * limit,
                ...filters
            })
        }
    }

    const onNextClick = () => {
        setCurrentPage(prevState => prevState + 1)

        if (onChange) {
            onChange({
                limit,
                offset: currentPage * limit,
                ...filters
            })
        }
    }

    const changeFilter = (key: string, value: number | string | boolean) => {
        setCurrentPage(1)
        setFilters(prevState => ({
            ...prevState,
            [key]: value
        }))

        if (onChange) {
            onChange({
                ...filters,
                [key]: value,
                limit,
                offset: 0
            })
        }
    }

    const changeSort = (key: string, value: Sort) => {
        setCurrentPage(1)
        setFilters(prevState => ({
            ...prevState,
            [FilterConfigKeys.OrderBy]: key,
            [FilterConfigKeys.OrderWay]: value
        }))

        if (onChange) {
            onChange({
                limit,
                offset: 0,
                ...filters,
                [FilterConfigKeys.OrderBy]: key,
                [FilterConfigKeys.OrderWay]: value
            })
        }
    }

    const renderPageNumbers = () => tableUtils.createPages(currentPage, arrayOfPages, numberOfPages)

    return {
        values: {
            limit,
            currentPage,
            isLastPage,
            isFirstPage,
            offset,
            count: allRows,
            filters
        },
        functions: {
            onPrevClick,
            onNextClick,
            onPageClick,
            renderPageNumbers,
            onFirstPageClick,
            onLastPageClick,
            onChangeLimit,
            setCurrentPage,
            changeFilter,
            changeSort,
            setFilters
        }
    }
}
