import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'

type NumberProps = {
    isActive: boolean
}

type PaginationNumberProps = {
    isActive: boolean,
    number: number,
    onClick(page: number): void
}

export const PaginationNumber: React.FunctionComponent<PaginationNumberProps> = ({
    isActive,
    onClick,
    number
}) => (
    <Number
        key={number}
        isActive={isActive}
        onClick={() => !isActive && onClick(number)}
    >
        {number}
    </Number>
)

const Number = styled.div<NumberProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.isActive ? colors.primary : '#2C3E50'};
    margin-right: 14px;
    line-height: 0;
    cursor: pointer;
`
