import React, { useEffect, useMemo, useState } from 'react'
import { useTranslations, useTryFree } from 'lib/hooks'
import styled from 'styled-components'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import {
    addWeeks,
    endOfDay,
    format,
    fromUnixTime,
    getUnixTime,
    isWithinInterval,
    startOfDay
} from 'date-fns'
import DatePicker from 'react-datepicker'
import { DateFormats } from 'common'
import { colors } from 'lib/styles'
import { Consultation, TryFreeEnum, ConsultationLanguage } from 'lib/types'
import { Typography } from 'app/UI'
import { Button, CustomInputDatePicker, FormComponents, NewLoader } from 'app/Components'
import { generateInputStyles } from '../utils'
import { useAddConsultation, useGetConsultation } from '../actions'
import { dateInSelectedTimezone } from './utils'

type SelectedTimezone = {
    label: string,
    value: number
}

export const Book = () => {
    const T = useTranslations()
    const { tryFreeActions: { setPage, setConsultation }, tryFreeState: { userIsLogged, timezones } } = useTryFree()
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [selectedTeacher, setSelectedTeacher] = useState(ConsultationLanguage.English)
    const [chosenDate, setChosenDate] = useState<Date>()
    const initialSelectedTimezone = timezones.find(timezone => timezone.timezone === browserTimezone)
    const [selectedTimezone, setSelectedTimezone] = useState<SelectedTimezone | undefined>(initialSelectedTimezone ? {
        label: `${initialSelectedTimezone.offset} ${initialSelectedTimezone.timezone}`,
        value: initialSelectedTimezone.timezoneId
    } : undefined)
    const [selectedConsultation, setSelectedConsultation] = useState<Consultation>()
    const [consultationList, setConsultationList] = useState<Array<Consultation>>([])
    const timezoneOptions = timezones.map(timezone => ({
        value: timezone.timezoneId,
        label: `${timezone.offset} ${timezone.timezone}`
    }))
    const { fetch: getConsultation, fetchState: { isLoading } } = useGetConsultation(setConsultationList)
    const { fetch: addConsultation, fetchState: { isLoading: addingConsultation } } = useAddConsultation(() => setPage({
        page: TryFreeEnum.ConfirmBooking,
        consultationBooked: true
    }))
    const randomPeopleCount = useMemo(() => Math.ceil(Math.random() * 10), [])
    const timezoneName = timezones.find(timezone => timezone.timezoneId === selectedTimezone!.value)!.timezone
    const dateInTimezone = dateInSelectedTimezone(timezoneName)
    const [startDate, setStartDate] = useState(dateInTimezone)

    useEffect(() => {
        const dateInTimezone = dateInSelectedTimezone(timezoneName)

        setStartDate(dateInTimezone)
        setChosenDate(dateInTimezone)
    }, [selectedTimezone])

    useEffect(() => {
        if (!selectedTimezone && !browserTimezone || !chosenDate) {

            return
        }

        const startDateInChosenDate = zonedTimeToUtc(startOfDay(chosenDate), timezoneName)
        const endDateInChosenDate = zonedTimeToUtc(endOfDay(chosenDate), timezoneName)
        const startOfTodayDate = zonedTimeToUtc(startOfDay(dateInTimezone), timezoneName)
        const endOfTodayDate = zonedTimeToUtc(endOfDay(dateInTimezone), timezoneName)
        const todayStartDate = zonedTimeToUtc(chosenDate, timezoneName)
        const isToday = isWithinInterval(todayStartDate, {
            start: startOfTodayDate,
            end: endOfTodayDate
        })
        const startDate = isToday ? todayStartDate : startDateInChosenDate

        getConsultation({
            consultationLanguage: selectedTeacher,
            startDate: getUnixTime(startDate),
            endDate: getUnixTime(endDateInChosenDate)
        })
        setSelectedConsultation(undefined)
    }, [selectedTeacher, chosenDate])

    return (
        <>
            <Title>
                {T.pages.book.title}
            </Title>
            <SubTitle>
                {T.pages.book.subTitle}
            </SubTitle>
            <InnerWrapper>
                {/* <ButtonsWrapper>*/}
                {/*    <FormComponents.Button*/}
                {/*        onClick={() => setSelectedTeacher(ConsultationLanguage.English)}*/}
                {/*        content={T.pages.book.consultationEnglish}*/}
                {/*        checked={selectedTeacher === ConsultationLanguage.English}*/}
                {/*    />*/}
                {/*     <FormComponents.Button*/}
                {/*        onClick={() => setSelectedTeacher(ConsultationLanguage.Japanese)}*/}
                {/*        content={T.pages.book.consultationJapanese}*/}
                {/*        checked={selectedTeacher === ConsultationLanguage.Japanese}*/}
                {/*     />*/}
                {/* </ButtonsWrapper>*/}
                <SubTitle>
                    {T.pages.book.chooseDateText}
                </SubTitle>
                <DatesWrapper>
                    <FormsWrapper>
                        <Label>
                            {T.pages.book.chooseDate}
                        </Label>
                        <DatePicker
                            placeholderText={T.pages.signUp.placeholderBirtDate}
                            selected={chosenDate}
                            minDate={startDate}
                            maxDate={addWeeks(startDate, 1)}
                            dateFormat={DateFormats.FullExtendedDate}
                            onChange={(date: Date) => setChosenDate(date)}
                            customInput={<CustomInputDatePicker/>}
                        />
                    </FormsWrapper>
                    <FormsWrapper>
                        <Label>
                            {T.pages.book.chooseTimezone}
                        </Label>
                        <FormComponents.SingleSelect
                            placeholder={T.pages.signUp.timezonePlaceholder}
                            selected={selectedTimezone}
                            iconColor={selectedTimezone ? colors.white : colors.gray.typography}
                            options={timezoneOptions}
                            onChange={item => {
                                if (!item) {
                                    return
                                }

                                setSelectedTimezone({
                                    label: item.label,
                                    value: item.value as number
                                })

                            }}
                            customInputStyles={generateInputStyles(Boolean(selectedTimezone))}
                        />
                    </FormsWrapper>
                </DatesWrapper>
                <Label>
                    {T.pages.book.chooseTime}
                </Label>
                {selectedTimezone && (
                    <ChooseTimeWrapper>
                        {consultationList.map((consultation => (
                            <ButtonWrapper key={consultation.date}>
                                <FormComponents.Button
                                    centerContent
                                    onClick={() => setSelectedConsultation(consultation)}
                                    content={format(utcToZonedTime(fromUnixTime(consultation.date), timezones.find(timezone => timezone.timezoneId === selectedTimezone!.value)!.timezone), DateFormats.Hour)}
                                    checked={Boolean(selectedConsultation && selectedConsultation.date === consultation.date)}
                                />
                            </ButtonWrapper>
                        )))}
                        {isLoading && (
                            <LoaderWrapper>
                                <NewLoader/>
                            </LoaderWrapper>
                        )}
                        {consultationList.length === 0 && !isLoading && (
                            <NoAvailable>
                                {T.pages.book.notAvailableTeachers}
                            </NoAvailable>
                        )}
                    </ChooseTimeWrapper>
                )}
                <Footer>
                    <RandomPeople>
                        {T.pages.book.beforePeopleLooking}{randomPeopleCount} {T.pages.book.peopleLooking}
                    </RandomPeople>
                    <SubmitWrapper>
                        <Button.Button
                            disabled={!selectedConsultation || addingConsultation}
                            isLoading={addingConsultation}
                            height={56}
                            fontSize={16}
                            backgroundColor={colors.orange}
                            textColor={colors.white}
                            onClick={() => {
                                if (userIsLogged) {
                                    addConsultation(selectedConsultation)

                                    return
                                }

                                setPage({
                                    page: TryFreeEnum.SignUp
                                })
                                setConsultation(selectedConsultation)
                            }}
                            content={T.pages.book.bookNow}
                        />
                    </SubmitWrapper>
                </Footer>
            </InnerWrapper>
        </>
    )
}

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
`

const Title = styled(Typography.Heading)`
    text-align: center;
    white-space: break-spaces;
`

const SubTitle = styled(Typography.SubHeading)`
    text-align: center;
`

const InnerWrapper = styled.div`
    max-width: 780px;
    margin: auto;
`

const DatesWrapper = styled.div`
    display: flex;
    gap: 40px;
    margin-top: 24px;
    margin-bottom: 24px;
`

const FormsWrapper = styled.div`
    width: 100%;
`

const ChooseTimeWrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
`

const ButtonWrapper = styled.div`
    width: 93px;
`

const Label = styled.div`
    margin-bottom: 15px;
    font-size: 16px;
`

const Footer = styled.div`
    display: flex;  
    justify-content: space-between;
    align-items: center;
    margin-top: 83px;
`

const SubmitWrapper = styled.div`
    flex-basis: 320px;
`

const RandomPeople = styled.div`
    color: ${colors.gray.normal};
    font-weight: 500;
`

const LoaderWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const NoAvailable = styled.div`
    position: absolute;
    top: 0;
`
