import styled from 'styled-components'

interface SvgProps {
    svgWdith?: number,
    svgHeight?: number
}

export const Svg = styled.svg<SvgProps>`
	${({ svgWdith }) => svgWdith ? `width: ${svgWdith}px !important;` : ''};
	${({ svgHeight }) => svgHeight ? `height: ${svgHeight}px !important;` : ''};
`
