import { FormComponents } from 'app/Components'

export enum SignUpFields {
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    NewPassword = 'newPassword',
    ConfirmNewPassword = 'confirmNewPassword',
    AcceptTerms = 'acceptTerms',
    ReferralCode = 'referralCode'
}

export type SignUpFormShape = {
    [SignUpFields.FirstName]: string,
    [SignUpFields.LastName]: string,
    [SignUpFields.Email]: string,
    [SignUpFields.NewPassword]: string,
    [SignUpFields.ConfirmNewPassword]: string,
    [SignUpFields.AcceptTerms]: boolean,
    [SignUpFields.ReferralCode]: string
}
