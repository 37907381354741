import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const EyeOff: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M62.001 64.27a1.991 1.991 0 01-1.414-.586l-44-44a2 2 0 012.827-2.827l44 44A2 2 0 0162 64.271zm-22.042-8a27.041 27.041 0 01-14.865-4.562 37.676 37.676 0 01-11.087-11.375v-.01a45.48 45.48 0 018.155-9.026.25.25 0 00.031-.352l-.014-.015-2.49-2.486a.25.25 0 00-.339-.015 49.569 49.569 0 00-8.635 9.615 3.99 3.99 0 00-.08 4.442A41.764 41.764 0 0022.921 55.07a30.963 30.963 0 0017.038 5.2 29.894 29.894 0 009.475-1.572.25.25 0 00.1-.414l-2.7-2.7a.5.5 0 00-.479-.125 25.606 25.606 0 01-6.395.811zM69.36 38.1a42.365 42.365 0 00-12.409-12.566 31.4 31.4 0 00-16.991-5.259 28.414 28.414 0 00-9.361 1.6.25.25 0 00-.094.414l2.694 2.694a.5.5 0 00.485.125 24.1 24.1 0 016.276-.836 27.032 27.032 0 0114.818 4.625 38.339 38.339 0 0111.22 11.375.017.017 0 010 .02 38.843 38.843 0 01-8.015 9.091.25.25 0 00-.034.352l.015.017 2.487 2.486a.25.25 0 00.337.016 42.937 42.937 0 008.58-9.81 4.025 4.025 0 00-.012-4.347z" data-name="Path 49"/>
        <path d="M40.002 28.276a11.981 11.981 0 00-2.671.3.25.25 0 00-.125.423l14.074 14.07a.25.25 0 00.422-.125 12 12 0 00-11.7-14.667zm-11.277 9.207a.25.25 0 00-.423.125 12 12 0 0014.377 14.375.25.25 0 00.125-.422z" data-name="Path 50"/>
    </Icon>
)
