import React from 'react'
import ReactDOM from 'react-dom'
import { Airoid, Env } from 'lib/types'
import { App } from './app'
import './index.css'

declare global {
    interface Window {
        env: Env,
        airoid: {
            // eslint-disable-next-line @typescript-eslint/tslint/config
            Airoid: () => void
        },
        droid: Airoid
    }
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('student-web')
)
