import React from 'react'
import { useTheme } from 'styled-components'
import {
    Description,
    IconContainer,
    NoteContainer,
    DescriptionContainer
} from 'app/Components'
import { NoteIcon } from 'app/UI'
import { useTranslations } from 'lib/hooks'
import { Nullable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { NoteBody } from 'lib/models'
import {
    CustomNoteHtml,
    TeacherName
} from './style'

interface NoteProps {
    note: Nullable<NoteBody>
}

export const TeacherNote: React.FunctionComponent<NoteProps> = ({ note }) => {
    const theme = useTheme()
    const T = useTranslations()

    return (
        <DescriptionContainer>
            {note ? (
                <>
                    <CustomNoteHtml dangerouslySetInnerHTML={{
                        __html: note?.content
                    }} />
                    <TeacherName>
                        {T.components.writtenByOn.replace('{{teacherName}}', note.teacherName)} {dateHelpers.formatToLocaleDateString(new Date(note?.createdAt * 1000))}
                    </TeacherName>
                </>
            ) : (
                <NoteContainer
                    responsiveMdalignICenter
                    topBorder
                    alignCenter
                >
                    <IconContainer
                        responsiveMdIcon
                        color={theme.colors.orange}
                    >
                        <NoteIcon
                            width={25}
                            height={25}
                            fill={theme.colors.white}
                        />
                    </IconContainer>
                    <Description textNotCenter>
                        {T.components.noteNotAvailable}
                    </Description>
                </NoteContainer>
            )}
        </DescriptionContainer>
    )
}
