import React from 'react'
import styled from 'styled-components'
import { colors, stylesHelpers } from 'lib/styles'

type ButtonProps = {
    onClick: VoidFunction,
    content: string,
    checked: boolean,
    className?: string,
    centerContent?: boolean
}

type ButtonStyles = {
    checked: boolean,
    centerContent?: boolean
}

export const Button: React.FunctionComponent<ButtonProps> = ({
    content,
    onClick,
    checked,
    centerContent
}) => (
    <ButtonWrapper
        onClick={onClick}
        checked={checked}
        centerContent={centerContent}
    >
        {content}
    </ButtonWrapper>
)

const ButtonWrapper = styled.div<ButtonStyles>`
    display: flex;
    align-items: center;
    justify-content: ${props => props.centerContent ? 'center' : 'start'};
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid ${stylesHelpers.hexToRGBA(colors.gray.inputBorder, 0.8)};
    padding: 16px;
    width: 100%;
    height: 66px;
    font-size: 18px;
    color: ${props => props.checked ? `${colors.white}` : `${colors.typography.placeholder}`};
    background: ${props => props.checked ? `${colors.blue}` : `${colors.white}`};
`
