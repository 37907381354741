import React from 'react'
import {
    TeacherNote,
    Recordings,
    VCRecordings,
    Content,
    DateHeading,
    SubTitle
} from 'app/Components'
import { useTranslations } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'
import { Note, RecordingInfo } from 'lib/models'
import {
    TimeDescription
} from './style'

interface NoteContainerProps {
    note: Note
}

export const NoteContainer: React.FunctionComponent<NoteContainerProps> = ({
    note
}) => {
    const T = useTranslations()
    const [recordingState, setRecodingState] = React.useState(note.replayChallengeAnswerRecordings.length === 0)
    const appointmentId = note.appointmentId
    const resetRecordingState = () => setRecodingState(false)

    return (
        <Content>
            <DateHeading>
                {dateHelpers.formatToLocaleDateString(note.callTime)}
            </DateHeading>
            <TimeDescription>
                {T.pages.timeLabel}: {dateHelpers.formatAMPM(new Date(note.callTime))}
            </TimeDescription>
            <TeacherNote note={note.note} />
            <SubTitle>
                {T.pages.RecordingsTitle}
            </SubTitle>
            <VCRecordings
                recordingInfo={note.recordingInfo || {} as RecordingInfo}
            />
            <SubTitle>
                {T.components.replayChallengTitle}
            </SubTitle>
            <Recordings
                resetRecordingState={resetRecordingState}
                appointmentId={appointmentId}
                records={note.replayChallengeAnswerRecordings}
                recordingState={recordingState}
            />
        </Content>
    )
}
