import React from 'react'
import styled from 'styled-components'
import loader from 'assets/loader.png'
import loader2x from 'assets/loader@2x.png'

type LoaderProps = {
    isLoading: boolean
}

export const Loader: React.FunctionComponent<LoaderProps> = ({ isLoading }) => isLoading ? (
    <AnimatedLoader
        srcSet={`
            ${loader} 1x, 
            ${loader2x} 2x
        `}
        alt="loader"
    />
) : null

const AnimatedLoader = styled.img`
  @keyframes rotate-loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: rotate-loader 1s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
`
