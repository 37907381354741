import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const UserContent: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M39.858 40c-7.081 0-13.327-6.659-13.923-14.844a14.148 14.148 0 013.61-10.818 14.373 14.373 0 0120.6.027 14.11 14.11 0 013.631 10.794C53.174 33.342 46.928 40 39.858 40zm0-25.714a9.636 9.636 0 00-7.175 2.97 9.913 9.913 0 00-2.474 7.589c.429 5.892 4.847 10.87 9.648 10.87s9.212-4.979 9.649-10.873a9.874 9.874 0 00-2.5-7.561 9.608 9.608 0 00-7.148-2.995zM51.644 25z" data-name="Path 19"/>
        <path d="M63.429 69.999H16.286a4.147 4.147 0 01-3.241-1.49 4.734 4.734 0 01-.962-3.9 22.927 22.927 0 0110.2-15.234 32.865 32.865 0 0135.14 0 22.932 22.932 0 0110.206 15.234 4.735 4.735 0 01-.962 3.9 4.149 4.149 0 01-3.238 1.49zm-47.116-4.286h47.091a.7.7 0 00.012-.343 18.739 18.739 0 00-8.365-12.429 28.591 28.591 0 00-30.387 0A18.737 18.737 0 0016.3 65.37a.707.707 0 00.013.344zm47.149 0z" data-name="Path 20"/>
    </Icon>
)
