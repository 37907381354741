export enum TimezoneName {
    AmericaTimezone = 'America/Los_Angeles',
    Utc = 'Etc/UTC'
}

export type Timezone = {
    timezoneId: number,
    timezone: string,
    offset: string
}
