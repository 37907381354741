import axios from 'axios'
import { CONFIG } from 'lib/config'
import { getAuthCookie, shouldAttachAuthCookie } from './utils'

export const setAxiosDefaults = () => {
    axios.defaults.baseURL = CONFIG.NEI_API
    axios.defaults.timeout = parseInt(CONFIG.TIMEOUT || '10000', 10)
    // axios.defaults.withCredentials = true
}

axios.interceptors.request.use(async request => {
    request.headers['Api-key'] = CONFIG.VC_API_KEY

    if (shouldAttachAuthCookie()) {
        request.url = `${request.url}?token=${getAuthCookie()}`
    }

    return request
})
