import { UserWithRole } from './User'
import { VCEvent } from './VCEvent'

export type RecordingInfo = {
    appointmentId: string,
    participants: Array<UserWithRole>,
    recordings: Array<Recording>,
    metadata: Array<VCEvent>
}

export type Recording = {
    files: Record<VCFileExtension, string>, // string is s3 signed url
    startTime: number,
    endTime: number,
    duration: number
}

enum VCFileExtension {
    M3U8 = 'm3u8',
    MP4 = 'mp4'
}

export type GoogleParticipant = {
    timeFrom: string,
    timeTo: string,
    message: string,
    participantName: string,
    isTeacher?: boolean,
    date?: Date
}
