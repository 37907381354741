export const createPages = (currentPage: number, arrayOfPages: Array<number>, numberOfPages: number) => {
    switch (currentPage) {
        case 1:
        case 2:
            return numberOfPages === 4
                ? arrayOfPages.slice(0, 4)
                : arrayOfPages.slice(0, 3)
        case numberOfPages - 2:
            return arrayOfPages.slice(currentPage - 2, numberOfPages)
        case numberOfPages - 1:
        case numberOfPages:
            return numberOfPages < 4
                ? arrayOfPages.slice(0, numberOfPages)
                : arrayOfPages.slice(numberOfPages - 4, numberOfPages)
        default:
            return arrayOfPages.slice(currentPage - 2, currentPage + 1)
    }
}
