import React from 'react'
import { FilterConfig, SortConfig } from 'app/Components/Table'
import { KeyValuePair } from './common'

export enum Sort {
    Asc = 'ASC',
    Desc = 'DESC'
}

export enum FilterType {
    Input = 'input',
    DatePicker = 'datePicker'
}

export type TableConfig<T> = {
    label: string,
    key: string,
    width?: number,
    hidden?: boolean,
    center?: boolean,
    alignment?: HeaderAlignment,
    withoutSorting?: boolean,
    orderNumbers?: boolean,
    stickyColumn?: boolean,
    customTableCellStyles?(row: T): React.CSSProperties | undefined,
    renderCustomHeaderLabel?(): React.ReactNode,
    renderCustomCell?(row: T): React.ReactNode,
    onCellClick?(row: T): void
}

export type Values = {
    limit: number,
    currentPage: number,
    isLastPage: boolean,
    isFirstPage: boolean,
    offset: number,
    count: number,
    filters: KeyValuePair
}

export type TableMethods = {
    resetSorting: VoidFunction,
    getValues(): Values,
    setSortingState(config: SortConfig): void,
    setFilterState(filterConfig: FilterConfig): void,
    setFirstPage(): void
}

export enum HeaderAlignment {
    Center = 'center',
    Left = 'left',
    Right = 'right'
}
