import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Filter: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M40.45 13.068a77.314 77.314 0 0 0-17.34 1.782 26.654 26.654 0 0 0-5.4 1.825 7.174 7.174 0 0 0-1.575.994 7.174 7.174 0 0 0 1.575.994 26.654 26.654 0 0 0 5.4 1.825 77.314 77.314 0 0 0 17.34 1.783 77.314 77.314 0 0 0 17.34-1.783 26.654 26.654 0 0 0 5.4-1.825 7.174 7.174 0 0 0 1.575-.994 7.174 7.174 0 0 0-1.575-.994 26.654 26.654 0 0 0-5.4-1.825 77.314 77.314 0 0 0-17.34-1.783m0-3.068c15.249.001 27.61 3.435 27.61 7.67s-12.361 7.669-27.609 7.669-27.61-3.438-27.61-7.669S25.202 10 40.45 10Z" data-name="Ellipse 45"/>
        <path d="M13.851 19.817c.32.478 20.713 24.068 20.713 24.068V70l12.576-6.5V43.883l19.91-24.068s-29.199 5.403-53.199.002Z" data-name="Path 387"/>
    </Icon>
)
