import React from 'react'
import { SvgIcon } from 'lib/types'
import { Icon } from 'lib/components'

export const Eye: React.FunctionComponent<SvgIcon> = props => (
    <Icon {...props}>
        <path d="M39.959 60.255a30.98 30.98 0 01-17.037-5.2 41.746 41.746 0 01-12.284-12.581 3.984 3.984 0 01.073-4.434 45.561 45.561 0 0113.715-13.269 29.945 29.945 0 0115.533-4.514 31.434 31.434 0 0116.988 5.259 42.417 42.417 0 0112.412 12.563 4.027 4.027 0 010 4.353A41.7 41.7 0 0156.98 55.04a31.526 31.526 0 01-17.021 5.215zm0-36a26.007 26.007 0 00-13.475 3.944 41.6 41.6 0 00-12.487 12.116 37.89 37.89 0 0011.094 11.376 26.45 26.45 0 0029.723-.016 37.684 37.684 0 0011.177-11.4c.008-.012.008-.019 0-.025a38.389 38.389 0 00-11.222-11.372 27.078 27.078 0 00-14.81-4.621z"/>
        <path d="M40.001 52.255a12 12 0 1112-12 12.013 12.013 0 01-12 12zm0-20a8 8 0 108 8 8.009 8.009 0 00-8-7.999z"/>
    </Icon>
)
