import React from 'react'
import { useBlackboard } from 'app/Actions'
import { Record } from 'lib/models'

const useBlackBoardRecord = () => {
    const { fetchRecordings } = useBlackboard()
    const [state, setState] = React.useState<{
        recordingData: Array<Record>,
        hasError: boolean,
        isLoading: boolean,
        hasEmptyRecords: boolean
    }>({
        recordingData: [],
        hasError: false,
        isLoading: false,
        hasEmptyRecords: false
    })
    const getData = async () => {
        setState(prevState => ({
            ...prevState,
            isLoading: true
        }))
        try {
            const recordingData = await fetchRecordings()
            setState(prevState => ({
                ...prevState,
                recordingData,
                isLoading: false,
                hasEmptyRecords: recordingData.length === 0
            }))
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                hasError: true,
                isLoading: false
            }))
        }
    }
    React.useEffect(() => {
        getData()
    }, [])

    return {
        hasEmptyRecords: state.hasEmptyRecords,
        isLoading: state.isLoading,
        hasError: state.hasError && !state.isLoading || state.recordingData.length === 0 && !state.isLoading,
        canRenderRecording: !state.isLoading && !state.hasError && state.recordingData.length > 0,
        recordingData: state.recordingData,
        onReload: getData
    }
}

export default useBlackBoardRecord
