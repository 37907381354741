import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { Button } from 'app/Components'
import { Typography } from 'app/UI'
import { KEYWORDS_PAGE_LINK } from 'lib/utils/constants'

export const BookingConfirmation: React.FunctionComponent = () => {
    const T = useTranslations()

    return (
        <Container>
            <ConsultationBooked>
                <Title>
                    {T.pages.confirm.bookedConsultation}
                </Title>
                {T.pages.confirm.workingOnConsultation}
                <br/>
                <br/>
                <Bold>{T.pages.confirm.completeProfile}</Bold>
                <br/>
                <br/>
                {T.pages.confirm.bookedNote}
                <br/>
                <br/>
                <Button.Button
                    height={40}
                    fontSize={16}
                    backgroundColor={colors.orange}
                    textColor={colors.white}
                    onClick={() => document.location.href = KEYWORDS_PAGE_LINK}
                    content={T.pages.confirm.completeProfileLink}
                    isLoading={false}
                    disabled={false}
                />
                {/* <a href={KEYWORDS_PAGE_LINK}>{T.pages.confirm.completeProfileLink}</a>*/}
            </ConsultationBooked>
        </Container>
    )
}

const Container = styled.div`
    font-size: 20px;
    white-space: break-spaces;
`

const ConsultationBooked = styled.div`
    text-align: center;
    max-width: 800px;
    min-width: 400px;
`

const Title = styled(Typography.Heading)`
    text-align: center;
    margin-bottom: 32px;
`

const Bold = styled.span`
    font-weight: bold;
`

