import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PathNames } from 'lib/routing'
import { CONFIG } from 'lib/config'
import { renderComponent } from './utils'

export const AccessVerification: React.FunctionComponent = () => {
    const Component = renderComponent(CONFIG.MODULE)

    return (
        <Switch>
            <Route
                component={Component}
                path={PathNames.Root}
            />
            <Redirect to={PathNames.Root} />
        </Switch>
    )
}
