import { HttpMethod, OnSuccessResponse } from 'lib/types'
import { useFetch } from 'lib/hooks'
import { CONFIG } from 'lib/config'
import { GetAssessmentLinkRequest, SignUpRequest, SignUpResponse } from './types'

export const useSignUp = (onSuccess: OnSuccessResponse<SignUpResponse, SignUpRequest>) =>
    useFetch<SignUpResponse, SignUpRequest>({
        url: `${CONFIG.USER_API}/user/sign-up`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })

export const useGetAssessmentLink = (onSuccess: OnSuccessResponse<string, GetAssessmentLinkRequest>) =>
    useFetch<string, GetAssessmentLinkRequest>({
        url: `${CONFIG.USER_API}/auto-assessment/assign`,
        method: HttpMethod.POST
    }, {
        onSuccess
    })
