import React from 'react'
import { useTheme } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { Nullable, Undefinable } from 'lib/types'
import { dateHelpers } from 'lib/utils'
import { Recording, Reply } from 'lib/models'
import { ErrorRecording, RecordState } from 'app/Components/types'
import { useUploadRecording, UploadRecordingResponse } from 'app/Actions'
import { constants } from 'common'

interface StateRecord {
    recordState: string,
    audioUrl: string,
    error: Undefinable<ErrorRecording>,
    seconds: number,
    records: Array<Reply | Recording>,
    formData: FormData,
    time: string
}

export const useVoiceRecorder = ({
    appointmentId,
    records,
    resetRecordingState
}: {
    appointmentId: string,
    records: Array<Reply>,
    resetRecordingState?: VoidFunction
}) => {
    const [state, setState] = React.useState<StateRecord>({
        recordState: '',
        audioUrl: '',
        error: undefined,
        time: '',
        seconds: constants.SECONDS_TIMER,
        records,
        formData: new FormData()
    })
    const devices = navigator.mediaDevices
    const mediaRecorder = React.useRef<Undefinable<any>>()
    const intervalId: React.MutableRefObject<any> = React.useRef(null)
    const theme = useTheme()
    const color = state.recordState === RecordState.START && !state.error
        ? theme.colors.stateColors.danger
        : theme.colors.white
            ? 'Stop'
            : (state.recordState === RecordState.PLAY ? 'Play' : 'Mic')
    const fill = state.error
        ? theme.colors.stateColors.danger
        : state.recordState === RecordState.START
							&& !state.error
            ? theme.colors.white
            : state.recordState === RecordState.STOP || state.recordState === ''
                ? theme.colors.black[100]
                : theme.colors.stateColors.danger

    const handleRecording = () => {
        switch (state.recordState) {
            case RecordState.START:
                mediaRecorder.current?.stop()
                clearInterval(intervalId.current)
                setState(prevState => ({
                    ...prevState,
                    recordState: RecordState.STOP,
                    time: ''
                }))
                break
            case RecordState.STOP:
                setState(prevState => ({
                    ...prevState,
                    recordState: RecordState.PLAY
                }))
                break
            case RecordState.PLAY:
                setState(prevState => ({
                    ...prevState,
                    recordState: RecordState.STOP
                }))
                break
            default:
                clearInterval(intervalId.current)
                devices.getUserMedia({ audio: true })
                    .then(RecordAcion)
                    .catch(errorRecording)
                break
        }
    }
    const RecordAcion = (stream: MediaStream) => {
        const audioData: Array<BlobPart> = []
        mediaRecorder.current = new MediaRecorder(stream)
        mediaRecorder.current?.start()
        setState(prevState => ({
            ...prevState,
            recordState: RecordState.START
        }))
        startTimer()
        mediaRecorder.current?.addEventListener('dataavailable', (event: { data: BlobPart }) => {
            audioData.push(event.data)
        })
        mediaRecorder.current?.addEventListener('stop', () => {
            const blob = new Blob(audioData, { type : constants.ALLOWED_AUDIO_FILE_TYPE[constants.ALLOWED_AUDIO_FILE_TYPE.length - 1] })
            state.formData.delete('recording')
            state.formData.append('recording', blob, `replay-challenge-answer-${uuidv4()}.${constants.BlobExtension}`)
            const audioUrl = URL.createObjectURL(blob)
            setState(prevState => ({
                ...prevState,
                audioUrl
            }))
            stream.getTracks().forEach(track => track.stop())
        })
    }
    const startTimer = React.useCallback(
        () => {
            intervalId.current = setTimeout(() => {
                setState(prevState => ({
                    ...prevState,
                    time: dateHelpers.secondsToTime(prevState.seconds, true),
                    seconds: prevState.seconds - 1
                }))
            }, 1000)

            return
        },
        [state.seconds]
    )
    React.useEffect(() => {
        if (!state.seconds) {
            return setStartRecording(RecordState.STOP)
        }
        if (state.recordState === RecordState.START) {
            startTimer()
        }
    }, [startTimer, state.seconds])
    const errorRecording = () => setState(prevState => ({ ...prevState, error: ErrorRecording.ERROR_RECORDING }))
    const resetErrorState = () => {
        setState(prevState => ({
            ...prevState,
            error: undefined,
            recordState: ''
        }))
    }
    const setStartRecording = (stateOfRecording: string) => {
        clearInterval(intervalId.current)
        setState(prevState => ({
            ...prevState,
            recordState: stateOfRecording,
            seconds: constants.SECONDS_TIMER,
            audioUrl: ''
        }))
    }
    const onUploadSuccess = (newData: UploadRecordingResponse) => {
        setState(prevState => ({
            ...prevState,
            records: newData.data.replayChallengeAnswerRecordings
        }))
        setStartRecording('')
        resetRecordingState?.()
    }
    const onUploadError = () => {
        setState(prevState => ({
            ...prevState,
            error: ErrorRecording.ERROR_UPLOADING
        }))
    }
    const { fetch: handleUpload, fetchState } = useUploadRecording(appointmentId, onUploadSuccess, onUploadError)
    const handelUpload = () => {
        setState(prevState => ({
            ...prevState,
            error: undefined
        }))
        handleUpload(state.formData)
    }
    const handleUploadFile = (file: Nullable<FileList>) => {
        if (file && file.length > 0) {
            if (!constants.ALLOWED_AUDIO_FILE_TYPE.includes(file[0].type)) {
                setState(prevState => ({
                    ...prevState,
                    error: ErrorRecording.ERROR_FILE_TYPE
                }))
            } else if ((file[0].size / (1024*1024)) > constants.UPLOAD_MAX_FILE_SIZE) {
                setState(prevState => ({
                    ...prevState,
                    error: ErrorRecording.ERROR_FILE_SIZE
                }))
            } else {
                setState(prevState => ({
                    ...prevState,
                    error: undefined
                }))
                state.formData.delete('recording')
                state.formData.append('recording', file[0], file[0].name)
                handleUpload(state.formData)
            }
        }
    }

    return {
        recordState: state.recordState,
        color,
        fill,
        replayChallengeAnswerRecordings: state.records,
        uploadLoading: fetchState.isLoading,
        error: state.error,
        time: state.time,
        audioUrl: state.audioUrl,
        handelUpload,
        handleRecording,
        setStartRecording,
        handleUploadFile,
        resetErrorState
    }
}
