import React, { useEffect, useState } from 'react'
import { useGetAvaConversation } from '../actions'
import { AvaConversationFull, AvaChatMessage } from '../types'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { dateHelpers } from 'lib/utils'
import { CONVERSATION_TYPE, GPT_ROLE } from '../constants'
import { fromUnixTime } from 'date-fns'
import { Button } from 'app/Components'
import { PURCHASE_PRODUCT_LINK } from 'lib/utils/constants'

type AvaConversationProps = {
    avaConversationUid: string,
    reachedTheLimit: boolean,
    rolePlay?: string,
    setAvaConversationUid?(uid?: string): void
}

export const AvaConversation: React.FC<AvaConversationProps> = ({avaConversationUid, reachedTheLimit, rolePlay, setAvaConversationUid}) => {
    const T = useTranslations()
    const [avaConversation, setAvaConversation] = useState<AvaConversationFull>()
    const [conversationMessages, setConversationMessages] = useState<Array<AvaChatMessage>>([])
    const {fetch: getAvaConversation} = useGetAvaConversation(response => {
        setAvaConversation(response)
        if (response.messages) {
            setConversationMessages((JSON.parse(response.messages as unknown as string)).messages)
        }
    })

    useEffect(() => {
        if (!reachedTheLimit) {
            getAvaConversation({uid: avaConversationUid, forCurrentUser: true})
        }
    }, [])

    return (
        <span>
            {rolePlay && setAvaConversationUid && (<BackLink onClick={() => setAvaConversationUid(undefined)}>&lt; Back</BackLink>)}
            {avaConversation && (<Container>
                <Info>
                    <div><b>{T.common.date}:</b> {dateHelpers.formatToLocaleDateString(fromUnixTime(avaConversation.created_at))}</div>
                    <div><b>{T.pages.avaConversations.lastMessage}:</b> {avaConversation.last_message_at ? dateHelpers.formatToLocaleDateString(fromUnixTime(avaConversation.last_message_at)) : ''}</div>
                    <div><b>{T.pages.avaConversations.type}:</b> {avaConversation.type}</div>
                    {(avaConversation.type === CONVERSATION_TYPE.MICRO_TOPIC || avaConversation.type === CONVERSATION_TYPE.WORD_PRACTICE) && (<div>
                        <b>{T.pages.avaConversations.topic}: {avaConversation.role_play?.replace(/([a-z\d])([A-Z])/g, '$1 $2')}</b>
                    </div>)}
                    <div><b>{T.pages.avaConversations.turns}:</b> {avaConversation.turns}</div>
                    <div><b>{T.pages.avaConversations.studentOutput}:</b> {Math.ceil((avaConversation.output_volume || 0)/4)}</div>
                    <div>
                        <b>{T.pages.avaConversations.summary}:</b>
                        <br/>
                        {avaConversation.summary}
                    </div>
                    <div>
                        <b>{T.pages.avaConversations.recommendations}:</b>
                        <br/>
                        {avaConversation.recommendations}
                    </div>
                </Info>
                <Chat>
                    {conversationMessages && conversationMessages.map(
                        (message, index) => {
                            return message.role === GPT_ROLE.USER ? <StudentMessage key={index}>{message.content}</StudentMessage>
                                : <AvaMessage key={index}>{message.content}</AvaMessage>
                        }
                    )}
                </Chat>
            </Container>)}
            {reachedTheLimit && (<Container>
                {T.pages.avaConversations.detailsLimit}
                <br/>
                <Button.Button
                    height={40}
                    fontSize={16}
                    backgroundColor={colors.orange}
                    textColor={colors.white}
                    onClick={() => document.location.href = PURCHASE_PRODUCT_LINK}
                    content={T.common.purchaseSubscription}
                    isLoading={false}
                    disabled={false}
                />
            </Container>)}
        </span>
    )
}

const Container = styled.div`
    padding: 20px;
    max-width: 1000px;
    max-height: 550px;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 18px;
    position: relative;
    background-color: ${colors.white};
    gap: 20px;
`

const Info = styled.div`
    width: 42%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
`

const Chat = styled.div`
    width: 55%;
`

const StudentMessage = styled.div`
    width: 70%;
    border-radius: 10px;
    background-color: ${colors.darkGreen};
    float: right;
    clear: left;
    margin-bottom: 12px;
    padding: 7px;
    text-align: left;
`

const AvaMessage = styled.div`
    width: 70%;
    border-radius: 10px;
    background-color: ${colors.blue};
    float: left;
    clear: right;
    margin-bottom: 12px;
    padding: 7px;
    text-align: left;
`

const BackLink = styled.div`
    width: 300px;
    font-size: 16px;
    cursor: pointer;
    padding: 0px 10px 15px 0;
`