import { CONFIG } from 'lib/config'
import { optionsType } from 'lib/types'

const ValidTimeZone = (timeZone?: string) => {
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
        return false
    }
    try {
        Intl.DateTimeFormat(undefined, {timeZone})

        return true
    } catch (ex) {
        return false
    }
}

const timeZone = ValidTimeZone(CONFIG.TIMEZONE) ? CONFIG.TIMEZONE :  undefined

export const ALLOWED_AUDIO_FILE_TYPE = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/x-m4a']
export const SECONDS_TIMER = 600
export const UPLOAD_MAX_FILE_SIZE = 10 // allowed file upload in MB
export const optionsDate: optionsType = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone
}

export enum Breakpoints {
    Tablet = 1500
}

export const BlobExtension = 'm4a'
